import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
import NotificationIcon from "../../../images/icon-notifications.png";
import BackIcon from "../../../images/icon-back.png";
import UploadIcon from "../../../images/icon-upload.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddPermission = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  useEffect(() => { }, []);
  const [credentials, setCredentials] = useState({ name: "", section: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);
    const response = await fetch(`${url}/api/roles/createPermissions`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: credentials.name,
        section: credentials.section,
      }),
    });
    setIsLoading(false);

    const json = await response.json();
    if (json.success) {
      setCredentials({
        name: "",
        section: "",
      });
      toast.success("Permission Created Successfully");
    } else {
      toast.error(json.error);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const resetForm = () => {
    setCredentials({
      name: "",
      section: "",
    });
  }
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-sidebar">
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header />
                <div className="general-dashboard text-capitalize">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/user/role">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div>
                              <h3>create Permissions</h3>
                              <p>
                                List of all the sites registered in the system
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>enter permission</h4>
                                  <p>Enter the name of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Enter Permission"
                                    value={credentials.name}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>assign to</h4>
                                  <p>Select the status of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <select
                                    name="section"
                                    id=""
                                    value={credentials.section}
                                    onChange={handleInput}
                                    required
                                  >
                                    <option value="" selected>
                                      Select section
                                    </option>

                                    <option value="user_section">
                                      User Section
                                    </option>
                                    <option value="QR Codes Type">
                                      QR Codes Type
                                    </option>
                                    <option value="Chat">Chat</option>
                                    <option value="Sites">Sites</option>
                                    <option value="Profile">Profile</option>
                                    <option value="Door">Door</option>
                                    <option value="Site QR Code">
                                      Site QR Code
                                    </option>
                                    <option value="WayFinder">WayFinder</option>
                                    <option value="Screens">Screens</option>
                                    <option value="Apponitment">
                                      Appointments
                                    </option>
                                    <option value="Content">Content</option>
                                    <option value="Employee">Employee</option>
                                    <option value="Camera">Camera</option>
                                    <option value="Scan">Scan</option>
                                    <option value="building Admins">
                                      building Admins
                                    </option>
                                    <option value="Vistor">Vistor</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <Link
                                onClick={resetForm}
                                className="btn btn-prim btn-transparent w-auto"
                              >
                                Reset Form
                              </Link>
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                id="btnSubmit"
                                disabled={isLoading}
                              >
                                <i
                                  className="fa fa-spinner fa-spin"
                                  style={iocnStyle}
                                ></i>
                                Add Permission
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddPermission;
