import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Help } from "../helpers/Help";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import Logo from "../../images/logo.svg";
import IconAdmins from "../../assets/icons/icon-admins.svg";
import IconAppoint from "../../assets/icons/icon-appoint.svg";
import IconCmera from "../../assets/icons/icon-camera.svg";
import IconChat from "../../assets/icons/icon-chat.svg";
import IconDashboard from "../../assets/icons/icon-dashboard.svg";
import IconDoors from "../../assets/icons/icon-doors.svg";
import IconEmployees from "../../assets/icons/icon-employess.svg";
import IconFinder from "../../assets/icons/icon-finder.svg";
import IconQR from "../../assets/icons/icon-qr.svg";
import IconRoute from "../../assets/icons/icon-route.svg";
import IconScn from "../../assets/icons/icon-scan.svg";
import IconScreens from "../../assets/icons/icon-screens.svg";
import IconSite from "../../assets/icons/icon-site.svg";
import IconSiteQR from "../../assets/icons/icon-site-qr.svg";
import IconUser from "../../assets/icons/icon-user.svg";
import { NavLink } from "react-router-dom";
import "./partials.css";


const Sidebar = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user);
  const handleUserLogout = async (e) => {
    const id = localStorage.getItem("token");
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");

  };
  return (
    <div>
      <section className="sidebar_wrap">
        <div className="logo-wrap text-center">
          <img src={Logo} alt="logo" className="logo-sidebar-wrap" />
        </div>
        <ul className="list-unstyled">
          <li>
            {isAuthenticated.role === "tenant" ? (
              <NavLink to="/tenant/appointment/list">
                <img src={IconDashboard} alt="icon" />
                <span>Dashboard</span>
              </NavLink>
            ) : (
              <NavLink to="/dashboard">
                <img src={IconDashboard} alt="icon" />
                <span>Dashboard</span>
              </NavLink>
            )}
          </li>
          {isAuthenticated.role === "admin" && (
            <li>
              <NavLink to="/user/list">
                <img src={IconUser} alt="icon" />
                <span>Users</span>
              </NavLink>
            </li>
          )}
          {isAuthenticated.role === "employee" && (
            <li>
              <NavLink to="/employee/visitor/list">
                <img src={IconUser} alt="icon" />
                <span>Tenants</span>
              </NavLink>
            </li>
          )}

          {isAuthenticated.role === "buildingAdmin" && (
            <li>
              <NavLink to="/building/user/list">
                <img src={IconUser} alt="icon" />
                <span>Users</span>
              </NavLink>
            </li>
          )}
          {isAuthenticated.role === "admin" && (
            <li>
              <NavLink to="/qr/type/list">
                <img src={IconQR} alt="icon" />
                <span>QR Code Types</span>
              </NavLink>
            </li>
          )}
          {isAuthenticated.role != "guard" &&
            isAuthenticated.role != "visitor" && (
              <li>
                <NavLink to="/chats">
                  <img src={IconChat} alt="icon" />
                  <span>Chats</span>
                </NavLink>
              </li>
            )}
          {(isAuthenticated.role === "admin" ||
            isAuthenticated.role === "integrator") &&
            (Help(isAuthenticated.role, "site_list") ||
              isAuthenticated.role === "admin") && (
              <li>
                <NavLink to="/site/list">
                  <img src={IconSite} alt="icon" />
                  <span>Sites</span>
                </NavLink>
              </li>
            )}
          {isAuthenticated.role === "admin" && (
            <li>
              <NavLink to="/user/role">
                <img src={IconRoute} alt="icon" />
                <span>Roles / Permissions</span>
              </NavLink>
            </li>
          )}
          {isAuthenticated.role === "buildingAdmin" && (
            <>
              <li>
                <NavLink to="/wayfinder/list">
                  <img src={IconFinder} alt="icon" />
                  <span>Wayfinder</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/site/qr/codes/list">
                  <img src={IconSiteQR} alt="icon" />
                  <span>Site QR Codes</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/screen/list">
                  <img src={IconScreens} alt="icon" />
                  <span>Screens</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/live/streaming/17">
                  <img src={IconSiteQR} alt="icon" />
                  <span>Streaming</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/channels">
                  <img src={IconSiteQR} alt="icon" />
                  <span>Channels</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/building/appointment/list">
                  <img src={IconAppoint} alt="icon" />
                  <span>Appointments</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/media/contents">
                  <img src={IconUser} alt="icon" />
                  <span>Media Content</span>
                </NavLink>
              </li>
            </>
          )}

          {(isAuthenticated.role === "buildingAdmin" ||
            isAuthenticated.role === "integrator") && (
              <li>
                <NavLink to="/door/list">
                  <img src={IconDoors} alt="icon" />
                  <span>Doors</span>
                </NavLink>
              </li>
            )}
          {isAuthenticated.role === "tenant" && (
            <>
              <li>
                <NavLink to="/camera">
                  <img src={IconCmera} alt="icon" />
                  <span>Cameras</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/tenant/employee/list">
                  <img src={IconEmployees} alt="icon" />
                  <span>Employees</span>
                </NavLink>
              </li>
            </>
          )}
          {isAuthenticated.role === "guard" && (
            <>
              <li>
                <NavLink to="/qrcode/scan">
                  <img src={IconScn} alt="icon" />
                  <span>Scan</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/external/scan/${isAuthenticated.site}`}>
                  <img src={IconScn} alt="icon" />
                  <span>External Scan</span>
                </NavLink>
              </li>
            </>
          )}
          {isAuthenticated.role === "integrator" && (
            <>
              <li>
                <NavLink to="/integrator/buildingAdmin/list">
                  <img src={IconAdmins} alt="icon" />
                  <span>Building Admins</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/integrator/site/list">
                  <img src={IconSite} alt="icon" />
                  <span>Sites</span>
                </NavLink>
              </li>
            </>
          )}
          <li>
            <Link onClick={handleUserLogout}>
              <img src={IconUser} alt="icon" />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Sidebar;
