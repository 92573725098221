
import "./App.css";
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import About from "./components/About";
import Dashboard from "./components/templates/dashboard/Dashboard";
import Streaming from "./components/templates/Streaming/Streaming"
import LiveStreaming from "./components/templates/Streaming/LiveStreaming"
import LiveStreaming2 from "./components/templates/Streaming/LiveStreaming2"

import PrivateRoute from "./components/auth/PrivateRoute";
import RolesPermissions from "./components//templates/Roles&Permissions/RolesPermissions";
import AddRole from "./components//templates/Roles&Permissions/AddRole";
import AddPermission from "./components/templates/Permission/AddPermission";
import Permission from "./components/Permission/Permission";
import AddSite from './components/templates/Site/Add';
import Site from './components/templates/Site/Site';
import EditSite from './components/templates/Site/Edit';

import AddUser from './components/templates/User/Add';
import UserEdit from "./components/templates/User/UserEdit";
import UserList from './components/templates/User/UserList';
import AddTpes from "./components/templates/QRCodeType/AddTpes";
import TypesList from "./components/templates/QRCodeType/TypesList";
import EditTypes from "./components/templates/QRCodeType/EditTypes";

import VideoChat from "./components/templates/Chat/VideoChat"
import Compose from "./components/templates/Chat/Compose"
import VideoCall from "./components/templates/videoCall/VideoCall"
import Chating from "./components/templates/Chat/Chating";
import SentEmails from "./components/templates/Chat/SentEmails";
import Inbox from "./components/templates/Chat/Inbox";
import SentItem from "./components/templates/Chat/SentItem";
import VD_Chat from "./components/templates/Chat/VD_Chat";
import Live from "./components/templates/Streaming/Live";

import BuildingAddAppointment from "./components/templates/BuildingAdmin/Appoinntment/AddAppointments";
import BuildingAppointmentList from "./components/templates/BuildingAdmin/Appoinntment/List";
import AddSiteQrCodes from "./components/templates/SIteQRCodes/Add";
import EditSiteQrCodes from "./components/templates/SIteQRCodes/Edit";
import SiteQrCodesList from "./components/templates/SIteQRCodes/List";
import PostVideo from "./components/templates/content/Add";
import MediaEdit from "./components/templates/content/MediaEdit";
import VideoList from "./components/templates/content/List";
import QrcodeScan from "./components/templates/scan/QrcodeScan";
import RegistervVisitorDetail from "./components/templates/appointments/RegistervVisitorDetail";
import AddInformation from "./components/templates/wayFinder/AddInformation";
import WayfinderList from "./components/templates/wayFinder/WayfinderList";
import WayfinderEdit from "./components/templates/wayFinder/WayfinderEdit";
import EditImages from "./components/templates/wayFinder/EditImages";
import SingleImageEdit from "./components/templates/wayFinder/SingleImageEdit";

// import CameraOpen from "./components/templates/wayFinder/CameraOpen";
//import Profile from "./components/templates/wayFinder/profile";
import AddScreen from "./components/templates/screens/AddScreen";
import ScreenList from "./components/templates/screens/ScreenList";
import ScreenView from "./components/templates/screens/ScreenView";
import EditScreen from "./components/templates/screens/EditScreen";
import SiteList from "./components/templates/Integrator/Site/SiteList";
import SiteAdd from "./components/templates/Integrator/Site/SiteAdd";
import BuildingAdminList from "./components/templates/Integrator/BuildingAdmin/BuildingAdminList";
import BuildingAdminAdd from "./components/templates/Integrator/BuildingAdmin/BuildingAdminAdd";
import BuildingAdminEdit from "./components/templates/Integrator/BuildingAdmin/BuildingAdminEdit";
import DoorAdd from "./components/templates/Integrator/Door/DoorAdd";
import DoorList from "./components/templates/Integrator/Door/DoorList";
import DoorEdit from "./components/templates/Integrator/Door/DoorEdit";
import Scan from "./components/templates/external/Scan";
import ExternalAppointment from "./components/templates/external/ExternalAppointment";
import AppointmentDetail from "./components/templates/BuildingAdmin/Appoinntment/AppointmentDetail";
import Profile from "./components/templates/profile/Profile";
import ProfileEdit from "./components/templates/profile/ProfileEdit";
import EmployeeAdd from "./components/templates/tenant/EmployeeAdd";
import EmployeeList from "./components/templates/tenant/EmployeeList";
import TenantAppList from "./components/templates/tenant/TenantAppList";
import Messages from "./components/templates/Chat/Messages";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Chats from "./components/templates/Chat/Chat";
import EditRolePermission from "./components/templates/Roles&Permissions/EditRolePermission"
import BuildingUserList from "./components/templates/BuildingAdmin/User/BuildingUserList";
import BuildingUserAdd from "./components/templates/BuildingAdmin/User/BuildingUserAdd";
import BuildingUserEdit from "./components/templates/BuildingAdmin/User/BuildingUserEdit";

import PasswordChange from "./components/templates/profile/PasswordChange";
import TenantAppView from "./components/templates/tenant/TenantAppView";
import EmployeeEdit from "./components/templates/tenant/EmployeeEdit";
import EmployeeVisitor from "./components/templates/employee/EmployeeVisitor";
import TenantWayfinder from "./components/templates/SIteQRCodes/TenantWayfinder";
import WayfinderDetails from "./components/templates/wayFinder/WayfinderDetails";
import AppDetail from "./components/templates/BuildingAdmin/Appoinntment/AppDetail";
import VideoCallTest from "./components/templates/Chat/VideoCallTest";
import Camera from "./components/templates/camera/Camera";
import AppQrcodes from "./components/templates/external/AppQrcodes";
import DemoVideo from "./components/templates/videoCall/DemoVideo";
import ChannelList from "./components/templates/camera/ChannelList";
function App() {

  return (
    <>
      <Router>

        <Routes>
          <Route exact path="/" element={<Login />} />

          {/* Auth Routes */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot/password" element={<ForgotPassword />} />
          <Route exact path="/reset/password/:token" element={<ResetPassword />} />
          <Route exact path="/tenant/wayfinder/:id" element={<TenantWayfinder />} />
          <Route exact path="/wayfinder/location/details/:id" element={<WayfinderDetails />} />
          <Route exact path="/video/chat" element={<VideoCallTest />} />
          <Route exact path="/demo/video" element={<DemoVideo />} />
          <Route exact path="/channel/list" element={<ChannelList />} />

          {/* Protected Routes */}

          <Route element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/streaming" element={<Streaming />} />
            <Route exact path="/about" element={<About />} />

            {/* User Routes */}
            <Route exact path="/user/role" element={<RolesPermissions />} />
            <Route exact path="/user/permission" element={<Permission />} />
            <Route exact path="/user/add" element={<AddUser />} />
            <Route exact path="/user/edit/:id" element={<UserEdit />} />
            <Route exact path="/user/list" element={<UserList />} />

            <Route exact path="/add/role" element={<AddRole />} />
            <Route exact path="/add/permission" element={<AddPermission />} />
            <Route exact path="/add/site" element={<AddSite />} />
            <Route exact path="/site/list" element={<Site />} />
            <Route exact path="/edit/site/:id" element={<EditSite />} />

            {/* QR Routes */}
            <Route exact path="/qr/type/add" element={<AddTpes />} />
            <Route exact path="/qr/type/list" element={<TypesList />} />
            <Route exact path="/site/qr/codes/list" element={<SiteQrCodesList />} />
            <Route exact path="/qr/add" element={<AddSiteQrCodes />} />
            <Route exact path="/qr/edit/:id" element={<EditSiteQrCodes />} />

            <Route exact path="/video/chat/compose" element={<VideoChat />} />
            <Route exact path="/room/:id" element={<VideoCall />} />
            {/* <Route exact path="/room/:id/:roomId" element={ <VideoCall />} /> */}
            <Route exact path="/chatting" element={<Chating />} />
            <Route exact path="/building/appointment/add" element={<BuildingAddAppointment />} />
            <Route exact path="/building/appointment/list" element={<BuildingAppointmentList />} />
            <Route exact path="/media/content/add" element={<PostVideo />} />
            <Route exact path="/media/contents" element={<VideoList />} />
            <Route exact path="/media/content/edit/:id" element={<MediaEdit />} />
            <Route exact path="/appointment/detail/:id" element={<AppDetail />} />
            {/* Way Finder Routes */}
            <Route exact path="/wayfinder/configure" element={<AddInformation />} />
            {/* <Route exact path="/wayfinder/camera/:id" element={ <CameraOpen />} /> */}
            <Route exact path="/wayfinder/list" element={<WayfinderList />} />
            <Route exact path="/wayfinder/edit/:id" element={<WayfinderEdit />} />


            <Route exact path="/cam/test" element={<Profile />} />
            <Route exact path="/screen/add" element={<AddScreen />} />
            <Route exact path="/screen/list" element={<ScreenList />} />
            <Route exact path="/screen/view/:id" element={<ScreenView />} />
            <Route exact path="/compose/message" element={<Compose />} />
            <Route exact path="/message/sent" element={<SentEmails />} />
            <Route exact path="/message/inbox" element={<Inbox />} />
            <Route exact path="/chat/:receiverId" element={<Messages />} />
            <Route exact path="/sent/:id" element={<SentItem />} />
            <Route exact path="/video" element={<VD_Chat />} />
            <Route exact path="/live" element={<Live />} />
            <Route exact path="/screen/edit/:id" element={<EditScreen />} />
            <Route exact path="/integrator/site/list" element={<SiteList />} />
            <Route exact path="/integrator/site/add" element={<SiteAdd />} />
            <Route exact path="/integrator/buildingAdmin/list" element={<BuildingAdminList />} />
            <Route exact path="/integrator/buildingAdmin/add" element={<BuildingAdminAdd />} />
            <Route exact path="/integrator/buildingAdmin/edit/:id" element={<BuildingAdminEdit />} />
            <Route exact path="/door/add" element={<DoorAdd />} />
            <Route exact path="/door/list" element={<DoorList />} />
            <Route exact path="/door/edit/:id" element={<DoorEdit />} />
            <Route exact path="/external/scan/:id" element={<Scan />} />
            <Route exact path="/qrcode/print" element={<AppQrcodes />} />
            <Route exact path="/qrcode/scan" element={<QrcodeScan />} />
            <Route exact path="/external/new/appointment/:id" element={<ExternalAppointment />} />
            <Route exact path="/appointment/detail/page/:id" element={<AppointmentDetail />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/profile/edit" element={<ProfileEdit />} />
            <Route exact path="/password/change" element={<PasswordChange />} />
            <Route exact path="/tenant/employee/add" element={<EmployeeAdd />} />
            <Route exact path="/tenant/employee/list" element={<EmployeeList />} />
            <Route exact path="/tenant/appointment/list" element={<TenantAppList />} />
            <Route exact path="/appointment/:id" element={<TenantAppView />} />
            <Route exact path="/chats" element={<Chats />} />
            <Route exact path="/edit/rolePermission/:id" element={<EditRolePermission />} />
            <Route exact path="/edit/qr/type/:id" element={<EditTypes />} />
            {/* Building User Routes */}
            <Route exact path="/building/user/list" element={<BuildingUserList />} />
            <Route exact path="/building/user/add" element={<BuildingUserAdd />} />
            <Route exact path="/building/user/edit/:id" element={<BuildingUserEdit />} />
            {/* Way Finder Routes */}
            <Route exact path="/wayfinder/edit/images/:id" element={<EditImages />} />
            <Route exact path="/wayfinder/single/images/:id" element={<SingleImageEdit />} />
            {/* <Route exact path="/live/streaming" element={ <LiveStreaming />} /> */}
            <Route exact path="/live/streaming/:id" element={<LiveStreaming2 />} />
            <Route exact path="/channels" element={<ChannelList />} />
            <Route exact path="/camera" element={<Camera />} />
            <Route exact path="/tenant/employe/edit/:id" element={<EmployeeEdit />} />
            <Route exact path="/employee/visitor/list" element={<EmployeeVisitor />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
// require('./components/Example');
