import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import styles from './style.module.css';
import logo from '../../images/logo.svg'
import frgt_hero from '../../images/forget-hero.png'  
import { toast } from "react-toastify";
const ResetPassword = () => {
    const { token } = useParams();
    const imageUrl1 = "/assets/images/logo/logo-fast-lobby-white.png";
    const [credentials, setCredentials] = useState({ new_password: "", confirm_password: "" });
    const url = process.env.REACT_APP_APP_BACK_URL;
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();  
    const handleInput = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };
    const formSubmit = async (e) => {
        e.preventDefault();
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true"); 
        setIsLoading(true); 
        const response = await fetch(`${url}/api/auth/reset/password`, {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                new_password: credentials.new_password,
                confirm_password: credentials.confirm_password,
                token: token,
            }),
        });
        setIsLoading(false); 
        const json = await response.json();
        if (json.success) { 
            toast.success(json.message);
            navigate('/login'); 
        } else { 
            toast.error(json.error); 
        }
    };
    const iocnStyle = {
        display: isLoading ? 'inline-block' : 'none',
    };
    return (
        <div className={styles.heading}>
            <section className={styles.sign_sec}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 my-auto">
                            <div className="row">
                                <div className="col-lg-6 p-0 mob-2">
                                    <div className={styles.sign_hero_wrap}>
                                        <img src={frgt_hero} alt="logo hero" />
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0 text-center mob-1">
                                    <div className={styles.cont_form_wrap}>
                                        <div className={styles.signup_wrap}>
                                            <div className={styles.logo_wrap}>
                                                <img src={logo} alt="logo" />
                                            </div>
                                            <h1>reset password</h1>
                                            <form className="form-wrap" onSubmit={formSubmit}>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="email" className="form-label">
                                                                New Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="email"
                                                                name="new_password"
                                                                placeholder="new password"
                                                                value={credentials.new_password}
                                                                onChange={handleInput}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="email" className="form-label">
                                                                Confirm Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="email"
                                                                name="confirm_password"
                                                                placeholder="confirm password"
                                                                value={credentials.confirm_password}
                                                                onChange={handleInput}
                                                                required
                                                            />
                                                        </div>
                                                        {/* <button type="submit" className="btn btn-prim">get reset link</button> */}
                                                        <button type="submit" className="btn btn-prim" disabled={isLoading}>
                                                            <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Change Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
} 
export default ResetPassword