import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import defaultAvatar from "../../../images/avatar.png";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import SearchIcon from "../../../assets/icons/search.svg";

import styles from "./style.module.css";
import HashLoader from "react-spinners/ClipLoader";
import { motion } from "framer-motion";
import { formattedDate } from "../../../utils/functions";

const EmployeeList = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRole, setSelectedRole] = useState("employee");
  const [notFound, setNotFound] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ status: "all", name: "" });
  const handleInput = (e) => {
    setCurrentPage(1);

    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = { ...prevCredentials, [e.target.name]: e.target.value };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage]);

  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setNotFound(false);
      const response = await fetch(
        `${url}/api/tenant/employee/list?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&status=${fetchCredentials.status}&role=${selectedRole}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      const json = await response.json();
      if (json.users.length > 0) {
        setIsDisplay(true);
      } else {
        setNotFound(true);
      }
      setData(json.users);
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const handleResetBtn = () => {
    const updatedCredentials = {
      name: '',
      status: 'all',
    };

    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);

    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };



  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token", localStorage.getItem("token"));
        const response = await fetch(`${url}/api/auth/user/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });

        if (response.ok) {
          fetchData(); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "User deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }



  


  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>All Employees</h3>
                <p>List of all the Emloyees in the system</p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box" onClick={toggleFilterSection}>
                    <i className="fas fa-filter" />
                  </div>
                </div>
                <Link
                  to="/tenant/employee/add"
                  className="btn btn-prim w-auto"
                >
                  Add New Employee
                </Link>
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <>
              <motion.div
                className={`row mt-5 filter-section`}
                initial={{ opacity: 0 }} // Initial state (hidden)
                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                transition={{ duration: 1.2, delay: 0.1 }}
              >

                <div className="col-12">
                  <div className="filter-right-sec filter-wrap-chat">

                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Status</label>
                      <select name="status" id="" value={credentials.status} onChange={handleInput}>
                        <option value="all">Choose Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>

                    <div className="search-wrap">
                      <input type="search" placeholder="search by username" name="name" value={credentials.name} onChange={handleInput} />
                      <div className="search-box">
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <Link
                      to="" onClick={handleResetBtn}
                      className="btn btn-prim w-auto"
                    >
                      RESET
                    </Link>
                  </div>
                </div>

              </motion.div>


            </>
          )}
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Status</th>
                          <th>Created</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td colSpan={6} className="text-center" style={{ background: "transparent" }}>
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {(isDisplay && data.length > 0) ? (
                          data.map((user) => (
                            <>
                              <tr>
                                <td>
                                  <div className="usr_det_tb">
                                    {user.image ? (
                                      <div className="img_wrap_user_tb">
                                        <img
                                          className="not_prof_img"
                                          src={`${user.image}`}
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      <div className="img_wrap_user_tb"></div>
                                    )}

                                    <span>{user.name}</span>
                                  </div>{" "}
                                </td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>

                                <td>
                                  <span className="status success text-capitalize">
                                    {user.status}
                                  </span>
                                </td>
                                <td>{formattedDate(user.date)}</td>
                                <td>
                                  <div className="d-flex">
                                    <Link to={`/tenant/employe/edit/${user._id}`}>
                                      <button
                                        type="button"
                                        className="btn btn-edit tb-btn"
                                      >
                                        Edit
                                      </button>
                                    </Link>
                                    <button
                                      type="button"
                                      className="btn btn-delete tb-btn"
                                      onClick={() =>
                                        handleDelete(user._id)
                                      }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))
                        ) : (
                          notFound && (
                            <tr>
                              <td colSpan={5} className="text-center">
                                <p className="text-center text-danger">No data found.</p>

                              </td>
                            </tr>)
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="table-num">
                    <div className="dig-num">
                      <span>1</span> <Link to="/">2</Link>{" "}
                      <Link to="/">3</Link> <Link to="/">4</Link>{" "}
                    </div>
                    <div className="num-btns">
                      <Link to="/">
                        <img src={PreviousIcon} alt="" />
                      </Link>{" "}
                      <Link to="/">
                        <img src={NextIcon} alt="" />
                      </Link>
                    </div>
                  </div> */}
                  <div className="table-num">
                    {data.length > 0 && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={" dig-num"}
                        pageClassName={"dig-num"}
                        previousClassName={"num-btns"}
                        nextClassName={"num-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"pagination-active"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;
