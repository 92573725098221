import React, { useCallback, useEffect , useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { disconnectConnection } from '../../Redux/Authentication';
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);

const VideoChat = () => {
  const dispatch = useDispatch();
  // const url = process.env.REACT_APP_APP_BACK_URL;
  const image='/assets/images/users/avatar.jpg';
  const [data, setData] = useState([]);
  const [room, setRoom] = useState("");
  const [recUser, setUser] = useState("");
  // const socket = useSocket();
  const isAuthenticated = useSelector((state) => state.user);
  let navigate = useNavigate();
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // const handleJoinRoom = useCallback(
  //   (data) => {
  //     const  room  = data;
  //     navigate(`/room/${room}`);
  //   },
  //   [navigate]
  // );
  // useEffect(() => {
  //   fetchData();
  //   socket.on("room:join", handleJoinRoom);
  //   return () => {
  //     socket.off("room:join", handleJoinRoom);
  //   };
  // }, [socket, handleJoinRoom]);
   useEffect(() => {
    fetchData();
    }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token",localStorage.getItem("token"));
      const response = await fetch(`${url}/api/auth/getChatUsers`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error);
    }
  };
  const startChat= async (userId)=>{
    try {
      
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token",localStorage.getItem("token"));
        const response = await fetch(`${url}/api/video/chat/checkRoom/${userId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        });
        const json = await response.json();
        let room =json.existingItem[0]._id;
        let user=json.existingItem[0].user_two;
        let message ='test';
        dispatch(disconnectConnection());
        socket.emit("sendNotification", {userId, room});
        navigate(`/room/${room}`);
        //  socket.emit("room:join", { user, room });
      } catch (error) {
        console.error(error);
      }
  }
  const handleDelete = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/site/delete/${id}`, {
        mode: "cors",
        method: "DELETE",
        headers: headers,
      });
      fetchData(); // Refresh the records list after successful deletion
    } catch (error) {
      console.log("Error deleting record:", error);
    }
  };
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid" style={{ marginTop: "100px" }}>
            <div className="row">
              <div className="col-lg-3"> </div>
              <div className="col-lg-6">
                <h3 className="text-center mt-3">Select Users For Chat</h3>
                <div className="card">
                  <ul className="list-group list-group-flush">
                    {data.map((item, key) => (
                      <div className="row p-2">
                        <div className="col-lg-8">
                          <li className="list-group-item">
                            <span>
                              <img
                                src={image}
                                id="user_two"
                                style={{width:'40px',height:'40px',borderRadius:'50%'}}
                              />
                            </span>
                            {capitalizeFirstLetter(item.name)} ({capitalizeFirstLetter(item.role)})
                          </li>
                        </div>
                        <div className="col-lg-4 text-center pt-5">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => startChat(item._id)}
                            id="btnSubmit"
                          >
                            Start Chat
                          </button>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default VideoChat;