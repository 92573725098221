import React, { useEffect, useState } from 'react';
import styles from "./style.module.css";
import Header from "../../partials/EternalHeader";


const AppQrcodes = () => {
    const url = process.env.REACT_APP_APP_BACK_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [apps, setApps] = useState([]);
    const base_url  = process.env.REACT_APP_BASE_URL;

    const fetchData = async () => {
        try {
          let headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Access-Control-Allow-Origin", "*");
          headers.append("Access-Control-Allow-Credentials", "true");
          setIsLoading(true)
          const response = await fetch(`${url}/api/appointments/qrcode/print`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          });
          setIsLoading(false)
          const json = await response.json();
          if(json.success){
            setApps(json.apps);
          }
        } catch (error) {
          console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
      }, []);
  return (
    <div className={styles.heading}>
    <div className="body-wrap">
      <section className={styles.sidebar_wrap}>
        <div className="container-fluid px-0 ">
          <div className="row">
            <div className="col col-12">
            <Header />
              <div className="general-dashboard">
                <div className="container-fluid">
                  <div className="dashboard-top-row add-new-top-row">

                  </div>
                  {/* row */}
                  <form>
                    <div className="form-box card-box">
                      <div className="row">
                      {apps.length > 0 &&
                        apps.map((app) => (
                            app?.qr_image && (
                            <div className="col-lg-6 col-sm-12" key={app._id}>
                                <img src={`/${app.qr_image}`} style={{ width: "300px" }} alt={`QR code for ${app.name}`} />
                            </div>
                            )
                        ))
                        }

                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  )
}

export default AppQrcodes