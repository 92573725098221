import React, { useEffect, useCallback, useState } from "react";
import Modal from "./Modal";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import DateDisplay from "../templates/date/DateDisplay";
import NotificationIcon from "../../assets/icons/icon-notifications.svg";
import ProfileImg from "../../assets/avatar.svg";
import DeclineIcon from "../../images/decline-call.png";
import AttendVoiceIcon from "../../images/attend-voice.png";
import AttendVideoIcon from "../../images/attend-video.png";
import ProfileImgReciever from "../../images/profile-reciever.png";
import HamburgerIcon from "../../images/hamburger-icon.png";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
// import { useSocket } from "../templates/Sockets/SocketProvider";
const Header = (props) => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [room, setRoom] = useState(null);
  const [sender, setSender] = useState(null);
  const [getNotification, setNotification] = useState([]);
  const dispatch = useDispatch();
  const [msg, setMsg] = "";
  const isAuthenticated = useSelector((state) => state.user);
  const imageUrl = isAuthenticated.image;
  const [notificationData, setNotificationData] = useState([]);
  useEffect(() => {
    socket.emit("login", isAuthenticated._id);
    socket.on("notification", (data) => {
      setSender(data.senderId);
      setIsOpen(true);
    });

    socket.on("messageNotification", (data) => {
      SmsNotificationList();
    });

    // socket.on("room-created-notification", ({ roomId, userId }) => {
    //   if (userId === isAuthenticated._id) {
    //     // Show an alert with the notification message
    //     setMsg(roomId);
    //     alert(`Room ${roomId} has been created!`);
    //   }
    // });
    // return () => {
    //   socket.disconnect();
    // };
    //fetchVidoChat();
    SmsNotificationList();
  }, [isAuthenticated]);
  const SmsNotificationList = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/chat/notifications/${isAuthenticated._id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      setNotificationData(json.notifications);
    } catch (error) {
      console.error(error);
    }
  };
  const notificationRead = async (notificationId) => {
    console.log("clicked");
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/chat/notification/read/${notificationId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        const queryParam = `user=${json.id}`;
        navigate(`/chats?${queryParam}`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  let subtitle;
  // const fetchVidoChat = async () => {
  //   try {
  //     let headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("Access-Control-Allow-Origin", "*");
  //     headers.append("Access-Control-Allow-Credentials", "true");
  //     const response = await fetch(`${url}/api/video/chat/notifications/${isAuthenticated._id}`, {
  //       mode: "cors",
  //       method: "GET",
  //       headers: headers,
  //     });
  //     const json = await response.json();
  //     if(json.success){
  //       setNotification(json.notification);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }
  function joinRoom() {
    setIsOpen(false);
    navigate(`/room/${sender}/?remote=true`);
  }
  const handleLogout = async (e) => {
    const id = localStorage.getItem("token");
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", id);
    const response = await fetch(
      `${url}/api/auth/user/update/chatStatusOffline`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );
  };

  return (
    <div>
      <header>
        <div className="row">
          <div className="col-lg-6">
            <div className="wel-not text-capitalize">
              <h1>Welcome back {isAuthenticated.name}!</h1>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="prof-sett">
              <div className="mob_btn_hambrgr" onClick={props.toggleActive}>
                <i className="fas fa-bars"></i>
              </div>
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownNot"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    position: "relative",
                    ...(notificationData.length > 0 && {
                      paddingRight: "15px", // Adjust the padding to accommodate the indicator
                    }),
                  }}
                >
                  <img src={NotificationIcon} alt="" />
                  {notificationData.length > 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: "10px",
                        height: "10px",
                        backgroundColor: "red",
                        borderRadius: "50%",
                      }}
                    ></span>
                  )}
                </button>
                <ul className="dropdown-menu overflow-scroll" aria-labelledby="dropdownNot">
                  <li className="notification_heads">
                    <div className="not_head_wrap">
                      <h3 className="not_head">Notifications</h3>
                    </div>

                    <div className="not_num">
                      <span className="not_num_icon">
                        {notificationData.length}
                      </span>
                    </div>
                  </li>
                  {notificationData &&
                    notificationData.map((notification) => (
                      <li
                        className="d-flex gap-1"
                        onClick={() => notificationRead(notification._id)}
                        key={notification._id}
                      >
                        <div className="not_prof_wrap">
                          <img
                            className="not_prof_img"
                            src={ProfileImg}
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1 text-wrap text-justify" style={{ fontSize: '10px' }}>
                          <p className="">
                            <b>{notification.title}</b>:
                            {notification.message}
                          </p>
                        </div>
                        <div className="not_time_wrap" style={{ width: '4rem' }}>
                          <span className="not_time_text">
                            <DateDisplay date={notification.createdAt} />
                          </span>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="prof-wrap">
                <div className="det-prof">
                  <div className="name-wrap ">
                    <h5 className="mb-0 text-capitalize">{isAuthenticated.name}</h5>
                    <p>{isAuthenticated.email}</p>
                  </div>
                </div>
                <div className="prof-img">
                  {isAuthenticated.image ? (
                    <Link to="/profile" >
                      <div className="img-profile-wrap">
                        <img
                          src={ProfileImg}
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </Link>
                  ) : (
                    <Link to="/profile" >
                      <img src={ProfileImg} alt="" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Incoming video call
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}
          ></button>
        </div>
        <div className="modal-body">
          Somenone is inviting you for a video call..
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={joinRoom}>
            Join
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeModal}
          >
            Decline
          </button>
        </div>
      </Modal> */}
      <Modal isOpen={modalIsOpen} onClose={closeModal}>

        <div className="modal-body">
          <div className="row h-100">
            <div className="col-12 mt-auto">
              <div className="incoming-call-sec">
                <h3 className="text-center">Inviting you for a video chat</h3>
                <div className="call-detail-bar">

                  <div className="call-options-wrap">
                    <div className="icons-wrap-options">
                      <div className="icon-dec">
                        <img src={DeclineIcon} alt="" onClick={closeModal} />
                      </div>
                      <div className="icon-att-voice">
                        <img src={AttendVoiceIcon} alt="" onClick={joinRoom} />
                      </div>

                      <div className="icon-att-vid">
                        <img src={AttendVideoIcon} alt="" onClick={joinRoom} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Modal>
    </div>
  );
};
export default Header;
