// RolePermissions.js
import React, { useState, useEffect } from 'react';

function Permission({ id }) {
  const [permissions, setPermissions] = useState([]);
  const url = process.env.REACT_APP_APP_BACK_URL;
  useEffect(() => {
    // Fetch permissions for the role from your backend API
    fetchPermissionData(id);
  }, [id]);
  const fetchPermissionData = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/roles/getPermsnBy/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
       
      setPermissions(json);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
     
         {
          permissions?( <span> {permissions.name} |</span>):('')  
         }
          
    </>
  );
}

export default Permission;
