import React, { useState, useEffect } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import ChatSidebar from "./Sidebar";
import { useSelector } from "react-redux";
import "./message.css";
import { Link } from "react-router-dom";
import DateDisplay from "../date/DateDisplay";
import { useParams } from "react-router-dom";
import RichTextEditor from "./RichTextEditor";
import { Parser } from 'html-to-react';
import defaultAvatar from "../../../images/avatar.png";
import io from "socket.io-client";
import { toast } from "react-toastify";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);


const Messages = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const { receiverId } = useParams();
  const senderId = isAuthenticated._id;
  const [content, setContent] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/chat/message/list/${receiverId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);

      const json = await response.json();

      if (json.success) {
        setData(json.chats);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);
    const response = await fetch(
      `${url}/api/chat/message/send`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          receiver_id: receiverId,
          sender_id: senderId,
          message: content,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    console.log(json)
    if (json.success) {
      socket.emit("sendMessageNotification", { receiverId });
      fetchData()
      setIsReply(false);
      setContent('');
      toast.success('Message Sent Successfully');

    } else {
      setIsLoading(false);
      toast.error(json.error);
    }
  };

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const divStyle = {
    display: isReply ? "block" : "none",
  };
  const btnStyle = {
    display: isReply ? "none" : "block",
  };
  const iconStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const planStyle = {
    display: isLoading ? "none" : "inline-block",
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div style={{ marginTop: "100px" }}></div>
            <div className="page-header">
              <h1 className="page-title">Chat</h1>
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Chat
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <ChatSidebar />
              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                <div className="card" id="chat">
                  <div className="card-header">
                    <h3 className="card-title">Mail Read ({data.length})</h3>
                  </div>
                  <div className="card-body">
                    {isLoading ? (

                      <p>Loading, please wait</p>

                    ) : (
                      data.length > 0 ? (
                        data.map((chat) => (
                          <div className="email-media" id="row">
                            <div className="mt-0 d-sm-flex">
                              <img className="me-2 rounded-circle avatar avatar-lg" src={chat.senderImg !== '' ? chat.senderImg : defaultAvatar} alt="avatar" />
                              <div className="media-body pt-0">
                                <div className="float-md-end d-flex fs-15">
                                  <small className="me-3 mt-3 text-muted"><DateDisplay date={chat.createdAt} /></small>
                                </div>
                                <div className="media-title text-dark font-weight-semibold mt-1">{chat.senderName}<span className="text-muted font-weight-semibold">( {chat.senderEmail} )</span></div>
                                <small className="mb-0">to {chat.receiverName} ( {chat.receiverEmail} ) </small>
                              </div>
                            </div>
                            <div className="eamil-body mt-5">
                              {Parser().parse(chat.message)}
                            </div>
                          </div>

                        ))
                      ) : (

                        <p>No Message...</p>

                      )
                    )}

                  </div>
                  {data.length > 0 &&
                    <div>
                      <div className="card-footer" style={btnStyle}>
                        <button className="btn btn-primary mt-1 mb-1" onClick={() => setIsReply(true)}><i className="fa fa-reply" /> Reply</button>
                      </div>
                    </div>
                  }
                  <div className="shadow mb-3 mt-2" style={divStyle}>
                    <form onSubmit={formSubmit}>
                      <div className="col-12">
                        <RichTextEditor onContentChange={handleContentChange} />
                      </div>
                      <div className="col-12 mt-4">
                        <div className="row">
                          <div className="col-2">
                            <button type="submit" className="btn btn-primary ml-2 mb-2" id="btnSubmi">
                              <i className="ion ion-ios-paper-plane" style={planStyle} />
                              <i className="fas fa-spinner fa-spin fa-reply" style={iconStyle} />&nbsp; Send
                            </button>
                          </div>
                          {/* <div className="col-4">
                                                <div className="mb-2">
                                                    <a href="javascript:void(0)" className="btn btn-icon btn-white btn-svg" id="btnUp" data-bs-toggle="tooltip" title data-bs-original-title="Attach"><span className="ri-attachment-2" /></a>
                                                </div>
                                                </div>
                                                <div className="col-6 text-end mt-2">
                                                <a href="javascript:;" className="btnClose"><i className="fas fa-trash" /></a>
                                                </div> */}
                        </div>
                      </div>
                    </form>
                  </div>



                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Messages