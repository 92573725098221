import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./style.module.css";
const WayfinderDetails = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  let stateVariable = 0;
  const [location, setlocation] = useState([]);
  const [count, setCount] = useState(0);
  const [length, setlenght] = useState();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [image, setimage] = useState();
  const [index_val, setIndex] = useState(0);
  const [description, setdescription] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);
  const handleNext = () => {
    console.log(location.length);
    setCount(count + 1);
    setimage(location[count + 1].image);
    setdescription(location[count + 1].description);
  }
  const handlePrevious = () => {
    setCount(count - 1);
    setimage(location[count - 1].image);
    setdescription(location[count - 1].description);
  }
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/wayfinder/detail/list?id=${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      setimage(json.location.image);
      setdescription(json.location.description);
      setlocation(json.location_list);
      setlenght(json.location_list.length - 1)
    } catch (error) {
      console.error(error);
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-12">
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row mt-2">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <div>
                              {/* <h3>Tenant Locations</h3>
                            <p>
                              List of all the sites tenant and thier locations
                            </p> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="form-box card-box">
                      <div className="col-lg-12 text-center">
                        <div className="row">
                          <div className="form-btn-wrap mb-1">
                            <h5>{description}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 text-center">
                        {image && (
                          <img src={`/uploads/files/wayfinder/${image}`} style={{ height: '400px', width: '635px', borderRadius: '10px' }} />
                        )}
                      </div>
                      <div className="col-lg-12 text-center">
                        <div className="row">
                          <div className="form-btn-wrap mb-5 mt-3">
                            {count > 0 && (
                              <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto" id="previous" onClick={handlePrevious}>
                                <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{isLoading ? "Loading..." : "Previous Step"}
                              </button>
                            )}
                            {(count < length) && (
                              <button disabled={isLoading} className="btn btn-prim w-auto" id="hoger" onClick={handleNext}>
                                <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{isLoading ? "Loading..." : "Next Step"}
                              </button>
                            )}
                            <br />
                            <br />
                            <Link to='/wayfinder/list'>Back to List</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default WayfinderDetails;
