import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import InputMask from 'react-input-mask';
import styles from "./style.module.css";
import BackIcon from "../../../images/icon-back.png";
import { toast } from "react-toastify";
function PhoneInput(props) {
  return (
    <InputMask
      mask='+1999 999 9999'
      value={props.value}
      onChange={props.onChange} placeholder="+1123 123 1234" className="form-control">
    </InputMask>
  );
}
const EmployeeEdit = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [getSite, setSite] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const handlePhoneInput = (e) => { setPhone(e.target.value) };
  const { id } = useParams();
  useEffect(() => {
    getUser();
  }, []);
  const [credentials, setCredentials] = useState({ name: "", email: "", site: "", start_time: "", end_time: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/auth/employee/update`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          name: credentials.name,
          email: credentials.email,
          phone: phone,
          start_time: credentials.start_time,
          end_time: credentials.end_time,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      toast.success('User updated Successfully');
    } else {
      toast.error('failed to update user');
    }
  };
  const getUser = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/auth/user/edit/${id}`,
      {
        mode: "cors",
        method: "get",
        headers: headers,
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      let usr = json.user;
      setCredentials({
        name: usr.name,
        email: usr.email,
        site: usr.site,
        start_time: usr.start_time,
        end_time: usr.end_time
      });
      setPhone(usr.phone)
    } else {
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };

  
  return (
    <div className="general-dashboard text-capitalize">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/tenant/employee/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>edit  employee</h3>
                  <p>
                    Enter the credentials to update building admin
                    to the system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Name</h4>
                      <p>Enter name of building admin</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        required
                        value={credentials.name}
                        onChange={handleInput}
                        placeholder="Stephen"
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>email</h4>
                      <p>Enter email of building admin</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        required
                        value={credentials.email}
                        onChange={handleInput}
                        placeholder="Finnrenalds@email.com"
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>phone</h4>
                      <p>Contact number of building admin</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <PhoneInput
                        value={phone}
                        onChange={handlePhoneInput}>
                      </PhoneInput>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>job starts at</h4>
                      <p>Enter job starting time</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="start_time" required value={credentials.start_time}
                        onChange={handleInput}>
                        <option value="">choose time</option>
                        <option value="08 am">8 AM</option>
                        <option value="09 am">9 AM</option>
                        <option value="10 am">10 AM</option>
                        <option value="11 am">11 AM</option>
                        <option value="12 am">12 PM</option>
                        <option value="01 pm">1 PM</option>
                        <option value="02 pm">2 PM</option>
                        <option value="03 pm">3 PM</option>
                        <option value="04 pm">4 PM</option>
                        <option value="05 pm">5 PM</option>
                        <option value="06 pm">6 PM</option>
                        <option value="07 pm">7 PM</option>
                        <option value="08 pm">8 PM</option>
                        <option value="09 pm">9 PM</option>
                        <option value="10 pm">10 PM</option>
                        <option value="11 pm">11 PM</option>
                        <option value="12 pm">12 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>job ends at</h4>
                      <p>Enter job ending time</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="end_time" required value={credentials.end_time}
                        onChange={handleInput}>
                        <option value="">choose time</option>
                        <option value="08 am">8 AM</option>
                        <option value="09 am">9 AM</option>
                        <option value="10 am">10 AM</option>
                        <option value="11 am">11 AM</option>
                        <option value="12 am">12 PM</option>
                        <option value="01 pm">1 PM</option>
                        <option value="02 pm">2 PM</option>
                        <option value="03 pm">3 PM</option>
                        <option value="04 pm">4 PM</option>
                        <option value="05 pm">5 PM</option>
                        <option value="06 pm">6 PM</option>
                        <option value="07 pm">7 PM</option>
                        <option value="08 pm">8 PM</option>
                        <option value="09 pm">9 PM</option>
                        <option value="10 pm">10 PM</option>
                        <option value="11 pm">11 PM</option>
                        <option value="12 pm">12 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" className="btn btn-prim w-auto ms-5" id="btnSubmit" disabled={isLoading}>
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EmployeeEdit;
