import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import { Link, useNavigate ,useParams} from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import $ from "jquery";

const WayfinderDetails = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  let stateVariable = 0;
  const [location, setlocation] = useState([]);
  const [count, setCount] = useState(0);
 
  const [length, setlenght] = useState();
  const { id } = useParams();
  const [data, setData] = useState([]);
  
  const [image, setimage] = useState();
  const [index_val, setIndex] = useState(0);
  const [description, setdescription] = useState();
  const [image_id, setImageId] = useState();
  const isAuthenticated = useSelector((state) => state.user);
  
  const [site, setsite] = useState(isAuthenticated.site);
 

  useEffect(() => {
    fetchData();
    // var images = location;
    // var imageIndex = 0;
    // var iLength = images.length - 1;
    // $("#previous").prop("disabled", true);
    // if (iLength == 0) {
    //     $("#hoger").prop("disabled", true);
    // }
    // $("#previous").on("click", function() {
    //     imageIndex = (imageIndex - 1);
    //     // $("#image").attr('src', 'uploads/files/wayfinder/' + images[imageIndex].image);
    //     // $("#description").val(images[imageIndex].description);

    //     $("#hoger").prop("disabled", false);
    //     if (imageIndex == 0) {
    //         $(this).prop("disabled", true);
    //     }
    // });

    // $("#hoger").on("click", function() {
    //     $("#previous").prop("disabled", false);
    //     imageIndex = (imageIndex + 1);
        
    //     // console.log(images[imageIndex].image);
    //     // $("#image").attr('src', '/uploads/files/wayfinder/'+images[imageIndex].image);
    //     // $("#description").val(images[imageIndex].description);
    //     if (imageIndex == iLength) {
    //         $(this).prop("disabled", true);
    //     }
    // });
  }, []);

const handleNext =  ()=>{
  console.log(location.length);
  setCount(count + 1);
  setimage(location[count + 1].image);
  setdescription(location[count + 1].description);
  setImageId(location[count + 1]._id);
  
}
const handleDelete = async (id)=>{
    try {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(
          `${url}/api/wayfinder/image/delete/${id}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          }
        );
        fetchData();
       // Refresh the records list after successful deletion
      } catch (error) {
        console.log("Error deleting record:", error);
      }
  }

const handlePrevious =  ()=>{
  setCount(count - 1);
  setimage(location[count - 1].image);
  setdescription(location[count - 1].description);
  setImageId(location[count - 1]._id);
}
  const fetchData = async () => {
    try {
      
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/wayfinder/detail/list?id=${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      setimage(json.location.image);
      setdescription(json.location.description);
      setImageId(json.location._id);
      setlocation(json.location_list);
      
     setlenght(json.location_list.length-1)
    } catch (error) {
      console.error(error);
    }
  };

 


  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div style={{ marginTop: "100px" }}>
              Hello {isAuthenticated.name} | <small className="badge bg-success">{isAuthenticated.role}</small>
            </div>

            <div className="page-header">
              <div>
                <h1 className="page-title">Location Details</h1>

                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>

                  <li className="breadcrumb-item " aria-current="page">
                  <Link to="/wayfinder/list">Wayfinder List</Link>
                  
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Location Details
                  </li>
                </ol>
              </div>

             
            </div>

            <div className="row">
              <div className="col-12 col-sm-12">
                <div className="card">
                  <div className="card-header  mx-1">
                    <div className="media">
                      <div className="media-body">
                   
                        <h6 className="mb-0 mt-1 text-muted">Location Direction</h6>
                     
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                 
                    <div className="col-lg-6">
                        <label htmlFor="exampleInputname" className="form-label mb-0">Location Direction</label>
                        <input type="text" className="form-control mt-3 mb-2" name="description" id="description"
                            value={description}  />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="exampleInputname" className="form-label mb-0">Location Images</label>
                        <img id="image" src={`/uploads/files/wayfinder/${image}`}  alt="testt" />
                    </div>

                      
                    <div className="col-lg-6">
                    {count > 0 && (
                        <button className="btn btn-light mt-2" id="previous" onClick={handlePrevious}
                            style={{border: '1px solid #555555',borderRadius: '6px',background: 'none'}}>Previous</button>
                            
                            )}
                            {(count < length)  && (
                        <button className="btn btn-light mt-2" id="hoger" onClick={handleNext}
                           style={{border: '1px solid #555555',borderRadius: '6px',background: 'none'}}>Next</button>
                         )} 
                         {(length >=0)  && (
                            <div>
                         <Link className="btn btn-light mt-2" to={`/edit/image/${image_id}`}
                           style={{border: '1px solid #555555',borderRadius: '6px',background: 'none'}}>Edit</Link>
                           
                           <button className="btn btn-light mt-2" id="hoger" onClick={()=>handleDelete(image_id)}
                           style={{border: '1px solid #555555',borderRadius: '6px',background: 'none'}}>Delete</button>
                           </div>
                         )}
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    </div>
  );
};

export default WayfinderDetails;
