import React, { useCallback, useEffect, useState, useRef } from "react";
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
// import NotificationIcon from "../../../images/icon-notifications.png";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
// import SearchIcon from "../../../assets/icons/search.svg";
// 
import PhIcon from "../../../images/icon-voice-call.png";
import bg from "../../../images/notfoundImg.png";
import VideoIcon from "../../../images/icon-video-call.png";
import PinImg from "../../../images/pin-chat.svg";
import DownloadImg from "../../../images/download-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import HashLoader from "react-spinners/ClipLoader";
import ContentLoader from "react-content-loader"
import { toast } from "react-toastify";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
const Chat = () => {
  const chatContainerRef = useRef(null);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const senderId = isAuthenticated._id;
  const [selectedUser, setSelectedUser] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [videoCallUser, setVideoCallUser] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const countRef = useRef(newMessage);
  const queryParams = new URLSearchParams(window.location.search);
  const member = queryParams.get("user");
  const [NotFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [files, setFiles] = useState([]);
  // const socket = useSocket();
  let navigate = useNavigate();
  useEffect(() => {
    fetchData();
    socket.on("updateChatMessages", (data) => {
      showNewMessageBox(data.senderId);
    });
  }, []);
  useEffect(() => {
    if (messages.length) {
      if (chatContainerRef.current) {
        chatContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, [messages.length]);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setNotFound(false);
      const response = await fetch(`${url}/api/auth/getChatUsers?member=${member}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json);
      setData(json.users);
      if (json.users && json.users.length > 0) {
        console.log(member);
        let firstUserId = json.users[0]._id;
        getUserMessages(firstUserId);
      } else {
        setIsLoading(false);
        setNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getUserMessages = async (id) => {
    try {
      setNewMessage(id);
      countRef.current = id;
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/chat/message/list/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setVideoCallUser(id);
      setIsLoading(false);
      setIsSelecting(false);
      const json = await response.json();
      if (json.success) {
        setMessages(json.chats)
        setSelectedUser(json.selectedUser)
        setShowMessageBox(true);
        notificationRead(id);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const startChat = async (userId) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/video/chat/checkUser/status/${userId}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.status === 'available') {
        socket.emit("sendNotification", { userId, senderId });
        navigate(`/room/${userId}`);
      } else if ((json.status === 'busy')) {
        toast.warning('User busy on another call.');
      } else {
        toast.warning('User offline please call later.');
      }
      //  socket.emit("room:join", { user, room });
    } catch (error) {
      console.error(error);
    }
  }
  const sendMessage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (files) {
      //formData.append("files", files);
      files.forEach((file, index) => {
        formData.append(`file`, file);
      });
    }
    formData.append("message", messageInput);
    formData.append("sender_id", senderId);
    formData.append("receiver_id", selectedUser._id);
    setIsSubmit(true);
    const response = await fetch(`${url}/api/chat/message/send`, {
      method: "POST",
      body: formData,
    });
    setIsSubmit(false);
    const json = await response.json();
    if (json.success) {
      setMessageInput('');
      console.log(json.newMsg);
      setMessages([...messages, json.newMsg]);
      setFiles([]);
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
      //getUserMessages(selectedUser._id);
      const receiverId = selectedUser._id;
      socket.emit("updateReciverChat", { senderId });
      socket.emit("sendMessageNotification", { receiverId });
      toast.success("Message sent Successfully");
    } else {
      toast.error("failed to send message");
    }
  };
  const showNewMessageBox = async (id) => {
    if (countRef.current === id) {
      getUserMessages(id);
    }
  }
  const notificationRead = async (notificationId) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/chat/notification/read/all/${notificationId}/${senderId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
    } catch (error) {
      console.error(error);
    }
  };
  const selecteUser = async (id) => {
    if (member) {
      // Remove the "user" query parameter from the URL
      queryParams.delete("user");
      const updatedQueryString = queryParams.toString();
      const urlWithoutUserParam = updatedQueryString
        ? `${window.location.pathname}?${updatedQueryString}${window.location.hash}`
        : `${window.location.pathname}${window.location.hash}`;
      window.history.replaceState({}, document.title, urlWithoutUserParam);
    }
    setIsSelecting(true);
    getUserMessages(id);
  }
  const pStyle = {
    "font-style": "italic",
    "font-size": "12px",
    "letter-spacing": "2px",
    "font-weight": "600",
    "color": "#a6a8a9"
  }
  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
  };
  const deleteFile = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
  };
  function isImageFile(extension) {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp']; // Add more image extensions as needed
    return imageExtensions.includes(extension.toLowerCase());
  }

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>Chats</h3>
                <p>All of your conversations with other users</p>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
        <div className="row">
          <div className="col-12">
            <div className="messages-wrap-inner ">
              <div className="row">
                {isLoading &&
                  <div className="col-12 text-center">
                    <HashLoader
                      color={`#0b0d41`}
                      loading={isLoading}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                }
                {!isLoading &&
                  <>
                    <div className="col col-user-accs">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        {(data && data.length > 0) &&
                          data.map((item, key) => (
                            <button
                              className={`nav-link ${key === 0 ? 'active' : ''}`}
                              id="v-pills-alexa1-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-alexa1"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-alexa1"
                              aria-selected="true"
                              onClick={() => { selecteUser(item._id) }}
                              key={key}
                            >
                              <div className="icon-wrap">
                                <img src={item.image} alt="" />
                              </div>
                              <div className="usr-det text-capitalize">
                                <h4>{item.name}</h4>
                                <p>
                                  {item.role && item.role.trim() !== '' ? item.role : ''}
                                </p>
                              </div>
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className="col col-user-msgs ">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-alexa1" role="tabpanel" aria-labelledby="v-pills-alexa1-tab"
                          tabIndex="0">
                          <div className="message-body">
                            {isSelecting ? (
                              <div className="col-12 px-4">
                                <ContentLoader
                                  speed={1}
                                  height={500}
                                  viewBox="0 0 400 400"
                                  backgroundColor="#f3f3f3"
                                  foregroundColor="#ecebeb"
                                >
                                  <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                                  <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                                  <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                                  <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                                  <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                                  <circle cx="20" cy="20" r="20" />
                                </ContentLoader>
                              </div>
                            ) : (
                              (showMessageBox && (
                                <>
                                  <div className="message-top-bar-user-wrap text-capitalize">
                                    <div className="message-top-bar-user d-flex justify-content-between align-items-center">
                                      <div className="name-det d-flex">
                                        <div className="icon-wrap">
                                          <img src={selectedUser.image && selectedUser.image !== null ? selectedUser.image : ProfileImg} alt="" />
                                        </div>
                                        <div className="name-job">
                                          <h3 className="mb-0">
                                            {selectedUser && selectedUser !== '' ? selectedUser.name : 'no user'}
                                          </h3>
                                          <p className="mb-0">
                                            {selectedUser && selectedUser !== '' ? selectedUser.role : ''}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="user-cont-opt text-end">
                                        <div className="icons-wrap d-flex">
                                          <Link onClick={videoCallUser && videoCallUser !== '' ? () => startChat(videoCallUser) : ''}>
                                            <div className="icon-ph-wrap">
                                              <img src={PhIcon} alt="" />
                                            </div>
                                          </Link>
                                          <Link onClick={videoCallUser && videoCallUser !== '' ? () => startChat(videoCallUser) : ''}>
                                            <div className="icon-ph-wrap">
                                              <img src={VideoIcon} alt="" />
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="message-content-area">
                                    <div className="cht-wrap text-capitalize" >
                                      {messages.length > 0 ? (
                                        messages.map((chat) => (
                                          <>
                                            {chat.receiverId === senderId &&
                                              <div className="recv-msg msg-line">
                                                <p>
                                                  {chat.message}
                                                </p>
                                                {chat.files && chat.files.length > 0 && (
                                                  <div className="media-pt">
                                                    <div className="row">
                                                      {chat.files.map((file, index) => (
                                                        <>
                                                          <div className="col-xl-2 col-lg-3 col-md-4 px-1" key={index}>
                                                            <div className="upld-img-wrap my-2">
                                                              <div className="remve-img">
                                                                <a href={`/uploads/files/${file.fileName}`} download={file.fileName} rel="noreferrer" target="_blank">
                                                                  <img
                                                                    src={DownloadImg}
                                                                    alt=""
                                                                  />
                                                                </a>
                                                              </div>
                                                              {isImageFile(file.extension) &&
                                                                <img alt="" src={`/uploads/files/${file.fileName}`} />
                                                              }
                                                            </div>
                                                          </div>
                                                        </>
                                                      ))}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            }
                                            {chat.senderId === senderId &&
                                              <div className="snt-msg msg-line">
                                                <p>
                                                  {chat.message}
                                                </p>
                                                {chat.files && chat.files.length > 0 && (
                                                  <div className="media-pt">
                                                    <div className="row">
                                                      {chat.files.map((file, index) => (
                                                        <>
                                                          <div className="col-xl-2 col-lg-3 col-md-4 px-1" key={index}>
                                                            <div className="upld-img-wrap my-2">
                                                              <div className="remve-img">
                                                                <a href={`/uploads/files/${file.fileName}`} download={file.fileName} rel="noreferrer" target="_blank">
                                                                  <img
                                                                    src={DownloadImg}
                                                                    alt=""
                                                                  />
                                                                </a>
                                                              </div>
                                                              {isImageFile(file.extension) &&
                                                                <img alt="" src={`/uploads/files/${file.fileName}`} />
                                                              }
                                                            </div>
                                                          </div>
                                                        </>
                                                      ))}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            }
                                          </>
                                        ))
                                      ) : (
                                        <p style={pStyle}>No Message...</p>
                                      )
                                      }
                                      <div ref={chatContainerRef}></div>
                                    </div>
                                    <div className="send-wrap">
                                      <form onSubmit={sendMessage} enctype="multipart/form-data">
                                        <div className="inpt-fld d-flex">
                                          <div className="col input-fld">
                                            <input
                                              type="text"
                                              placeholder="Write a message..."
                                              value={messageInput}
                                              required
                                              onChange={(e) => { setMessageInput(e.target.value) }}
                                            />
                                            <div className="pin-wrap">
                                              <input type="file" multiple onChange={handleFileSelect} />
                                              <img src={PinImg} alt="" />
                                            </div>
                                          </div>
                                          <div className="col input-btn">
                                            <button type="submit" className="btn btn-prim" disabled={isSubmit} style={{ fontSize: "18px" }}>
                                              <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                            </button>
                                          </div>
                                        </div>
                                        {error && <p className="text-danger">{error}</p>}
                                        {uploadProgress > 0 && <p className="text-danger">Upload Progress: {uploadProgress}%</p>}
                                        {files.length > 0 && (
                                          <div>
                                            <h6>Selected Files:</h6>
                                            <ul>
                                              {files.map((file, index) => (
                                                <li key={index}>
                                                  {file.name}
                                                  <Link onClick={() => deleteFile(index)}> <i className="fa fa-trash text-danger"></i> </Link>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        )}
                                      </form>
                                    </div>
                                  </div>
                                </>
                              ))
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-jason2"
                          role="tabpanel"
                          aria-labelledby="v-pills-jason2-tab"
                          tabIndex="0"
                        >
                          ...
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-alexa3"
                          role="tabpanel"
                          aria-labelledby="v-pills-alexa3-tab"
                          tabIndex="0"
                        >
                          ...
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-jason4"
                          role="tabpanel"
                          aria-labelledby="v-pills-jason4-tab"
                          tabIndex="0"
                        >
                          ...
                        </div>
                      </div>
                    </div>
                  </>
                }
                {NotFound &&
                  <>
                    <div className="col col-user-accs">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                      </div>
                    </div>
                    <div className="col col-user-msgs">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-alexa1" role="tabpanel" aria-labelledby="v-pills-alexa1-tab"
                          tabIndex="0">
                          <div className="message-body text-center">
                            <img src={bg} />
                            <p className="text-muted">No Chat selected yet</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        {/* row */}
      </div>
    </div>
  );
};
export default Chat;
