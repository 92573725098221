import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
import NotificationIcon from "../../../images/icon-notifications.png";
import BackIcon from "../../../images/icon-back.png";
import UploadIcon from "../../../images/icon-upload.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import EditRolePermissions from './EditPermission';
import { toast } from "react-toastify";
const EditRolePermission = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [permissions, setPermissions] = useState([]);
  const [defaultPermission, setDefaultPermission] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    getRole(id);
    fetchPermissionData();
  }, [id]);
  const [credentials, setCredentials] = useState({ name: "", section: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const updateParentState = (newValue, checked) => {
    if (checked) {
      setDefaultPermission((prevPermissions) => [...prevPermissions, newValue]);
    } else {
      setDefaultPermission((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== newValue)
      );
    }
    console.log(defaultPermission);
    // Update the parent's state with the value from the child component
  };

  // // get role
  const getRole = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/roles/getRole/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setDefaultPermission(json.permissions);
      setFormData({ ...json });
    } catch (error) {
      console.error(error);
    }
  };
  // fetch all permissions
  const fetchPermissionData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/roles/getRolesPermissions`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setPermissions(json);
    } catch (error) {
      console.error(error);
    }
  };
  const formSubmit = async (e) => {
    e.preventDefault();

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);
    const response = await fetch(`${url}/api/roles/updateRoles/${id}`, {
      mode: "cors",
      method: "PUT",
      headers: headers,
      body: JSON.stringify({
        name: formData.name,
        permissions: defaultPermission,
      }),
    });
    setIsLoading(false);

    const json = await response.json();
    if (json.success) {
      toast.success("Roles Updated Successfully");
    } else {
      toast.error(json.error);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const resetForm = () => {
    setCredentials({
      name: "",
      section: "",
    });
  };
  // edit checkbox
  // const handleCheckboxChange = (e) => {
  //   const { name, value } = e.target;
  //   setDefaultPermission([...defaultPermission, value]);
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-sidebar">
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header />
                <div className="general-dashboard text-capitalize">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/user/role">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div>
                              <h3>Edit Role Permissions</h3>
                              <p>
                                List of all the sites registered in the system
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-12">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Role</h4>
                                  <p>Enter the name of the role</p>
                                </div>
                              </div>
                              <div className="col-xl-5 col-lg-5">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Enter Permission"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                {permissions.map((item, key) => (

                                  <>
                                    <div className="col-12 mt-3">
                                      <span>
                                        <b>{item._id}</b>
                                      </span>
                                    </div>


                                    <div className="col-4 mt-3">
                                      <EditRolePermissions key={item._id} ids={item._id} updateParentState={updateParentState} />
                                    </div>
                                  </>
                                ))}

                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                id="btnSubmit"
                                disabled={isLoading}
                              >
                                <i
                                  className="fa fa-spinner fa-spin"
                                  style={iocnStyle}
                                ></i>
                                Update Role Permission
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditRolePermission;
