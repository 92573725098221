import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import defaultAvatar from "../../../images/avatar.png";
import { loginSuccess } from '../../Redux/Authentication';
import { useDispatch } from 'react-redux';
import styles from "./style.module.css";
import BackIcon from "../../../images/icon-back.png";
import { toast } from "react-toastify";
const ProfileEdit = () => {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [ProLoading, setProLoading] = useState(false);
  const [credentials, setCredentials] = useState({ name: (isAuthenticated ? isAuthenticated.name : ""), email: (isAuthenticated ? isAuthenticated.email : ""), phone: (isAuthenticated ? isAuthenticated.phone : ""), bio: (isAuthenticated ? isAuthenticated.bio : ""), current_password: "", new_password: "", confirm_password: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const profileFormSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    setProLoading(true);
    const response = await fetch(`${url}/api/profile/setting`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: credentials.name,
        email: credentials.email,
        phone: credentials.phone,
        bio: credentials.bio
      })
    });
    setProLoading(false);
    const json = await response.json();
    if (json.success) {
      dispatch(loginSuccess(json.user));
      toast.success("Profile updated successfully");
    } else {
      toast.error(json.error);
    }
  };
  const iocnStyle2 = {
    display: ProLoading ? "inline-block" : "none",
  };

  
  return (

    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/profile">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>edit profile</h3>
                  <p>Update your profile information</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={profileFormSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>name</h4>
                      <p>Enter the precise name</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        placeholder="Stephen"
                        name="name" defaultValue={isAuthenticated && isAuthenticated.name} onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>phone</h4>
                      <p>Enter the contact number of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="tel"
                        className="form-control"
                        id="ph"
                        placeholder="012 358954"
                        name="phone" defaultValue={isAuthenticated && isAuthenticated.phone} onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Email</h4>
                      <p>enter the email address</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="email"
                        className="form-control"
                        id="ph"
                        placeholder="john@doe.com"
                        name="email" defaultValue={isAuthenticated && isAuthenticated.email} onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Bio</h4>
                      <p>enter about you</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <textarea name="bio" id="" cols="30" rows="7" defaultValue={isAuthenticated && isAuthenticated.bio} onChange={handleInput}>
                      </textarea>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end my-5">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" disabled={ProLoading} className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle2}></i> update profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ProfileEdit