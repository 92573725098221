import React, { useEffect, useState } from "react";
import styles from './style.module.css'
import BackIcon from '../../../../images/icon-back.png'
import UploadIcon from '../../../../images/icon-upload.png'
import Sidebar from "../../../partials/Sidebar";
import Header from "../../../partials/Header";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";

function PhoneInput(props) {
  return (
    <InputMask
      mask='+1999 999 9999'
      value={props.value}
      onChange={props.onChange} placeholder="+1123 123 1234" className="form-control">
    </InputMask>
  );
}
const BuildingUserEdit = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [getRoles, setRoles] = useState([]);
  const site = isAuthenticated.site;
  const parentId = isAuthenticated._id;
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const handlePhoneInput = (e) => { setPhone(e.target.value) };
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [mediaShow, setMediaShow] = useState(false);

  

  useEffect(() => {
    roleData();
    getUser();
  }, []);
  const [credentials, setCredentials] = useState({ name: "", email: "", role: "", start_time: "", end_time: "", status: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("id", id);
    formData.append("name", credentials.name);
    formData.append("email", credentials.email);
    formData.append("phone", phone);
    formData.append("role", credentials.role);
    formData.append("site", site);
    formData.append("start_time", credentials.start_time);
    formData.append("end_time", credentials.end_time);
    formData.append("status", credentials.status);
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/auth/user/update`,
      {
        method: "POST",
        body: formData,
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      toast.success('User updated Successfully');
    } else {
      toast.error('failed to update user');
    }
  };
  const roleData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/roles/getRoles`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setRoles(json);
    } catch (error) {
      console.error(error);
    }
  };
  const getUser = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/auth/user/edit/${id}`,
      {
        mode: "cors",
        method: "get",
        headers: headers,
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      let usr = json.user;
      setCredentials({
        name: usr.name,
        email: usr.email,
        role: usr.role,
        site: usr.site,
        start_time: usr.start_time,
        end_time: usr.end_time,
        status: usr.status
      });
      setPhone(usr.phone)
    } else {
    }
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setMediaShow(false);
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        setFile(file);
        setError(null);

        // Create a FileReader
        const reader = new FileReader();
        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        setFile(null);
        setError("Invalid file type. Please select an image file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };

  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  return (
    <div className="general-dashboard text-capitalize">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/building/user/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Edit user</h3>
                  <p>
                    Enter the credentials to edit user to the
                    system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>name</h4>
                      <p>Enter the precise name of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        placeholder="Stephen"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>email</h4>
                      <p>Enter email address of the user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="email"
                        className="form-control"
                        id="emailadd"
                        placeholder="Email Address"
                        name="email"
                        value={credentials.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>user role</h4>
                      <p>Select the role that the user will play</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap text-capitalize">
                      <select name="role" value={credentials.role} onChange={handleInput} required>
                        <option value="" >Choose Role</option>
                        {getRoles
                          .filter((per) => per.name === "tenant" || per.name === "guard")
                          .map((per) => (
                            <option

                              key={per._id} value={per.name}>
                              {per.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>user status</h4>
                      <p>Select the current status of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="status"
                        value={credentials.status}
                        onChange={handleInput}
                        required>
                        <option value="">Choose Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>phone</h4>
                      <p>Enter the contact number of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <PhoneInput
                        value={phone}
                        onChange={handlePhoneInput}>
                      </PhoneInput>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Job Starts At</h4>
                      <p>Select the job timing</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="start_time" value={credentials.start_time} onChange={handleInput} required>
                        <option value="">choose time</option>
                        <option value="08 am">8 AM</option>
                        <option value="09 am">9 AM</option>
                        <option value="10 am">10 AM</option>
                        <option value="11 am">11 AM</option>
                        <option value="12 am">12 PM</option>
                        <option value="01 pm">1 PM</option>
                        <option value="02 pm">2 PM</option>
                        <option value="03 pm">3 PM</option>
                        <option value="04 pm">4 PM</option>
                        <option value="05 pm">5 PM</option>
                        <option value="06 pm">6 PM</option>
                        <option value="07 pm">7 PM</option>
                        <option value="08 pm">8 PM</option>
                        <option value="09 pm">9 PM</option>
                        <option value="10 pm">10 PM</option>
                        <option value="11 pm">11 PM</option>
                        <option value="12 pm">12 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Job Ends At</h4>
                      <p>Select the job Ending Time</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="end_time" value={credentials.end_time} onChange={handleInput} required>
                        <option value="">choose time</option>
                        <option value="08 am">8 AM</option>
                        <option value="09 am">9 AM</option>
                        <option value="10 am">10 AM</option>
                        <option value="11 am">11 AM</option>
                        <option value="12 am">12 PM</option>
                        <option value="01 pm">1 PM</option>
                        <option value="02 pm">2 PM</option>
                        <option value="03 pm">3 PM</option>
                        <option value="04 pm">4 PM</option>
                        <option value="05 pm">5 PM</option>
                        <option value="06 pm">6 PM</option>
                        <option value="07 pm">7 PM</option>
                        <option value="08 pm">8 PM</option>
                        <option value="09 pm">9 PM</option>
                        <option value="10 pm">10 PM</option>
                        <option value="11 pm">11 PM</option>
                        <option value="12 pm">12 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>picture</h4>
                      <p>Upload the clear picture of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <div className="file-wrap">
                        <input type="file" onChange={handleFileSelect} />
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="file_name">
                            {file ? file.name : 'No file selected'}
                          </span>{" "}
                          <img src={UploadIcon} alt="Upload Icon" />
                        </div>
                      </div>
                      <div style={{ fontSize: '12px', color: 'red', fontStyle: 'italic' }}>
                        {uploadProgress > 0 && uploadProgress < 100 && (
                          <span>{uploadProgress}% uploaded</span>
                        )}
                      </div>
                      {error && <div style={{ fontSize: '12px', color: 'red', fontStyle: 'italic' }}>{error}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">

                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Update User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default BuildingUserEdit
