import React, { useEffect, useState } from "react";
import Header from "../../../partials/Header";
import Sidebar from "../../../partials/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import defaultAvatar from "../../../../images/avatar.png";
import HashLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import SearchIcon from "../../../../images/search.png";

import styles from "./style.module.css";
import { motion } from "framer-motion";
const BuildingAdminList = () => {

  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});

  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;
  const [getSite, setSite] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ site: "all", status: "all", name: "" });
  const handleInput = (e) => {
    setCurrentPage(1);

    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = { ...prevCredentials, [e.target.name]: e.target.value };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };

  useEffect(() => {
    fetchData(credentials);
  }, [currentPage,]);
  useEffect(() => {
    siteData();
  }, []);



  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      const response = await fetch(`${url}/api/integrator/buildingAdmin/list?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&status=${fetchCredentials.status}&site=${fetchCredentials.site}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      const json = await response.json();
      console.log(json);
      if (json.success) {
        if (json.users.length > 0) {
          setIsDisplay(true);
        }
        setData(json.users);
        setTotalPages(json.totalPages);
        setIsVisible(true);
      } else {
        setIsVisible(true);

      }

    } catch (error) {
      console.error(error);
    }
  };

  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/integrator/sites`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSite(json.sites);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }

  const handleResetBtn = () => {
    const updatedCredentials = {
      name: '',
      site: 'all',
      status: 'all',
    };

    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);

    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };





  const handleDeleteClick = async (id) => {

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/auth/user/delete/${id}`, {
        method: "DELETE",
        headers: headers,
      });

      setIsLoading(false);
      const json = await response.json();
      if (response.ok) {
        fetchData(); // Refresh the records list after successful deletion
        Swal.fire(
          'Deleted!',
          'User deleted successfully!',
          'success'
        );
      } else {
        Swal.fire(
          'Error',
          'An error occurred while deleting the record.',
          'error'
        );
      }
    }
  };



  




  return (
    <div className="general-dashboard">
    <div className="container-fluid">
      <div className="dashboard-top-row">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="user-wrap">
              <h3>building admins</h3>
              <p>List of all the building admins in the system</p>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="filter-right-sec">
              <div className="filter-wrap">
                <div className="filter-box" onClick={toggleFilterSection}>
                   <i className="fas fa-filter" />
                </div>
              </div>
              <Link
                to="/integrator/buildingAdmin/add"
                className="btn btn-prim w-auto"
              >
                add building admin
              </Link>
            </div>
          </div>
        </div>
        {isFilterVisible && (
          <>
            <motion.div
              className={`row mt-5 filter-section`}
              initial={{ opacity: 0 }} // Initial state (hidden)
              animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
              transition={{ duration: 1.2, delay: 0.1 }}
            >

              <div className="col-12">
                <div className="filter-right-sec filter-wrap-chat">
                  <div className="filter-select d-flex align-items-center">
                    <label htmlFor="">Site</label>
                    <select name="site" id="" value={credentials.site} onChange={handleInput}>
                      <option value="all">Choose Site</option>
                      {getSite.map((per) => (
                        <option key={per._id} value={per._id}>
                          {per.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="filter-select d-flex align-items-center">
                    <label htmlFor="">Status</label>
                    <select name="status" id="" value={credentials.status} onChange={handleInput}>
                      <option value="all">Choose Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>

                  <div className="search-wrap">
                    <input type="search" placeholder="search by username" name="name" value={credentials.name} onChange={handleInput} />
                    <div className="search-box">
                      <img src={SearchIcon} alt="" />
                    </div>
                  </div>
                  <Link
                    to="" onClick={handleResetBtn}
                    className="btn btn-prim w-auto"
                  >
                    RESET
                  </Link>
                </div>
              </div>

            </motion.div>


          </>
        )}
      </div>
      {/* row */}
      <div className="table-wrap">
        <div className="row">
          <div className="col-12">
            <div className={styles.card_box}>
              <div className={styles.tables_wrap}>
                <div className="table-responsive">
                  <table className="table align-middle">
                    <thead>
                      <tr>
                        <th>name</th>
                        <th>job time</th>
                        <th>site</th>
                        <th>email</th>
                        <th>phone</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && (
                        <>
                          <tr>
                            <td colSpan={6} className="text-center" style={{ background: "transparent" }}>
                              <HashLoader
                                color={`#0b0d41`}
                                loading={isLoading}
                                size={40}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </td>
                          </tr>
                        </>
                      )}
                      {(isDisplay && data.length > 0) ? (
                        data.map((item, key) => (
                          <tr key={key}>
                            <td>{item.name}</td>
                            <td>{item.start_time}-{item.end_time}</td>

                            <td>
                              {getSite && item.site ? (
                                (() => {
                                  const foundSite = getSite.find(site => site._id === item.site);
                                  return foundSite ? foundSite.name : item.site;
                                })()
                              ) : (
                                "Loading..." // or another placeholder for when data is not available
                              )}
                            </td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td className="text-end">
                              {/* <Link to="javascript:;" state="2" lockerid="8" relay="123123" className="btn btn-success btn-sm relayState">Open Door</Link> */}
                              <div className="d-flex">
                                <Link to={`/integrator/buildingAdmin/edit/${item._id}`} className="btn btn-edit tb-btn">Edit</Link>
                                <button
                                  type="button"
                                  className="btn btn-delete tb-btn"
                                  onClick={() => handleDeleteClick(item._id)}
                                >
                                  delete
                                </button>
                              </div>

                            </td>
                          </tr>
                        ))
                      ) : (
                        isVisible && (

                          <tr>
                            <td colSpan={7} className="text-center">
                              <p className="text-center text-danger">No data found.</p>

                            </td>
                          </tr>
                        )

                      )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="table-num">
                  {(isDisplay && data.length > 0) && (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      pageCount={totalPages}
                      forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                      onPageChange={handlePageChange}
                      containerClassName={' dig-num'}
                      pageClassName={'dig-num'}
                      previousClassName={'num-btns'}
                      nextClassName={'num-btns'}
                      disabledClassName={'pagination-disabled'}
                      activeClassName={'pagination-active'}
                    />
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default BuildingAdminList;
