// src/components/VideoCall.js
import React, { useEffect, useRef, useState } from "react";
import Peer from "simple-peer";
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
function VideoCallTest() {
  const [stream, setStream] = useState(null);
  const [peer, setPeer] = useState(null);
  const userVideoRef = useRef();
  const partnerVideoRef = useRef();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((userStream) => {
        

        if (userVideoRef.current) {
          userVideoRef.current.srcObject = userStream;
        }
        setLoading(false);
        setStream(userStream);
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
      });
  }, []);

  const callPeer = () => {
    const peer = new Peer({ initiator: true, stream });

    peer.on("signal", (data) => {
      socket.emit("call", { signal: data });
    });

    peer.on("stream", (partnerStream) => {
      if (partnerVideoRef.current) {
        partnerVideoRef.current.srcObject = partnerStream;
      }
    });

    setPeer(peer);
  };

  const endCall = () => {
    if (peer) {
      peer.destroy();
    }
  };

  return (
    <div>
      <h1>Video Callss</h1>
      <div>
     
          <video
            ref={userVideoRef}
            autoPlay
            muted
            style={{ width: "300px", height: "200px" }}
          />
      
      </div>
      <div>
      <h2>Peersss</h2>
      <video
              ref={partnerVideoRef}
              autoPlay
              style={{ width: "300px", height: "200px" }}
            />
        {peer ? (
          <div>
            
            
            <button onClick={endCall}>End Call</button>
          </div>
        ) : (
          <button onClick={callPeer}>Call Peer</button>
        )}
      </div>
    </div>
  );
}

export default VideoCallTest;
