/**
*
* PrivateRoutes
*
*/
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import RootLayout from '../layout/RootLayout';

// Utils

const PrivateRoute = () => {
    const authed = localStorage.getItem('token');
    const storedToken = localStorage.getItem('token-expiration');
    const currentTime = Math.floor(Date.now() / 1000);
    if (authed && storedToken > currentTime) {
        return <RootLayout> <Outlet /></RootLayout>;
    } else {
        return <Navigate to="/" replace />
    }
}

export default PrivateRoute;