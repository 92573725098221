import React, { useState, useEffect } from 'react';
import axiosInstance from '../../config/axios';
import { toast } from 'react-toastify';

export const Help = (userRole, requiredPermission) => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const url = process.env.REACT_APP_APP_BACK_URL;
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        axiosInstance.get('/roles/getRoles')
          .then((res) => {
            setRoles(res.data);
          })
          .catch((err)=>{
            console.log(err);
            toast.error(err.message)
          });
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPermissions = async () => {
      try {
        axiosInstance.get('/roles/getRolesPermissions')
          .then((res) => {
            setPermissions(res.data);
          })
          .catch((err)=>{
            console.log(err);
            toast.error(err.message)
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchRoles();
    fetchPermissions();
  }, []);
  const role = roles?.find((role) => role.name === userRole);
  if (!role) return false;

  const permissionsForRole = permissions.filter((permission) =>
    role.permissions.includes(permission._id)
  );
  return permissionsForRole.some(
    (permission) => permission.name === requiredPermission
  );
}


