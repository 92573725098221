import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../images/icon-back.png'
import UploadIcon from '../../../images/icon-upload.png'
import { useSelector } from 'react-redux';
import styles from './style.module.css';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [appendedHTML, setAppendedHTML] = useState('');
  // Step 3: Click event handler function to append HTML content
  //   const handleAppendHTML = () => {
  //     // In this example, we're appending a simple <p> element.
  //     // You can replace this with any HTML content you want to append.
  //     const newHTML = '<tr><td><input type="text" name="url[]" placeholder="128.0.1.." className="form-control"></td><td><a href="javascript:;" className="delete-row"><i className="fas fa-trash mt-2"></i></a></td></tr>';
  //     // Update the state with the new HTML content
  //     setAppendedHTML(prevHTML => prevHTML + newHTML);
  //   };
  useEffect(() => { }, []);
  const [credentials, setCredentials] = useState({ name: "", address: "", status: "", ip_address: "", protocol: "", host: "", port: "", cmd: "" });
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    // const response = await fetch("http://54.174.4.89:5000/api/auth/login", {
    //   mode: "cors",
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify({
    //     email: credentials.email,
    //     password: credentials.password,
    //   }),
    // });
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/sites/createSite`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name,
          address: credentials.address,
          status: credentials.status,
          ip_address: credentials.ip_address,
          protocol: credentials.protocol,
          host: credentials.host,
          port: credentials.port,
          cmd: credentials.cmd,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    console.log(json)
    if (json.success) {
      setCredentials({
        name: '',
        address: '',
        status: '',
        ip_address: '',
        protocol: '',
        host: '',
        port: '',
        cmd: '',
      });
      toast.success('Site Created Successfully');
    } else {
      setIsLoading(false);
      toast.error(json.error);
    }
  };

  
  return (
    <div className="general-dashboard text-capitalize">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/site/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>create site</h3>
                  <p>
                    List of all the sites registered in the system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>site name</h4>
                      <p>Enter the name of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="sitec"
                        placeholder="Site Name"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>status</h4>
                      <p>Select the status of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="status" required value={credentials.status} onChange={handleInput}>
                        <option value="">Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">In Active</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>site IP</h4>
                      <p>Enter the IP address of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        placeholder="192.168.10.1"
                        name="ip_address"
                        required
                        value={credentials.ip_address}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>site address</h4>
                      <p>Enter complete address of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <textarea name="address" id="" cols="30" rows="7" value={credentials.address} onChange={handleInput}>
                        2405 Wesbrook Mall, Vancouver, BC V6T 1Z4,
                        Canada
                      </textarea>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-12 mb-5">
                <h3 className="text-primary fw-bold">Streaming Api Configuration:</h3>
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Protocol</h4>
                      <p>Enter the protocol for the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="sitec"
                        placeholder="protocol"
                        name="protocol"
                        value={credentials.protocol}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Host</h4>
                      <p>Enter the host for the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="sitec"
                        placeholder="host"
                        name="host"
                        value={credentials.host}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Port</h4>
                      <p>Enter the port for the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="number"
                        className="form-control"
                        id="sitec"
                        placeholder="port"
                        name="port"
                        value={credentials.port}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Cmd Name</h4>
                      <p>Enter the cmd name for the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="sitec"
                        placeholder="cmd name"
                        name="cmd"
                        value={credentials.cmd}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a
                    href=""
                    className="btn btn-prim btn-transparent w-auto"
                  >
                    Reset Form
                  </a>
                  <button type="submit" className="btn btn-prim w-auto ms-5" disabled={isLoading}>
                    {isLoading ? 'Creating...' : 'create site'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Add;
