import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../../assets/icons/search.svg";
import QrIconTable from "../../../images/icon-qr-tb.png";
import styles from "./style.module.css";
import HashLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const WayfinderList = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [site, setsite] = useState(isAuthenticated.site);
  useEffect(() => {
    fetchData();
  }, [currentPage, searchValue, selectedStatus]);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setNotFound(false);
      const response = await fetch(
        `${url}/api/wayfinder/getwayyfinder/list?page=${currentPage}&limit=${itemsPerPage}&search=${searchValue}&status=${selectedStatus}&site=${site}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.wayfinder_data.length > 0) {
        setIsDisplay(true);
      } else {
        setNotFound(true);
      }
      setData(json.wayfinder_data);
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/wayfinder/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        if (response.ok) {
          fetchData(); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "User deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>Way Finder</h3>
                <p>List of all the paths towards tenants</p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box">
                    <i className="fas fa-filter" />
                  </div>
                </div>
                <div className="search-wrap">
                  <div className="search-box">
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                <Link
                  to="/wayfinder/configure"
                  className="btn btn-prim w-auto"
                >
                  Add Way Finder
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
        <div className="table-wrap way_find_table">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr className="text-capitalize">
                          <th>tenant qr</th>
                          <th>tenant</th>
                          <th>start</th>
                          <th>end</th>
                          <th>no. of turns</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={6}
                                className="text-center"
                                style={{
                                  background: "transparent",
                                }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay && data.length > 0 ? (
                          data.map((item, key) => (
                            <tr>
                              <td>
                                <div className="usr_det_tb">
                                  <div className="img_wrap_qr_tb">
                                    {item.tenant_qr &&
                                      item.tenant_qr !==
                                      "" ? (
                                      <img
                                        className="not_prof_img"
                                        src={`/${item.tenant_qr}`}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="not_prof_img"
                                        src={QrIconTable}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <span>{item.tenant_name}</span>
                                </div>{" "}
                              </td>
                              <td>{item.tenant_name}</td>
                              <td>{item.start}</td>
                              <td>{item.end}</td>
                              <td>{item.no_of_turns}</td>
                              <td>
                                <Link to={`/wayfinder/location/details/${item._id}`}>
                                  <button
                                    type="button"
                                    className="btn btn-success tb-btn"
                                  >
                                    start journey
                                  </button>
                                </Link>
                                <Link
                                  to={`/wayfinder/edit/images/${item._id}`}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-edit tb-btn"
                                  >
                                    edit
                                  </button>
                                </Link>
                                <button
                                  type="button"
                                  className="btn btn-delete tb-btn"
                                  onClick={() =>
                                    handleDelete(item._id)
                                  }
                                >
                                  remove
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          notFound && (
                            <tr>
                              <td colSpan={7} className="text-center">
                                <p className="text-center text-danger">
                                  No data found.
                                </p>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="table-num">
                    {data.length > 0 && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={" dig-num"}
                        pageClassName={"dig-num"}
                        previousClassName={"num-btns"}
                        nextClassName={"num-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"pagination-active"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WayfinderList;
