import React from 'react';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
    <div className="container">
        <div className="row align-items-center flex-row-reverse">
            <div className="col-md-12 col-sm-12 text-center">
                Copyright ©  <a href=""></a>. All rights
                reserved.
            </div>
        </div>
    </div>
</footer>

    </div>
  );
}

export default Footer;
