import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import styles from "./style.module.css";
import SearchIcon from "../../../assets/icons/search.svg";

import InputMask from "react-input-mask";
import HashLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { toast } from "react-toastify";
function PhoneInput(props) {
  return (
    <InputMask
      mask="+1999 999 9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="+1123 123 1234"
      className="form-control"
    ></InputMask>
  );
}

const EmployeeVisitor = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [credentials, setCredentials] = useState({
    client: isAuthenticated._id,
    visitor: "",
    email: "",
    site: isAuthenticated.site,
    start_time: "",
    end_time: "",
  });
  const [phone, setPhone] = useState("");
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [searchStatus, setSearchStatus] = useState("all");

  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsSubmit(true);
    const response = await fetch(`${url}/api/appointments/createAppointment`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        client: credentials.client,
        site: credentials.site,
        visitor: credentials.visitor,
        email: credentials.email,
        phone: phone,
        date: credentials.date,
        time: credentials.time,
      }),
    });
    setIsSubmit(false);

    const json = await response.json();
    if (json.success) {
      fetchData();
      setCredentials({
        visitor: "",
        email: "",
        phone: "",
        date: "",
        time: "",
      });
      toast.success("Appointment Created Successfully",);
    } else {
      toast.error("failed to create appointment",);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, searchValue, searchStatus, searchPhone]);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setNotFound(false);
      const response = await fetch(
        `${url}/api/appointments/getAllAppointments?page=${currentPage}&limit=${itemsPerPage}&search=${searchValue}&status=${searchStatus}&phone=${searchPhone}&tenant_id=${isAuthenticated._id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.appointments.length > 0) {
        setIsDisplay(true);
      } else {
        setNotFound(true);
      }
      setData(json.appointments);
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1); // Reset current page when the search value changes
  };
  const handleStatusFilterChange = (e) => {
    setSearchStatus(e.target.value);
    setCurrentPage(1); // Reset current page when the role filter changes
  };
  const handlePhoneFilterChange = (e) => {
    setSearchPhone(e.target.value);
    setCurrentPage(1); // Reset current page when the role filter changes
  };
  const handleDateFilterChange = (e) => {
    setSearchDate(e.target.value);
    setCurrentPage(1); // Reset current page when the role filter changes
  };

  const handleResetBtn = () => {
    setSearchValue("");
    setSearchStatus("all");
    setCurrentPage(1);
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/appointments/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        fetchData(); // Refresh the records list after successful deletion
        if (response.ok) {
          fetchData(); // Refresh the records list after successful deletion
          Swal.fire(
            'Deleted!',
            'Appointment deleted successfully!',
            'success'
          );
        } else {
          Swal.fire(
            'Error',
            'An error occurred while deleting the record.',
            'error'
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
    }
  };
  const iocnStyle = {
    display: isSubmit ? "inline-block" : "none",
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-sidebar">
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="card">
                        <div className="card-body px-3 py-2 pt-3">
                          <form onSubmit={formSubmit}>
                            <div className="row align-items-center">
                              <div className="col-lg-3 col-xl-3 col-md-4 col-sm-6 mb-1">
                                <label htmlFor className="fw-bold mb-1">
                                  Visitor:{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="visitor"
                                  placeholder="John Doe.."
                                  required
                                  value={credentials.visitor}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="col-lg-3 col-xl-3 col-md-4 col-sm-6 mb-1">
                                <label htmlFor className="fw-bold mb-1">
                                  Email Address:{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="example@example..."
                                  required
                                  value={credentials.email}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="col-lg-3 col-xl-3 col-md-4 col-sm-6 mb-1">
                                <label htmlFor className="fw-bold mb-1">
                                  Phone Number:{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <PhoneInput
                                  value={phone}
                                  onChange={handlePhoneInput}
                                ></PhoneInput>
                              </div>
                              <div className="col-lg-3 col-xl-3 col-md-4 col-sm-6 mb-1">
                                <label htmlFor className="fw-bold mb-1">
                                  Appointment Date:{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="date"
                                  value={credentials.date}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="col-lg-3 col-xl-3 col-md-4 col-sm-6 mb-1">
                                <label className="fw-bold mb-1">
                                  Appointment Time:{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-control form-select"
                                  id="time"
                                  name="time"
                                  required
                                  value={credentials.time}
                                  onChange={handleInput}
                                >
                                  <option value>Choose Time</option>
                                  <option value="08-09 am">08-09 AM</option>
                                  <option value="09-10 am">09-10 AM</option>
                                  <option value="10-11 am">10-11 AM</option>
                                  <option value="11-12 am">11-12 AM</option>
                                  <option value="12-01 pm">12-01 PM</option>
                                  <option value="01-02 pm">01-02 PM</option>
                                  <option value="02-03 pm">02-03 PM</option>
                                  <option value="03-04 pm">03-04 PM</option>
                                  <option value="04-05 pm">04-05 PM</option>
                                  <option value="05-06 pm">05-06 PM</option>
                                  <option value="06-07 pm">06-07 PM</option>
                                  <option value="07-08 pm">07-08 PM</option>
                                  <option value="08-09 pm">08-09 PM</option>
                                  <option value="09-10 pm">09-10 PM</option>
                                  <option value="10-11 pm">10-11 PM</option>
                                  <option value="11-12 pm">11-12 PM</option>
                                </select>
                              </div>
                              <div className="col-lg-3 col-xl-3 col-md-4 col-sm-6 mb-1 ">
                                <label htmlFor className="mb-1" />
                                <button
                                  type="submit"
                                  className="btn btn-prim mt-4"
                                  id="btnSubmit"
                                  disabled={isSubmit}
                                >
                                  <i
                                    className="fa fa-spinner fa-spin"
                                    style={iocnStyle}
                                  ></i>
                                  Add Appointment
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Appointments</h3>
                            <p>List of all the appointments in the system</p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end">
                          <div className="filter-right-sec">
                            <div className="filter-wrap">
                              <div className="filter-box">
                                 <i className="fas fa-filter" />
                              </div>
                            </div>
                            <div className="search-wrap">
                              <div className="search-box">
                                <img src={SearchIcon} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="table-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                                <table className="table align-middle">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th>Phone</th>
                                      <th>Date & Time</th>
                                      <th>Status</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading && (
                                      <>
                                        <tr>
                                          <td colSpan={6} className="text-center" style={{ background: "transparent" }}>
                                            <HashLoader
                                              color={`#0b0d41`}
                                              loading={isLoading}
                                              size={40}
                                              aria-label="Loading Spinner"
                                              data-testid="loader"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    {(isDisplay && data.length > 0) ? (
                                      data.map((app, key) => (
                                        <>
                                          <tr key={key}>
                                            <td>
                                              <div className="usr_det_tb">
                                                <div className="img_wrap_user_tb"></div>
                                                <span>{app.name}</span>
                                              </div>{" "}
                                            </td>
                                            <td>{app.email}</td>
                                            <td>{app.phone}</td>
                                            <td>
                                              {new Date(
                                                app.date
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                              })}{" "}
                                              | {app.time}
                                            </td>
                                            <td>
                                              {app.status === "pending" && (
                                                <span
                                                  className={`badge bg-warning`}
                                                >
                                                  {app.status}
                                                </span>
                                              )}
                                              {app.status === "confirm" && (
                                                <span
                                                  className={`badge bg-success`}
                                                >
                                                  {app.status}
                                                </span>
                                              )}
                                              {app.status === "cancel" && (
                                                <span
                                                  className={`badge bg-danger`}
                                                >
                                                  {app.status}
                                                </span>
                                              )}
                                              {app.status === "expire" && (
                                                <span
                                                  className={`badge bg-danger`}
                                                >
                                                  {app.status}
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              <div className="d-flex">
                                                <Link
                                                  to={`/appointment/${app._id}`}
                                                  className="btn btn-edit tb-btn"
                                                >
                                                  Detail
                                                </Link>
                                                <button
                                                  type="button"
                                                  className="btn btn-delete tb-btn"
                                                  onClick={() =>
                                                    handleDelete(app._id)
                                                  }
                                                >
                                                  Remove
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      ))
                                    ) : (
                                      notFound && (
                                        <tr>
                                          <td colSpan={5} className="text-center">
                                            <p className="text-center text-danger">No data found.</p>

                                          </td>
                                        </tr>)
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="table-num">
                                {data.length > 0 && (
                                  <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={totalPages}
                                    forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                    onPageChange={handlePageChange}
                                    containerClassName={" dig-num"}
                                    pageClassName={"dig-num"}
                                    previousClassName={"num-btns"}
                                    nextClassName={"num-btns"}
                                    disabledClassName={"pagination-disabled"}
                                    activeClassName={"pagination-active"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="modals-wrap">
          {/* modal */}
          {/* <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editModalLabel">Edit QR code types</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                              <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                              <table className="table align-middle">
                                <thead>
                                  <tr>
                                  <th>user</th>
                                    <th>email</th>
                                    <th>role</th>
                                    <th>status</th>
                                    <th>phone</th>
                                    <th>created</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                  <td><div className="img_wrap_user_tb"><img className="not_prof_img" src={ProfileImg} alt="" /><span> Michael Hogan</span></div> </td>
                                    <td>Michaelhogan@email.com</td>
                                    <td>tenant</td>
                                    <td><span className="status success">in office</span></td>
                                    <td>204 6986 006</td>
                                    <td>aug 11, 2023</td>
                                    <td>
                                    <button type="button" className="btn btn-edit tb-btn" data-bs-toggle="modal" data-bs-target="#editModal">
                                    edit
            </button>
                                    <button type="button" className="btn btn-delete tb-btn" data-bs-toggle="modal" data-bs-target="#deleteModal">
                                    remove
            </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-prim btn-transparent" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-prim">Save</button>
                </div>
              </div>
            </div>
            </div> */}
          {/* modal */}
          {/* modal */}
          {/* <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="deleteModalLabel">Delete QR code type</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="body-text-delete text-center">
                      <div className={styles.delete_modal}>
                        <h3>Are you sure, you want to delete!</h3>
                      </div>
                </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-prim btn-transparent" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-prim btn-danger">Delete</button>
                </div>
              </div>
            </div>
            </div> */}
          {/* modal */}
        </div>
      </div>
    </div>
  );
};

export default EmployeeVisitor;
