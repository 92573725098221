import React from "react";
import { Link } from "react-router-dom";
const Sidebar = () => {
  return (
    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
      <div className="card">
        <div className="list-group list-group-transparent mb-0 mail-inbox pb-3">
          <div className="mt-4 mb-4 mx-4 text-center">
            <Link to="/compose/message" className="btn btn-primary btn-lg d-grid">
              Compose
            </Link>
          </div>
          <Link
            to="/message/inbox"
            className="list-group-item d-flex align-items-center active mx-4"
          >
            <span className="icons">
              <i className="ri-mail-line"></i>
            </span>{" "}
            Inbox <span className="ms-auto badge bg-secondary bradius">3</span>
          </Link>
          <Link
            to="/message/sent"
            className="list-group-item d-flex align-items-center mx-4"
          >
            <span className="icons">
              <i className="ri-mail-send-line"></i>
            </span>{" "}
            Sent Mail
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
