
import React, { useCallback, useEffect , useState } from "react";
import VideoChat from "./Chat_ser";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
const Chating = () => {
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const [roomId, setRoomId] = useState("");
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  // Implement user authentication and set the user state accordingly
  // ...
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  useEffect(() => {
    fetchData();
   
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token",localStorage.getItem("token"));
      const response = await fetch(`${url}/api/auth/getChatUsers`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error);
    }
  };
  const startChat=async (id)=>{
    try {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token",localStorage.getItem("token"));
        const response = await fetch(`${url}/api/video/chat/checkRoom/${id}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        });
        const json = await response.json();
        let room =json.existingItem[0]._id;
        setRoomId(room);
      } catch (error) {
        console.error(error);
      }
  }
  return (
    <div>

        <div >
          <h1 style={{marginTop:'100px',marginLeft:'300px'}}>Welcome, {isAuthenticated.name}!</h1>
          {data.map((item, key) => (
          <ul style={{marginLeft:'300px'}}>
            <li> <Link onClick={() => startChat(item._id)}>Join Video Chat{capitalizeFirstLetter(item.name)} ({capitalizeFirstLetter(item.role)})</Link></li>
          </ul>
         
          ))}
          {roomId && <VideoChat user={isAuthenticated} roomId={roomId} />}
        </div>
     
    </div>
  );
};

export default Chating;
