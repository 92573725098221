import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles

const RichTextEditor = ({ onContentChange }) => {
  const [editorHtml, setEditorHtml] = useState('');

  const handleEditorChange = (html) => {
    setEditorHtml(html);
    onContentChange(html); // Notify parent component of content change
  };

  return (
    <div className="col-xl-10">
      <ReactQuill
        value={editorHtml}
        onChange={handleEditorChange}
      />
    </div>
  );
};

export default RichTextEditor;
