import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import HashLoader from "react-spinners/ClipLoader";
import styles from "./style.module.css";
import ScreenIcon from "../../../assets/icons/icon-screen.svg";
import AppointIcon from "../../../assets/icons/icon-calendar.svg";
import EmployeesIcon from "../../../assets/icons/icon-user.svg";
import QrIcon from "../../../assets/icons/icon-site-qr.svg";
import IconDoors from "../../../assets/icons/icon-doors.svg";
import SitesIcon from "../../../assets/icons/icon-site.svg";
import Images from "../modal/Images";
import "sweetalert2/dist/sweetalert2.min.css";
import { formattedDate } from "../../../utils/functions";
import fetchAppointmentsList from "../../../api/appointments/fetchAppointmentsList";
import fetchWalkInAppointmentsList from "../../../api/appointments/fetchWalkInAppointmentsList";
import axiosInstance from "../../../config/axios";
const Dashboard = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const [getSite, setSite] = useState([]);
  const [getIntegratorSite, setIntegratorSite] = useState([]);
  const [getIntegrator, setIntegrtor] = useState([]);
  const [integratorNotFound, setIntegrtorNotFound] = useState(false);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [isTenantLoading, setIsTenantLoading] = useState(false);
  const [isAppLoading, setIsAppLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [appCurrentPage, setAppCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [appTotalPages, setAppTotalPages] = useState(1);
  const itemsPerPage = 10;
  const appItemsPerPage = 10;
  const [tenantData, setTenantData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [clientsAppData, setClientsAppData] = useState([]);
  const [clientAppNotFound, setClientAppNotFound] = useState(false);
  const [walkinAppData, setWalkinAppData] = useState([]);
  const [walkinAppNotFOund, setWalkinAppNotFound] = useState([]);
  const [dashCount, setDashCount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [siteNotFound, setSiteNotFound] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [notFound, setNotFound] = useState(false);
  useEffect(() => {
    siteData();
    intergratorList();
    clientsAppointmentList();
    walkinAppointmentList();
    dashboardCounts();
    integratorSiteData();
  }, []);
  const dashboardCounts = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/auth/dashboard/count`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      setDashCount(json.data);
    } catch (error) {
      console.error(error);
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      const response = await fetch(`${url}/api/sites/site/list`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      const json = await response.json();
      console.log(json);
      if (json.success) {
        if (json.data.length > 0) {
          setIsDisplay(true);
          setSite(json.data);
        } else {
          setSiteNotFound(true);
        }
      } else {
        setSiteNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const integratorSiteData = async () => {
    try {
      setIsLoading(true);
      setIsDisplay(false);
      setNotFound(false);
      const res = await axiosInstance.get('integrator/site/list')
      setIsLoading(false);
      if (res.data.success) {
        if (res.data.site_data.length > 0) {
          setIsDisplay(true);
          setIntegratorSite(res.data.site_data);
        } else {
          setIntegrtorNotFound(true);
        }
      } else {
        setIntegrtorNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const intergratorList = async () => {
    try {
      setIsLoading(true);
      setIsDisplay(false);
      const res = await axiosInstance.get('auth/integrator/list');
      setIsLoading(false);
      setIntegrtor(res.data.integrators);
    } catch (error) {
      console.error(error);
    }
  };
  const clientsAppointmentList = async () => {
    try {
      setIsLoading(true);
      setIsDisplay(false);
      const res = await fetchAppointmentsList();
      setIsLoading(false);
      if (res.data.success) {
        console.log(res.data.data.length);
        if (res.data.data.length > 0) {
          setIsDisplay(true);
          setClientsAppData(res.data.data);
        } else {
          setClientAppNotFound(true);
        }
      } else {
        setClientAppNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const walkinAppointmentList = async () => {
    try {
      setIsLoading(true);
      setIsDisplay(false);
      const res = await fetchWalkInAppointmentsList();
      setIsLoading(false);
      if (res.data.success) {
        if (res.data.data.length > 0) {
          setIsDisplay(true);
          setWalkinAppData(res.data.data);
        } else {
          setWalkinAppNotFound(true);
        }
      } else {
        setWalkinAppNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };
  useEffect(() => {
    tenantList();
  }, [currentPage]);
  useEffect(() => {
    appointmentList();
  }, [appCurrentPage]);
  const tenantList = async () => {
    try {
      setIsTenantLoading(true);
      const res = await axiosInstance.get(`auth/tenant/list?page=${currentPage}&limit=${itemsPerPage}`);
      setIsTenantLoading(false);
      setTenantData(res.data.tenants);
      setTotalPages(res.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const appointmentList = async () => {
    try {
      setIsAppLoading(true);
      const res = await axiosInstance.get(`appointments/getAllAppointments?page=${appCurrentPage}&limit=${appItemsPerPage}&site=${isAuthenticated.site}`)
      setIsAppLoading(false);
      setAppointmentData(res.data.appointments);
      setAppTotalPages(res.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const handleAppPageChange = (selectedPage) => {
    setAppCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token", localStorage.getItem("token"));
        const response = await fetch(`${url}/api/auth/user/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        if (response.ok) {
          tenantList(); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "User deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  const handleAppDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
        customClass: {
          container: "custom-alert-container", // Custom class for the alert container
        },
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/appointments/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        if (response.ok) {
          appointmentList(); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "Appointment deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  return (
    <div className={styles.body_wrap}>
      {isAuthenticated.role === "admin" && (
        <div className="row">
          {/* col-3 */}
          <div className="col-lg-3 col-sm-6">
            <div className={styles.card_wrap}>
              <div className={styles.card_icon}>
                <img src={EmployeesIcon} alt="" />
              </div>
              <div className={styles.card_cont}>
                <h3 className={styles.card_head}>
                  {dashCount.length !== 0 ? dashCount.totalUser : 0}
                </h3>
                <p className={styles.card_text}>users registered</p>
              </div>
            </div>
          </div>
          {/* col-3 */}
          <div className="col-lg-3 col-sm-6 text-primary">
            <div className={styles.card_wrap}>
              <div className={styles.card_icon}>
                <img src={SitesIcon} alt="" />
              </div>
              <div className={styles.card_cont}>
                <h3 className={styles.card_head}>
                  {dashCount.length !== 0 ? dashCount.totalSite : 0}
                </h3>
                <p className={styles.card_text}>total sites</p>
              </div>
            </div>
          </div>
          {/* col-3 */}
          <div className="col-lg-3 col-sm-6">
            <div className={styles.card_wrap}>
              <div className={styles.card_icon}>
                <img src={QrIcon} alt="" />
              </div>
              <div className={styles.card_cont}>
                <h3 className={styles.card_head}>
                  {dashCount.length !== 0
                    ? dashCount.totalQrcodeTypes
                    : 0}
                </h3>
                <p className={styles.card_text}>
                  Qrcode Types
                </p>
              </div>
            </div>
          </div>
          {/* col-3 */}
          <div className="col-lg-3 col-sm-6">
            <div className={styles.card_wrap}>
              <div className={styles.card_icon}>
                <img src={ScreenIcon} alt="" />
              </div>
              <div className={styles.card_cont}>
                <h3 className={styles.card_head}>
                  {dashCount.length !== 0
                    ? dashCount.totalWalkinApp
                    : 0}
                </h3>
                <p className={styles.card_text}>
                  Walkin Appointments
                </p>
              </div>
            </div>
          </div>
          {/* col-3 */}
          <div className="col-lg-3 col-sm-6">
            <div className={styles.card_wrap}>
              <div className={styles.card_icon}>
                <img src={AppointIcon} alt="" />
              </div>
              <div className={styles.card_cont}>
                <h3 className={styles.card_head}>
                  {dashCount.length !== 0
                    ? dashCount.totalRegisteredApp
                    : 0}
                </h3>
                <p className={styles.card_text}>
                  registered Appointments
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {isAuthenticated.role === "admin" && (
        <div className={styles.qr_roles_wrap}>
          <div className="row mt-3">
            <div className="col-lg-6">
              <div className={styles.card_box}>
                <h2>Site List</h2>
                <p>List of all the sites in the system</p>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr className="text-uppercase">
                          <th>QR</th>
                          <th>name</th>
                          <th>registered visitors</th>
                          <th>Walkin visitors</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={6}
                                className="text-center"
                                style={{
                                  background: "transparent",
                                }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay &&
                          getSite.map((item, key) => (
                            <tr>
                              <td>
                                <div className="usr_det_tb">
                                  <div className="img_wrap_qr_tb">
                                    {item.qrcode && item.qrcode !== '' &&
                                      <>
                                        <img
                                          className={`not_prof_img`}
                                          src={`/${item.qrcode}`}
                                          onClick={() => openModal(
                                            `/${item.qrcode}`
                                          )}
                                          alt=""
                                        />
                                        <Images isOpen={isModalOpen} imageUrl={selectedImageUrl} onClose={closeModal} />
                                      </>
                                    }
                                  </div>
                                </div>{" "}
                              </td>
                              <td>{item.name}</td>
                              <td className="text-center">
                                {item.nonWalkinAppointments}
                              </td>
                              <td className="text-center">
                                {item.walkinAppointments}
                              </td>
                            </tr>
                          ))
                        }
                        {siteNotFound &&
                          <tr>
                            <td colSpan={4} className="text-center">
                              <p className="text-center text-danger">
                                No data found.
                              </p>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  {isDisplay && getSite.length > 0 && (
                    <div className="row">
                      <div className="col-12 text-end">
                        <Link
                          to="/site/list"
                          className="btn btn-prim w-auto px-5 py-2"
                        >
                          <span>View All</span>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* col-6 */}
            <div className="col-lg-6">
              <div className={styles.card_box}>
                <h2>Integrators</h2>
                <p>List of all the Integrators</p>
                <div
                  className={`${styles.tables_wrap} ${styles.tables_role}`}
                >
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead className="text-uppercase">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={6}
                                className="text-center"
                                style={{
                                  background: "transparent",
                                }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay && getIntegrator.length > 0 ? (
                          getIntegrator.map((user) => (
                            <>
                              <tr>
                                <td>
                                  <div className="usr_det_tb">
                                    {user.image ? (
                                      <div className="img_wrap_user_tb">
                                        <img
                                          className="not_prof_img"
                                          src={`${user.image}`}
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      <div className="img_wrap_user_tb"></div>
                                    )}
                                    <span>{user.name}</span>
                                  </div>{" "}
                                </td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} className="text-center">
                              <p className="text-center text-danger">
                                No data found.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {isDisplay && getIntegrator.length > 0 && (
                    <div className="row">
                      <div className="col-12 text-end">
                        <Link
                          to="/user/list"
                          className="btn btn-prim w-auto px-5 py-2"
                        >
                          <span>View All</span>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* col-6 */}
          </div>
        </div>
      )}
      {isAuthenticated.role === "integrator" && (
        <div className={styles.qr_roles_wrap}>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className={styles.card_box}>
                <h2>Site List</h2>
                <p>List of all the sites</p>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead className="text-uppercase">
                        <tr>
                          <th>site QR</th>
                          <th>site name</th>
                          <th>status</th>
                          <th>complete address</th>
                          <th>site ip</th>
                          <th>created at</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={6}
                                className="text-center"
                                style={{
                                  background: "transparent",
                                }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay &&
                          getIntegratorSite.length > 0 ? (
                          getIntegratorSite.map((item, key) => (
                            <tr>
                              <td>
                                <div className="usr_det_tb">
                                  <div className="img_wrap_qr_tb">
                                    <img
                                      src={
                                        item.qr_code &&
                                          item.qr_code !== null
                                          ? `/${item.qr_code}`
                                          : QrIcon
                                      }
                                      onClick={() =>
                                        openModal(
                                          item.qr_code &&
                                            item.qr_code !== null
                                            ? `/${item.qr_code}`
                                            : QrIcon
                                        )
                                      }
                                    />
                                    <Images
                                      isOpen={isModalOpen}
                                      imageUrl={selectedImageUrl}
                                      onClose={closeModal}
                                    />
                                  </div>
                                </div>{" "}
                              </td>
                              <td>{item.name}</td>
                              <td>
                                <span className="status success">
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.address}</td>
                              <td>{item.ip_address}</td>
                              <td>
                                {formattedDate(item.createdAt)}
                              </td>
                              <td></td>
                            </tr>
                          ))
                        ) : (
                          integratorNotFound && (
                            <tr>
                              <td colSpan={5} className="text-center">
                                <p className="text-center text-danger">No data found.</p>
                              </td>
                            </tr>)
                        )}
                      </tbody>
                    </table>
                  </div>
                  {isDisplay && getIntegratorSite.length > 0 && (
                    <div className="row">
                      <div className="col-12 text-end">
                        <Link
                          to="/integrator/site/list"
                          className="btn btn-prim w-auto px-5 py-2"
                        >
                          <span>View All</span>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isAuthenticated.role === "buildingAdmin" && (
        <>
          <div className="dashboard-top-row">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="user-wrap">
                  <h3>Tenant List</h3>
                  <p>List of all the tenants in system</p>
                </div>
              </div>
              <div className="col-lg-6 text-end">
              </div>
            </div>
          </div>
          <div className="table-wrap">
            <div className="row">
              <div className="col-12">
                <div className={styles.card_box}>
                  <div className={styles.tables_wrap}>
                    <div className="table-responsive">
                      <table className="table align-middle" >
                        <thead>
                          <tr className="text-uppercase">
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isTenantLoading ? (
                            <>
                              <tr>
                                <td
                                  colSpan={6}
                                  className="text-center"
                                  style={{
                                    background: "transparent",
                                  }}
                                >
                                  <HashLoader
                                    color={`#0b0d41`}
                                    loading={isTenantLoading}
                                    size={40}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            tenantData.length > 0 ? (
                              tenantData.map((user, key) => (
                                <>
                                  <tr key={key}>
                                    <td>
                                      <div className="usr_det_tb">
                                        <div className="img_wrap_user_tb"></div>
                                        <span>{user.name}</span>
                                      </div>
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.email}</td>
                                    <td>
                                      {new Date(
                                        user.createdAt
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      })}{" "}
                                    </td>
                                    <td>
                                      <Link
                                        type="button"
                                        className="btn btn-edit tb-btn"
                                        to={`/building/user/edit/${user._id}`}
                                      >
                                        edit
                                      </Link>
                                      <button
                                        type="button"
                                        className="btn btn-delete tb-btn"
                                        onClick={() =>
                                          handleDelete(user._id)
                                        }
                                      >
                                        remove
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  <p className="text-center text-danger">No data found.</p>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    {tenantData.length > 0 && (
                      <div className="table-num">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={totalPages}
                          forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                          onPageChange={handlePageChange}
                          containerClassName={" dig-num"}
                          pageClassName={"dig-num"}
                          previousClassName={"num-btns"}
                          nextClassName={"num-btns"}
                          disabledClassName={"pagination-disabled"}
                          activeClassName={"pagination-active"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.qr_roles_wrap}>
            <div className={`${styles.qr_roles_wrap} mt-4`}>
              <div className="col-lg-12 overflow-hidden">
                <div className={styles.card_box}>
                  <h2>Appointment List</h2>
                  <p>List of all appointments.</p>
                  <div
                    className={`${styles.tables_wrap} ${styles.tables_role}`}
                  >
                    <div
                      className="table-responsive"
                      style={{
                        maxHeight: "400px",
                        overflowY: "auto",
                      }}
                    >
                      <table
                        id="data-table"
                        className="table  text-nowrap text-sm-nowrap table-hover mb-0"
                      >
                        <thead className="border-top text-uppercase">
                          <tr>
                            <th>Client</th>
                            <th>Visitor Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Date&Time</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody id="appointment">
                          {isAppLoading ? (
                            <tr className="text-center">
                              <td className="text-center" colSpan={7}>
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isAppLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          ) : appointmentData.length > 0 ? (
                            appointmentData.map((item, key) => (
                              <tr className="border-bottom" key={key}>
                                <td>{item.tenant_name}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>
                                  {new Date(
                                    item.date
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })}{" "}
                                  | {item.time}
                                </td>
                                <td className="text-start">
                                  <div className="conditions-appnt">
                                    {item.status === "pending" && (
                                      <span className={`appnt-alert warning`} style={{ marginLeft: "0px" }}>
                                        {item.status}
                                      </span>
                                    )}
                                    {item.status === "confirm" && (
                                      <span className={`appnt-alert success`} style={{ marginLeft: "0px" }}>
                                        {item.status}
                                      </span>
                                    )}
                                    {item.status === "checked-in" && (
                                      <span className={`appnt-alert success`} style={{ marginLeft: "0px" }}>
                                        {item.status}
                                      </span>
                                    )}
                                    {item.status === "cancel" && (
                                      <span className={`appnt-alert danger`} style={{ marginLeft: "0px" }}>
                                        {item.status}
                                      </span>
                                    )}
                                    {item.status === "expire" && (
                                      <span className={`appnt-alert danger`} style={{ marginLeft: "0px" }}>
                                        {item.status}
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="g-2">
                                    {/* Commenting this out for now since handleEdit and handleDelete functions are not shown */}
                                    <Link
                                      className="btn btn-edit tb-btn"
                                      to={`/appointment/detail/${item._id}`}
                                    >
                                      Detail
                                    </Link>
                                    <button
                                      type="button"
                                      className="btn btn-delete tb-btn"
                                      onClick={() =>
                                        handleAppDelete(item._id)
                                      }
                                    >
                                      remove
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td className="text-center" colSpan={6}>
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {appointmentData.length > 0 && (
                      <div className="table-num">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={appTotalPages}
                          forcePage={appCurrentPage - 1} // react-paginate uses zero-based indexing
                          onPageChange={handleAppPageChange}
                          containerClassName={" dig-num"}
                          pageClassName={"dig-num"}
                          previousClassName={"num-btns"}
                          nextClassName={"num-btns"}
                          disabledClassName={"pagination-disabled"}
                          activeClassName={"pagination-active"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(isAuthenticated.role === "admin" ||
        isAuthenticated.role === "guard") && (
          <>
            <div className="dashboard-top-row">
              <div className="row">
                <div className="col-lg-6 my-auto">
                  <div className="user-wrap">
                    <h3>Client Appointments</h3>
                    <p>List of all the recent client appointments</p>
                  </div>
                </div>
                <div className="col-lg-6 text-end">
                </div>
              </div>
            </div>
            <div className="table-wrap">
              <div className="row">
                <div className="col-12">
                  <div className={styles.card_box}>
                    <div className={styles.tables_wrap}>
                      <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto", }}>
                        <table className="table align-middle" >
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Client Email</th>
                              <th>Visitor Name</th>
                              <th>Visitor Email</th>
                              <th>Date & Time</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading && (
                              <>
                                <tr>
                                  <td
                                    colSpan={6}
                                    className="text-center"
                                    style={{
                                      background: "transparent",
                                    }}
                                  >
                                    <HashLoader
                                      color={`#0b0d41`}
                                      loading={isLoading}
                                      size={40}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  </td>
                                </tr>
                              </>
                            )}
                            {isDisplay &&
                              clientsAppData.map((per, key) => (
                                <>
                                  <tr key={key}>
                                    <td>
                                      <div className="usr_det_tb">
                                        {per.tenant_image && per.tenant_image !== 'N/A' ? (
                                          <div className="img_wrap_user_tb">
                                            <img
                                              className="not_prof_img"
                                              src={`${per.tenant_image}`}
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <div className="img_wrap_user_tb"></div>
                                        )}
                                        <span>{per.tenant_name}</span>
                                      </div>
                                    </td>
                                    <td>{per.tenant_email}</td>
                                    <td>{per.name}</td>
                                    <td>{per.email}</td>
                                    <td>
                                      {new Date(
                                        per.date
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      })}{" "}
                                      | {per.time}
                                    </td>
                                    <td className="text-start">
                                      <div className="conditions-appnt">
                                        {per.status === "pending" && (
                                          <span className={`appnt-alert warning`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                        {per.status === "confirm" && (
                                          <span className={`appnt-alert success`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                        {per.status === "checked-in" && (
                                          <span className={`appnt-alert success`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                        {per.status === "cancel" && (
                                          <span className={`appnt-alert danger`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                        {per.status === "expire" && (
                                          <span className={`appnt-alert danger`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))
                            }
                            {clientAppNotFound &&
                              <tr>
                                <td colSpan={5} className="text-center">
                                  <p className="text-center text-danger">No data found.</p>
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-top-row mt-5">
              <div className="row">
                <div className="col-lg-6 my-auto">
                  <div className="user-wrap">
                    <h3>Today's Walkin Visitors</h3>
                    <p>List of today's walkin visitors</p>
                  </div>
                </div>
                <div className="col-lg-6 text-end">
                </div>
              </div>
            </div>
            <div className="table-wrap mb-4">
              <div className="row">
                <div className="col-12">
                  <div className={styles.card_box}>
                    <div className={styles.tables_wrap}>
                      <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto", }}>
                        <table className="table align-middle">
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Client Email</th>
                              <th>Visitor</th>
                              <th>Appointment Time</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading && (
                              <>
                                <tr>
                                  <td
                                    colSpan={6}
                                    className="text-center"
                                    style={{
                                      background: "transparent",
                                    }}
                                  >
                                    <HashLoader
                                      color={`#0b0d41`}
                                      loading={isLoading}
                                      size={40}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  </td>
                                </tr>
                              </>
                            )}
                            {isDisplay &&
                              walkinAppData.length > 0 &&
                              walkinAppData.map((per, key) => (
                                <>
                                  <tr key={key}>
                                    <td>
                                      <div className="usr_det_tb">
                                        {per.tenant_image && per.tenant_image !== 'N/A' ? (
                                          <div className="img_wrap_user_tb">
                                            <img
                                              className="not_prof_img"
                                              src={`${per.tenant_image}`}
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <div className="img_wrap_user_tb"></div>
                                        )}
                                        <span>{per.tenant_name}</span>
                                      </div>
                                    </td>
                                    <td>{per.tenant_email}</td>
                                    <td>{per.name}</td>
                                    <td>{per.time}</td>
                                    <td className="text-start">
                                      <div className="conditions-appnt">
                                        {per.status === "pending" && (
                                          <span className={`appnt-alert warning`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                        {per.status === "confirm" && (
                                          <span className={`appnt-alert success`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                        {per.status === "checked-in" && (
                                          <span className={`appnt-alert success`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                        {per.status === "cancel" && (
                                          <span className={`appnt-alert danger`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                        {per.status === "expire" && (
                                          <span className={`appnt-alert danger`} style={{ marginLeft: "0px" }}>
                                            {per.status}
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))
                            }
                            {walkinAppNotFOund &&
                              <tr>
                                <td colSpan={5} className="text-center">
                                  <p className="text-center text-danger">No data found.</p>
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};
export default Dashboard;
