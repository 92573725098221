import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import styles from "./style.module.css";
import Header from "../../partials/EternalHeader";
import BackIcon from '../../../images/icon-back.png';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
function PhoneInput(props) {
  return (
    <InputMask
      mask='+1999 999 9999'
      value={props.value}
      onChange={props.onChange} placeholder="+1123 123 1234" className="form-control">
    </InputMask>
  );
}

const ExternalAppointment = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [siteData, setSiteData] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [Tenants, setTenants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const base_url = process.env.REACT_APP_BASE_URL;
  const handlePhoneInput = (e) => { setPhone(e.target.value) };
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    date: "",
    time: "",
    message: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const CardStyle = {
    'border-radius': '20px'
  }
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/appointments/external/detail/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSiteData(json.site);
        setTenants(json.tenants);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleTenantChange = (e) => {
    setSelectedTenant(e.target.value);
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(`${url}/api/appointments/walkin/create`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        client: selectedTenant,
        site: id,
        name: credentials.name,
        email: credentials.email,
        date: credentials.date,
        time: credentials.time,
        phone: phone,
        message: credentials.message,
        base_url: base_url
      }),
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      setPhone('')
      setSelectedTenant('');
      toast.success("Appointment Created Successfully");
    } else {
      toast.error("failed to create Appointment");
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0 ">
            <div className="row">
              <div className="col col-12">
                <Header />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap mt-5">
                            <Link to={`/external/scan/${id}`}>
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det mt-2">
                              <h3><b>request appointment</b></h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>your name</h4>
                                  <p>Enter complete name of visitor </p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="fuserName"
                                    placeholder="Alex Fair"
                                    name='name'
                                    required
                                    onChange={handleInput}
                                    value={credentials.name}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Your email</h4>
                                  <p>Enter your email address</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="fuserName"
                                    placeholder="Stephen@st.com"
                                    name='email'
                                    required
                                    onChange={handleInput}
                                    value={credentials.email}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>your phone no.</h4>
                                  <p>Enter phone number for visitor</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <PhoneInput
                                    value={phone}
                                    onChange={handlePhoneInput}>
                                  </PhoneInput>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>select tenant</h4>
                                  <p>Select the tenant you want to contact</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <select name="tenant" value={selectedTenant} onChange={handleTenantChange} required>
                                    <option value="">select tenant</option>
                                    {Tenants.length > 0 &&
                                      Tenants.map((tenant) => (
                                        <option key={tenant._id} value={tenant._id}>
                                          {tenant.name}
                                        </option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Appointment Date</h4>
                                  <p>Enter precise date for appointment</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="date"
                                    name="date"
                                    required
                                    className="form-control"
                                    value={credentials.date}
                                    onChange={handleInput}
                                  ></input>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Appointment Time</h4>
                                  <p>Select precise time for appointment </p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <select
                                    className="form-control form-select"
                                    id="time"
                                    name="time"
                                    required
                                    value={credentials.time}
                                    onChange={handleInput}
                                  >
                                    <option value="">choose time</option>
                                    <option value="8-9 am">8-9 AM</option>
                                    <option value="9-10 am">9-10 AM</option>
                                    <option value="10-11 am">10-11 AM</option>
                                    <option value="11-12 am">11-12 AM</option>
                                    <option value="12-1 pm">12-1 PM</option>
                                    <option value="1-2 pm">1-2 PM</option>
                                    <option value="2-3 pm">2-3 PM</option>
                                    <option value="3-4 pm">3-4 PM</option>
                                    <option value="4-5 pm">4-5 PM</option>
                                    <option value="5-6 pm">5-6 PM</option>
                                    <option value="6-7 pm">6-7 PM</option>
                                    <option value="7-8 pm">7-8 PM</option>
                                    <option value="8-9 pm">8-9 PM</option>
                                    <option value="9-10 pm">9-10 PM</option>
                                    <option value="10-11 pm">10-11 PM</option>
                                    <option value="11-12 pm">11-12 PM</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>message</h4>
                                  <p>Drop a message with appointment</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <textarea rows="5" name="message" required onChange={handleInput} value={credentials.message} placeholder='write here...'>
                                  </textarea>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                        </div>
                        <div className="col-lg-12 text-end pt-5">
                          <div className="form-btn-wrap mb-5 pb-5">
                            <button type='submit' className="btn btn-prim w-auto ms-5" disabled={isLoading}>
                              {isLoading ? "requesting" : "request appointment"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
export default ExternalAppointment