import React, { useEffect, useRef, useState } from "react";
// import { CopyToClipboard } from "react-copy-to-clipboard"
import { Link } from "react-router-dom";
import Peer from "simple-peer";

import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);

function App() {
  const isAuthenticated = useSelector((state) => state.user);
  const [me, setMe] = useState("");
  const [stream, setStream] = useState();
  const [userstream, setUserStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [idToCall, setIdToCall] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState("");
  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const connectionRef = useRef();

  useEffect(() => {
    async function setStreamFun() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setStream(stream);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setStreamFun();
    // navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
    // 	setStream(stream)
    // 	if (myVideo.current) {
    //         myVideo.current.srcObject = stream;
    // 	}

    // })
    socket.on("me", (id) => {
      console.log(id);
      setMe(id);
    });

    socket.on("callUser", (data) => {
      setReceivingCall(true);
      setCaller(data.from);
      setName(data.name);
      setCallerSignal(data.signal);
    });

    // navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
    // 	setStream(stream)
    //     if (myVideo.current) {
    //         myVideo.current.srcObject = stream;
    //       }
    // })
  }, []);

  const callUser = (id) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: stream,
    });
    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name: name,
      });
    });
    peer.on("stream", (stream) => {
      setUserStream(stream);
      userVideo.current.srcObject = stream;
    });
    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);
      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const answerCall = () => {
    setCallAccepted(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });
    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: caller });
    });
    peer.on("stream", (stream) => {
      setUserStream(stream);
      userVideo.current.srcObject = stream;
    });

    peer.signal(callerSignal);
    connectionRef.current = peer;
  };
  const handleSendNotification = (userId, message) => {
    // Send the notification to the server via WebSocket
    socket.emit("sendNotification", { userId, message });
  };
  const leaveCall = () => {
    setCallEnded(true);
    connectionRef.current.destroy();
  };

  return (
    <>
	<Header />
      <h1 style={{ textAlign: "center", color: "#fff" }}>Imran</h1>
      <div className="container">
        <div className="video-container">
          <div className="video">
            {stream && (
              <ReactPlayer
                playing
                muted
                height="100px"
                width="200px"
                url={stream}
              />
            )}
          </div>
          <div className="video">
            {callAccepted && !callEnded ? (
              <ReactPlayer
                playing
                muted
                height="100px"
                width="200px"
                url={stream}
              />
            ) : null}
          </div>
        </div>
        <div className="myId">
          <input
            id="filled-basic"
            label="Name"
            variant="filled"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          {/* <CopyToClipboard text={me} style={{ marginBottom: "2rem" }}> */}
          <Link variant="contained" color="primary">
            Copy ID
          </Link>
          {/* </CopyToClipboard> */}

          <input
            id="filled-basic"
            label="ID to call"
            variant="filled"
            value={idToCall}
            onChange={(e) => setIdToCall(e.target.value)}
          />
          <div className="call-button">
            {callAccepted && !callEnded ? (
              <Link variant="contained" color="secondary" onClick={leaveCall}>
                End Call
              </Link>
            ) : (
              <Link
                color="primary"
                aria-label="call"
                onClick={() => callUser(idToCall)}
              >
                Call User
              </Link>
            )}
            {idToCall}
          </div>
          <button onClick={() => handleSendNotification('64c0f26513432aa46717063a', "Hello!")}>
            Send Notification
          </button>
        </div>
        <div>
          {receivingCall && !callAccepted ? (
            <div className="caller">
              <h1>{name} is calling...</h1>
              <Link variant="contained" color="primary" onClick={answerCall}>
                Answer
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default App;
