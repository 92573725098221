import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../images/icon-back.png'
import UploadIcon from '../../../images/icon-upload.png'
import styles from './style.module.css'
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";
function PhoneInput(props) {
  return (
    <InputMask
      mask='+1999 999 9999'
      value={props.value}
      onChange={props.onChange} placeholder="+1123 123 1234" className="form-control">
    </InputMask>
  );
}
const EmployeeAdd = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const handlePhoneInput = (e) => { setPhone(e.target.value) };
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    start_time: "",
    end_time: "",
    role: "employee",
    site: isAuthenticated.site,
    parentId: isAuthenticated._id
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/auth/createUser`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name,
          email: credentials.email,
          phone: phone,
          role: credentials.role,
          site: credentials.site,
          start_time: credentials.start_time,
          end_time: credentials.end_time,
          parentId: credentials.parentId
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        name: '',
        email: '',
        start_time: '',
        end_time: ''
      });
      setPhone('')
      toast.success('Employee Created Successfully');
    } else {
      toast.error('failed to create employee');
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };

  
  return (
    <div className="general-dashboard text-capitalize">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/tenant/employee/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Add Employee</h3>
                  <p>
                    Enter the credentials to add new employee to the
                    system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>name</h4>
                      <p>Enter the precise name of employee</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        placeholder="Stephen"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>email</h4>
                      <p>Enter email address of the user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="email"
                        className="form-control"
                        id="emailadd"
                        placeholder="Email Address"
                        name="email"
                        value={credentials.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>phone</h4>
                      <p>Enter the contact number of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <PhoneInput
                        value={phone}
                        onChange={handlePhoneInput}>
                      </PhoneInput>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Job Starts At</h4>
                      <p>Select the job timing</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="start_time" value={credentials.start_time} onChange={handleInput} required>
                        <option value="">Choose Time</option>
                        <option value="08 am">8 AM</option>
                        <option value="09 am">9 AM</option>
                        <option value="10 am">10 AM</option>
                        <option value="11 am">11 AM</option>
                        <option value="12 am">12 PM</option>
                        <option value="01 pm">1 PM</option>
                        <option value="02 pm">2 PM</option>
                        <option value="03 pm">3 PM</option>
                        <option value="04 pm">4 PM</option>
                        <option value="05 pm">5 PM</option>
                        <option value="06 pm">6 PM</option>
                        <option value="07 pm">7 PM</option>
                        <option value="08 pm">8 PM</option>
                        <option value="09 pm">9 PM</option>
                        <option value="10 pm">10 PM</option>
                        <option value="11 pm">11 PM</option>
                        <option value="12 pm">12 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Job Ends At</h4>
                      <p>Select the job Ending Time</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="end_time" value={credentials.end_time} onChange={handleInput} required>
                        <option value="">Choose Time</option>
                        <option value="08 am">8 AM</option>
                        <option value="09 am">9 AM</option>
                        <option value="10 am">10 AM</option>
                        <option value="11 am">11 AM</option>
                        <option value="12 am">12 PM</option>
                        <option value="01 pm">1 PM</option>
                        <option value="02 pm">2 PM</option>
                        <option value="03 pm">3 PM</option>
                        <option value="04 pm">4 PM</option>
                        <option value="05 pm">5 PM</option>
                        <option value="06 pm">6 PM</option>
                        <option value="07 pm">7 PM</option>
                        <option value="08 pm">8 PM</option>
                        <option value="09 pm">9 PM</option>
                        <option value="10 pm">10 PM</option>
                        <option value="11 pm">11 PM</option>
                        <option value="12 pm">12 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>

              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a
                    href=""
                    className="btn btn-prim btn-transparent w-auto"
                  >
                    Reset Form
                  </a>
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Create User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default EmployeeAdd