import React from 'react';

const diffForHumans = (date) => {
  const currentDate = new Date();
  const givenDate = new Date(date);

  const timeDifferenceInSeconds = Math.floor((currentDate - givenDate) / 1000);

  if (timeDifferenceInSeconds < 60) {
    return 'Just now';
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else {
    const days = Math.floor(timeDifferenceInSeconds / 86400);
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  }
};

const DateDisplay = ({ date }) => {
  const formattedDate = diffForHumans(date);

  return <p>{formattedDate}</p>;
};

export default DateDisplay;
