import { toast } from "react-toastify";
import axiosInstance from "../../config/axios"

const fetchQrCodeHandler = async (id) => {
    try {
        const response = await axiosInstance.get(`/type/get/qrcode/${id}`);
        return response;
    } catch (error) {
        toast.error(error.message);
        console.log(error)
    }
}
export default fetchQrCodeHandler;