import React, { useState, useEffect } from "react";
import BackIcon from "../../../images/icon-back.png";
import { Link, useParams } from "react-router-dom";
import "../../../css/imageMaker.css";
import "../../js/imageMaker";
import { toast } from "react-toastify";
const SingleImageEdit = () => {
  const [img_urls, setimg_url] = useState(null);
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  let image_url = "";
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (img_urls) {
      (function (global, $) {
        // Your code that uses jQuery can go here
        // For example, you can use $(...) to select elements and apply jQuery methods
        function dataURItoFile(dataURI, fileName) {
          let byteString = atob(dataURI.split(",")[1]);
          let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
          let ab = new ArrayBuffer(byteString.length);
          let ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          return new File([ab], fileName, {
            type: mimeString,
            lastModified: Date.now(),
          });
        }
        const options = {
          merge_images: [
            {
              url: "/uploads/directions/left_new.png",
              title: "Left",
            },
            {
              url: "/uploads/directions/right.png",
              title: "Right",
            },
            {
              url: "/uploads/directions/up_new.png",
              title: "Up",
            },
            {
              url: "/uploads/directions/down_new.png",
              title: "Down",
            },
          ],
          templates: [
            {
              url: img_urls,
              title: $("#description").val(),
            },
          ],
          text_boxes_count: 0,
        };
        $("#camera-image").imageMaker(options);
        $(document)
          .find("a#save-photo.btn.btn-default.btn-success.save_photo")
          .on("click", async function (e) {
            e.preventDefault();
            let des = $("#description").val();
            let canvas_new = $(".wrapper_canvas").find("#camera-image");
            var image = canvas_new.get(0).toDataURL("image/png");
            let file = dataURItoFile(image, "image.jpg");
            const images = [];
            $("html")
              .find(".wrapper_canvas .imageBoxDR")
              .each(function () {
                // Test if the div element is empty
                var left = $(this).css("left").replace("px", "");
                var top = $(this).css("top").replace("px", "");
                images.push({
                  id: $(this).data("id"),
                  left: left,
                  top: top,
                });
              });
            var i = 0;
            var text = [];
            $("html")
              .find(".amm-text-boxes .wrapper-text ")
              .each(function () {
                // Test if the div element is empty
                i = $(this).attr("delta");
                var top = $("html")
                  .find(".wrapper_canvas #boxDR_camera-image_" + i)
                  .css("top")
                  .replace("px", "");
                var left = $("html")
                  .find(".wrapper_canvas #boxDR_camera-image_" + i)
                  .css("left")
                  .replace("px", "");
                text.push({
                  id: i,
                  text: $("html")
                    .find(
                      ".amm-text-boxes .wrapper-text #text_camera-image_" + i
                    )
                    .val(),
                  left: left,
                  top: top,
                });
              });
            var data = JSON.stringify(images);
            var textbox = JSON.stringify(text);
            const formData = new FormData();
            formData.append("description", des);
            formData.append("file", file);
            formData.append("json", data);
            formData.append("text", textbox);
            const response = await fetch(
              `${url}/api/screen/post/wayfinder/images/${id}`,
              {
                method: "POST",
                body: formData,
              }
            );
            if (response.status === 200) {
              // navigate(`/wayfinder/camera/${id}?camera=2`);
              window.history.back();
            }
          });
        const jsonData = images.json_image ? images.json_image : null;
        const jsontextData = images.json_text ? images.json_text : null;
        if (jsonData) {
          const jsonArray = JSON.parse(jsonData);
          jsonArray.map((item, index) => {
            $("#minleft").val((item.left / 1000) * 2.2);
            $("#mintop").val((item.top / 1000) * 3);
            $("html")
              .find(".wrapper-thumbnail-merge-images #" + item.id)
              .trigger("click");
            $("#minleft").val("");
            $("#mintop").val("");
            return null; // If you're using map for its side effect and not creating a new array
          });
        }
        if (jsontextData) {
          const jsontextArray = JSON.parse(jsontextData);
          jsontextArray.map((item, index) => {
            $("#texttop").val(item.top);
            $("#textleft").val(item.left);
            $("html").find(".add_textbox").trigger("click");
            $("html")
              .find(".wrapper-text #text_camera-image_" + item.id)
              .val(item.text);
            $("#texttop").val("");
            $("#textleft").val("");
            return null; // If you're using map for its side effect and not creating a new array
          });
        }
      })(this, window.jQuery);
    }
  }, [img_urls, images]);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/wayfinder/wayfinder/single/image/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        image_url = `/uploads/files/wayfinder/${json.images_list.orignal_image}`;
        setimg_url(image_url);
        setImages(json.images_list);
      } else {
        toast.error("No Image Found");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-12 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/wayfinder">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>edit image </h3>
                  <p>Add directions / text to the existing image</p>
                </div>
              </div>
            </div>
          </div>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-xl-3 col-lg-3 cust-col-3">
                <div className="label-wrap">
                  <h4>location direction</h4>
                  <p>Enter the precise direction of location</p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div className="inpt-wrap">
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    id="description"
                  />
                </div>
              </div>
            </div>
            <div className="justify-content-center">
              {/* <div className="col-lg-6 cust-col-6">
            <div className="row justify-content-start">
              <div className="col-lg-4">
                <div className="btns-wrap-modal">
                  <button className="btn btn-gray ms-0 w-100">
                    <img src={MergeImg} alt="" /> Merge Images
                  </button>
                  <button className="btn btn-gray ms-0 w-100">
                    <img src={DrawingBrush} alt="" /> drawing
                    brush
                  </button>
                  <button className="btn btn-gray ms-0 w-100">
                    <img src={AddText} alt="" /> add text box
                  </button>
                  <button className="btn btn-gray ms-0 w-100">
                    <img src={ReplaceImg} alt="" /> replace image
                  </button>
                  <div className="btns-small">
                    <button className="btn btn-gray">
                      <img src={MoveLeft} alt="" />
                    </button>
                    <button className="btn btn-gray">
                      <img src={MoveRight} alt="" />
                    </button>
                    <button className="btn btn-gray">
                      <img src={MoveTop} alt="" />
                    </button>
                    <button className="btn btn-gray">
                      <img src={MoveBottom} alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="upld-img-modal-wrap w-100 h-100">
                  <div className="upld-img-wrap w-100 h-100">
                    <div className="main-img w-100 h-100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
              {/* <div className="col-lg-12 cust-col-12"> */}
              <div id="camera-image"></div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleImageEdit;
