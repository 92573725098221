import React, { useEffect, useState } from "react";

import Header from "../../partials/Header";
import styles from "./style.module.css";
import Sidebar from "../../partials/Sidebar";
import BackIcon from "../../../images/icon-back.png";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { loginSuccess } from '../../Redux/Authentication';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
const PasswordChange = () => {
  const dispatch = useDispatch();

  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [PassLoading, setPassLoading] = useState(false);
  const [credentials, setCredentials] = useState({ name: (isAuthenticated ? isAuthenticated.name : ""), email: (isAuthenticated ? isAuthenticated.email : ""), phone: (isAuthenticated ? isAuthenticated.phone : ""), current_password: "", new_password: "", confirm_password: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const passwordFormSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    setPassLoading(true);
    const response = await fetch(`${url}/api/profile/password/change`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        current_password: credentials.current_password,
        new_password: credentials.new_password,
        confirm_password: credentials.confirm_password,
      })
    });
    setPassLoading(false);

    const json = await response.json();
    console.log(json)
    if (json.success) {
      localStorage.setItem("token", json.authToken);
      dispatch(loginSuccess(json.user));
      setCredentials({
        current_password: '',
        new_password: '',
        confirm_password: '',

      });
      toast.success("Password updated successfully");
    } else {
      toast.error(json.error);
    }
  };
  const iocnStyle3 = {
    display: PassLoading ? "inline-block" : "none",
  };



  



  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/profile">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>change password</h3>
                  <p>Set a nwe password for your account</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={passwordFormSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>current password</h4>
                      <p>Enter your current account password</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="password"
                        className="form-control"
                        id="currentpassword"
                        placeholder="*********"
                        required
                        name="current_password"
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>new password</h4>
                      <p>Enter your new account password</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="password"
                        className="form-control"
                        id="newpassword"
                        placeholder="*********"
                        required
                        name="new_password"
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>re-enter new password</h4>
                      <p>Re-enter your new account password</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="password"
                        className="form-control"
                        id="re-newpassword"
                        placeholder="*********"
                        required
                        name="confirm_password"
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>

              <div className="col-lg-12 text-end my-5">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button className="btn btn-prim w-auto ms-5" disabled={PassLoading}>
                    <i className="fa fa-spinner fa-spin" style={iocnStyle3}></i>
                    update password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordChange;
