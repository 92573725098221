import React, { useEffect, useState } from "react";
import Header from "../../../partials/Header";
import Sidebar from "../../../partials/Sidebar";
import Footer from "../../../partials/Footer";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../../../images/icon-back.png";
import styles from "./style.module.css";
import { toast } from "react-toastify";
const DoorEdit = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [getSite, setSite] = useState([]);
  const [getSiteUrl, setSiteUrl] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    siteData();
    fetchData();
  }, []);
  const [credentials, setCredentials] = useState({ site_relay_url: "", relay_no: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/integrator/door/update`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          site_id: selectedSite,
          site_relay_url: credentials.site_relay_url,
          relay_no: credentials.relay_no,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    console.log(json)
    if (json.success) {
      setCredentials({
        site_relay_url: '',
        relay_no: '',
      });
      setSelectedSite('');
      toast.success('Door added Successfully');
    } else {
      toast.error('failed to add door');
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/integrator/sites`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json);
      if (json.success) {
        setSite(json.sites);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSiteChange = async (event) => {
    const id = event.target.value;
    if (id !== '') {
      setSelectedSite(id);
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/integrator/site/url/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json)
      if (json.success) {
        setSiteUrl(json.urls);
      }
    } else {
      setSiteUrl('');
      console.log('id is empty')
    }
  }
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/integrator/door/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSelectedSite(json.door.site_id);
        credentials.relay_no = json.door.relay_no;
        credentials.site_relay_url = json.door.site_relay_url;
        setSiteUrl(json.door.site_relay_url);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/door/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Edit door</h3>
                  <p>Update a  door by entering the credentials</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>select site</h4>
                      <p>The site where the door will be added</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="site" onChange={handleSiteChange} value={selectedSite} required>
                        <option value="">Choose Site</option>
                        {getSite.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>relay no.</h4>
                      <p>Enter the relay number of site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="number"
                        name="relay_no"
                        className="form-control"
                        placeholder="5309923577892"
                        value={credentials.relay_no}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>site relay URL</h4>
                      <p>Enter the site relay URL</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="site_relay_url" required value={credentials.site_relay_url}
                        onChange={handleInput} >
                        <option value="" selected></option>
                        {getSiteUrl !== '' &&
                          <option value={getSiteUrl}>{getSiteUrl}</option>
                        }
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" className="btn btn-prim w-auto ms-5" disabled={isLoading}>
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> update door
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default DoorEdit