import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import BackIcon from '../../../images/icon-back.png'
import Select from 'react-select';
import { Link, useNavigate } from "react-router-dom";
import fetchTypesHandler from "../../../api/qr/fetchTypesHandler";
import { toast } from "react-toastify";
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const front_url = process.env.REACT_APP_BASE_URL;
  const [appendedHTML, setAppendedHTML] = useState("");
  useEffect(() => {
    getTypes();
    getTenants();
  }, []);
  const [qrTypes, setQrTypes] = useState([]);
  const [getTenant, setTenants] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [wifi, setwifi] = useState(false);
  const [wayfinder, setwayfinder] = useState(false);
  const [def, setdefault] = useState(true);
  const [qr_types, set_qr_types] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    name: "",
    qr_type: "",
    text: "",
    tenants: "",
    ssid: "",
    password: "",
    encryption: "",
    qr_types_name: ""
  });
  useEffect(() => {
    console.log(credentials.qr_type)
    const selectedQrType = qrTypes.find((qrType) => qrType._id === credentials.qr_type);
    console.log(selectedQrType?.name)
    selectedQrType && handleQrTypes(selectedQrType?.name)
  }, [credentials.qr_type])
  const handleQrTypes = (type) => {
    set_qr_types(type)
    console.log(qr_types, 'type')
    switch (type) {
      case 'wifi':
        console.log('wifi case')
        setwifi(true);
        setwayfinder(false);
        setdefault(false);
        break;
      case 'wayfinder':
        console.log('wayfinder case')
        setwifi(false);
        setwayfinder(true);
        setdefault(false);
        break;
      default:
        console.log('default case')
        setwifi(false);
        setwayfinder(false);
        setdefault(true);
        break;
    }
  }
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    console.log(credentials)
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    const formData = JSON.stringify({
      name: credentials.name,
      qr_types_name: qr_types,
      site_id: isAuthenticated.site,
      qr_type: credentials.qr_type,
      text: credentials.text,
      tenants: selectedTenants,
      ssid: credentials.ssid,
      url: front_url,
      password: credentials.password,
      encryption: credentials.encryption,
    })
    console.log(formData);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    try {
      const response = await fetch(`${url}/api/type/createSiteQR`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: formData,
      });
      setIsLoading(false);
      const json = await response.json();
      console.log(json);
      if (json.success) {
        setCredentials({
          name: "",
          qr_type: "",
          text: "",
          tenants: "",
          ssid: "",
          password: "",
          encryption: "",
          qr_types_name: ""
        });
        toast.success("Site QR Created Successfully");
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const getTypes = async () => {
    try {
      const response = await fetchTypesHandler();
      setQrTypes(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleTenantsSelection = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedTenants(selectedValues);
  };
  const getTenants = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/type/getTenants`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setTenants(json);
    } catch (error) {
      console.error(error);
    }
  };
  const getTenantOptions = [
    ...getTenant.map((per) => ({
      value: per._id,
      label: per.name,
    })),
  ];
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/site/qr/codes/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
              </div>
              <div className="user-back-det text-capitalize">
                <h3>create Site Qr Code</h3>
                <p>
                  List of all the sites qrcode in the system
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row text-capitalize">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap ">
                      <h4>Name</h4>
                      <p>Enter the precise name of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        placeholder="Enter Name of Type"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Site QR Type</h4>
                      <p>Select site qr type</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="qr_type"
                        required
                        value={credentials.qr_type}
                        onChange={handleInput}
                      >
                        <option value="">Choose QR Types</option>
                        {qrTypes.map((per) => (
                          <option className="text-capitalize" data-id={per._id} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              {def &&
                <>
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>QR Text</h4>
                          <p>Enter text for QR Code</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <textarea
                            className="form-control"
                            id="text"
                            name="text"
                            placeholder="Enter text for QR Code"
                            value={credentials.text}
                            onChange={handleInput}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                </>
              }
              {wayfinder &&
                <>
                  {console.log('first')}
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>Select Tenants</h4>
                          <p>Select Tenants</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <Select
                            isMulti
                            name="tenants"
                            options={getTenantOptions}
                            className="basic-multi-select text-capitalize"
                            classNamePrefix="select"
                            onChange={handleTenantsSelection}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {wifi &&
                <>
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>SSID</h4>
                          <p>Enter ssid for wifi network</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            name="ssid"
                            placeholder="Enter SSID"
                            className="form-control"
                            value={credentials.ssid}
                            onChange={handleInput}
                          ></input>
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>Password</h4>
                          <p>Enter Wifi Password</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            name="password"
                            placeholder="Enter Wifi Password"
                            className="form-control"
                            value={credentials.password}
                            onChange={handleInput}
                          ></input>
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>Encryption: </h4>
                          <p>Enter Encryption:  For Wifi Network</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <select
                            name="encryption"
                            value={credentials.encryption}
                            onChange={handleInput}
                          >
                            <option value="">Select Encryption</option>
                            <option value="WPA">WPA/WPA2/WPA3</option>
                            <option value="WEP">WEP</option>
                            <option value="no_password">None</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a
                    href=""
                    className="btn btn-prim btn-transparent w-auto"
                  >
                    Reset Form
                  </a>
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{isLoading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Add;
