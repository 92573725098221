import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
import NotificationIcon from "../../../images/icon-notifications.png";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchIcon from "../../../assets/icons/search.svg";

import NextIcon from "../../../images/next.png";
import PreviousIcon from "../../../images/prev.png";
import QrIconTable from "../../../images/icon-qr-tb.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
const Camera = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  useEffect(() => {
    // fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/camera/getChannelList`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.data.length > 0) {
        setIsDisplay(true);
        setData(json.data);
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-sidebar">
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Cameras</h3>
                            <p>List of all the cameras in the system</p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end">
                          <div className="filter-right-sec">
                            <div className="filter-wrap">
                              <div className="filter-box">
                                <i className="fas fa-filter" />
                              </div>
                            </div>
                            <div className="search-wrap">
                              <div className="search-box">
                                <img src={SearchIcon} alt="" />
                              </div>
                            </div>
                            {/* <Link to="/addnewcamera" className='btn btn-prim w-auto'>add camera</Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="table-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                                <table className="table align-middle text-capitalize">
                                  <thead>
                                    <tr>
                                      <th>qr</th>
                                      <th>name</th>
                                      <th>status</th>
                                      <th>added</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="usr_det_tb">
                                          <div className="img_wrap_qr_tb">
                                            <img
                                              className="not_prof_img"
                                              src={QrIconTable}
                                              alt=""
                                            />
                                          </div>
                                          <span> Michael Hogan</span>
                                        </div>{" "}
                                      </td>
                                      <td>First Camera</td>
                                      <td>
                                        <span className="status success">
                                          Active
                                        </span>
                                      </td>
                                      <td>septembar 15, 2023</td>
                                      <td>
                                        <Link to={'/live/streaming/17'}>
                                          <button
                                            type="button"
                                            className="btn btn-prim"
                                          >
                                            Live Streaming
                                          </button>
                                        </Link>
                                      </td>
                                    </tr>
                                    {isLoading && (
                                      <>
                                        <tr>
                                          <td
                                            colSpan={5}
                                            className="text-center"
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <HashLoader
                                              color={`#0b0d41`}
                                              loading={isLoading}
                                              size={40}
                                              aria-label="Loading Spinner"
                                              data-testid="loader"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    {isDisplay &&
                                      data.map((item, i) => (
                                        <tr>
                                          <td>
                                            <div className="usr_det_tb">
                                              <div className="img_wrap_qr_tb">
                                                <img
                                                  className="not_prof_img"
                                                  src={QrIconTable}
                                                  alt=""
                                                />
                                              </div>
                                              <span> Michael Hogan</span>
                                            </div>{" "}
                                          </td>
                                          <td>main auditorium hallway cam</td>
                                          <td>
                                            <span className="status success">
                                              working
                                            </span>
                                          </td>
                                          <td>august 11, 2023</td>
                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-prim"
                                            >
                                              Live Streaming
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    {isNotFound && (
                                      <tr>
                                        <td colSpan={6} className="text-center">
                                          No Data Found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Camera;
