import React, { useEffect, useRef } from 'react';
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { Link} from "react-router-dom";
import BackIcon from "../../../images/icon-back.png";
import styles from "./style.module.css";
import { useSelector } from 'react-redux';
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);

const Streaming = () => {
  const videoRef = useRef(null);
  const isAuthenticated = useSelector((state) => state.user);
  useEffect(() => {
    // const ws = new WebSocket(socket_url);

    // ws.onopen = () => {
    //   console.log('WebSocket connection established');
    // };

    // ws.onmessage = (event) => {
    //   const frameData = event.data;
    //   const canvas = document.getElementById('videoCanvas');
    //   const context = canvas.getContext('2d');
    
    //   const image = new Image();
    //   image.src = URL.createObjectURL(new Blob([frameData], { type: 'image/jpeg' }));
    //   image.onload = () => {
    //     context.drawImage(image, 0, 0, canvas.width, canvas.height);
    //   };
    // };

    // return () => {
    //   ws.close();
    // };
    socket.on('data', (data) => {
        const frameData = data;
      const canvas = document.getElementById('videoCanvas');
      const context = canvas.getContext('2d');
    
      const image = new Image();
      image.src = URL.createObjectURL(new Blob([frameData], { type: 'image/jpeg' }));
      image.onload = () => {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
    
      // Assuming the received data is a base64-encoded image
      // const imageSrc = `data:image/jpeg;base64,${data}`;
      // videoRef.current.src = imageSrc;
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className={styles.heading}>
    <div className="body-wrap">
      <section className={styles.sidebar_wrap}>
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col col-sidebar">
              <Sidebar />
            </div>
            <div className="col col-dashboard">
              <Header />
              <div className="general-dashboard">
                <div className="container-fluid">
                  <div className="dashboard-top-row add-new-top-row">
                    <div className="row">
                      <div className="col-lg-6 my-auto">
                        <div className="user-wrap user-back-wrap">
                          <Link to="/wayfinder">
                            <div className="back-icon">
                              <img src={BackIcon} alt="" />
                            </div>
                          </Link>
                          <div>
                            <h3>add wayfinder</h3>
                            <p>Create new wayfinder directions for tenant</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end"></div>
                    </div>
                  </div>
                  {/* row */}
                    <div className="form-box card-box">
                    <canvas id="videoCanvas" height={'500px'} width={'9000px'}></canvas>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  );
};

export default Streaming;
