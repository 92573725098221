import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import SearchIcon from "../../../assets/icons/search.svg";

import NextIcon from "../../../images/next.png";
import PreviousIcon from "../../../images/prev.png";
import QrIconTable from "../../../images/icon-qr-tb.png";
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
import NotificationIcon from "../../../images/icon-notifications.png";
import { Link, useNavigate } from "react-router-dom";
import RolePermissions from './Permission';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const RolesPermissions = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [table, setTable] = useState(true);
  const [formData, setFormData] = useState({});
  const [permission, setPermission] = useState([]);
  const [allPermsn, setPermsn] = useState([]);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    fetchData();
    fetchPermissionData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/roles/getRoles`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json);
      setData(json);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchPermissionData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/roles/getRolesPermissions`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json);
      setPermsn(json);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      setConfirmLoading(true);
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/roles/delete/${id}`, {
        mode: "cors",
        method: "DELETE",
        headers: headers,
      });
      toast.success("Role deleted successfully!");
      setConfirmLoading(false);
      fetchData(); // Refresh the records list after successful deletion
    } catch (error) {
      console.log("Error deleting record:", error);
    }
  };
  const handleEdit = (item) => {
    setSelectedItem(item);
    setFormData({ ...item });
    setEditMode(true);
  };
  const closeForm = () => {
    setEditMode(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    // const response = await fetch("http://54.174.4.89:5000/api/auth/login", {
    //   mode: "cors",
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify({
    //     email: credentials.email,
    //     password: credentials.password,
    //   }),
    // });
    const response = await fetch(
      `${url}/api/roles/updateRoles/${selectedItem._id}`,
      {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          name: formData.name,
          permissions: permission,
        }),
      }
    );
    const json = await response.json();
    setFormData("");
    setEditMode(false);
    setPermission([]);
    fetchData();
    if (json.success) {
      alert("success");
    } else {
      alert("Error");
    }
  };
  const handleCourseSelection = (e) => {
    const permissionId = e.target.value;
    setPermission((prevSelected) => [...prevSelected, permissionId]);
  };
  return (
    <div className="general-dashboard text-capitalize">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h2>Roles / Permissions</h2>
                <p>
                  List of all the roles and permissions assigned
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box">
                    <i className="fas fa-filter" />
                  </div>
                </div>
                <div className="search-wrap">
                  <div className="search-box">
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                <Link
                  to="/add/permission"
                  className="btn btn-prim w-auto"
                >
                  Add New Permissions
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div
                  className={`${styles.tables_wrap} ${styles.tables_role}`}
                >
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>Role</th>
                          <th>Permissions</th>
                          <th>action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((item, key) => (
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.permissions.map((item, key) => (
                                <RolePermissions key={item} id={item} />
                              ))}</td>

                              <td>
                                <div className="g-2">
                                  <Link to={`/edit/rolePermission/${item._id}`}
                                  >
                                    <button className="btn btn-edit tb-btn">
                                      Edit
                                    </button>
                                  </Link>

                                  <button
                                    type="button"
                                    onClick={() => {
                                      const result = Swal.fire({
                                        title: 'Are you sure you want to delete this Role?',
                                        text: "You won't be able to revert this!",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Yes, delete it!',
                                        cancelButtonText: 'No, cancel',
                                        reverseButtons: true,
                                        customClass: {
                                          container: 'custom-alert-container', // Custom class for the alert container
                                        },
                                      });
                                      if (result.isConfirmed) {
                                        handleDelete(item._id)
                                      }
                                    }}
                                    className="btn btn-delete tb-btn"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="3"
                              className="text-center text-danger"
                            >
                              No data found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesPermissions;
