import React, { useState, useEffect } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import ChatSidebar from "./Sidebar";
import { useSelector } from "react-redux";
import DateDisplay from "../date/DateDisplay";
import './message.css';
import { Link } from "react-router-dom";
const SentEmails = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    fetchData();
   
  }, []);

  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token",localStorage.getItem("token"));
      const response = await fetch(`${url}/api/video/chat/get/sentmessages?id=${isAuthenticated._id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
 
      setData(json.chats);
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div style={{ marginTop: "100px" }}>
            </div>
            <div className="page-header">
              <h1 className="page-title">Chat</h1>
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                   Chat
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
                <ChatSidebar />
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <div className="card">
                <div className="card-body p-6">
                    <div className="inbox-body">

                        <div className="table-responsive">
                            <table className="table table-inbox table-hover text-nowrap mb-0">
                                <tbody>
                                    

                               {data &&  data.map((item, index) => (
                                                        <tr key={index}>

                                              <td className="view-message dont-show fw-semibold clickable-row" ><Link to={`/sent/${item._id}`}>To:{item.users[0]?item.users[0].name:''} </Link></td>
                                              <td className="view-message clickable-row" ><Link to={`/sent/${item._id}`}>{item.subject=="" ? '(no subject)':item.subject}</Link></td>
                                               <td className="view-message text-end fw-semibold clickable-row" ><Link to={`/sent/${item._id}`}><DateDisplay date={item.createdAt} /></Link></td>
                                            </tr> 
                                            ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
           
        </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SentEmails;
