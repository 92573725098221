import React, { useState, useEffect } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import ChatSidebar from "./Sidebar";
import { useSelector } from "react-redux";
import "./message.css";
import DateDisplay from "../date/DateDisplay";
import {Link, useNavigate } from 'react-router-dom';

const Inbox = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = isAuthenticated._id;
  let navigate = useNavigate();



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/chat/conversation/list/${userId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
    console.log(json)

      if (json.success) {
        setData(json.conversations);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getReceiverId =  (item)=>{
      let receiverId;
      if(item.senderId===userId){
        receiverId = item.receiverId;
      }else{
        receiverId = item.senderId;

      }
      return receiverId;
  }


  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div style={{ marginTop: "100px" }}></div>
            <div className="page-header">
              <h1 className="page-title">Chat</h1>
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Chat
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <ChatSidebar />
              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                <div className="card">
                  <div className="card-body p-6">
                    <div className="inbox-body">
                      <div className="mail-option">
                        <div className="chk-all">
                          <div className="btn-group">
                            <a data-bs-toggle="dropdown" href="javascript:void(0)" className="btn mini all" aria-expanded="false">
                              All
                              <i className="fa fa-angle-down "></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-start">
                              <li><a href="/message/inbox?query=read"> Read</a></li>
                              <li><a href="/message/inbox?query=unread"> Unread</a></li>
                            </ul>
                          </div>
                        </div>
                        <div className="btn-group">
                          <a href="/message/inbox" className="btn mini tooltips">
                            <i className=" fa fa-refresh"></i>
                          </a>
                        </div>


                      </div>
                      <div className="table-responsive">
                        <table className="table table-inbox table-hover text-nowrap mb-0">
                          <tbody>


                            {data && data.map((item, index) => (
                              <tr key={index}>
                                <td className="inbox-small-cells">
                                  <label className="custom-control custom-checkbox mb-0 ms-3">
                                    <input type="checkbox" className="custom-control-input" name="example-checkbox2" value="option2" />
                                    <span className="custom-control-label"></span>
                                  </label>
                                </td>
                                <td className="inbox-small-cells"><i className="fa fa-star inbox-started"></i></td>
                                <td className="view-message dont-show fw-semibold clickable-row"><Link to={`/chat/${getReceiverId(item)}`}>{item.senderName}</Link></td>
                                <td className="view-message clickable-row" >{item.subject == "" ? '(no subject)' : item.subject}</td>
                                <td className="view-message text-end fw-semibold clickable-row"><DateDisplay date={item.createdAt} /></td>
                              </tr>
                            ))}

                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Inbox;
