// authActions.js

// Action Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_CONNECTION = 'SET_CONNECTION';
export const DISCONNECT_CONNECTION = 'DISCONNECT_CONNECTION';

// Action Creators

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
  
});

// add logout
export const logout = () => ({
  type: LOGOUT,
});
// set WEBRTC in video call

export const setConnection = (connection) => ({
  type: SET_CONNECTION,
  payload: connection,
});
// disconnect call connection
export const disconnectConnection = (connection) => ({
  type: DISCONNECT_CONNECTION,
});
// Thunk Action
export const loginUser = (credentials) => async (dispatch) => {
  try {
    // Call your MongoDB API to authenticate the user
    // const user = await api.login(credentials);
    const user='';
    // Dispatch the login success action
    dispatch(loginSuccess(user));
  } catch (error) {
    // Handle any login errors
    console.log('Login failed:', error.message);
  }
};