import React from 'react'

const VideoOrImages = ({ videoName }) => {
    // Remove spaces and special characters from videoName
    const cleanedVideoName = videoName.replace(/[^\w.]/g, "").toUpperCase();
  
    const isValidVideo =
      cleanedVideoName.includes(".MP4") ||
      cleanedVideoName.includes(".MPV") ||
      cleanedVideoName.includes(".WMV") ||
      cleanedVideoName.includes(".FLV") ||
      cleanedVideoName.includes(".AVI") ||
      cleanedVideoName.includes(".AVCHD") ||
      cleanedVideoName.includes(".WEBM") ||
      cleanedVideoName.includes(".MKV");
  
    return (
      <div>
        {isValidVideo ? (

        <video controls style={{ width: "190px", height: "auto", border: "2px solid #ccc" }}>
            <source src={`/uploads/screen/${videoName}`} type="video/mp4" />
        </video>
        ) : (
          <img src={`/uploads/screen/${videoName}`} alt="Preview"  />
        )}
      </div>
    );
  };
  

export default VideoOrImages