// RolePermissions.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
function EditPermission({ ids, updateParentState }) {
  const { id } = useParams();
  const [permission, setPermission] = useState([]);
  const [defaultPermission, setDefaultPermission] = useState([]);
  const [formData, setFormData] = useState({});
  const url = process.env.REACT_APP_APP_BACK_URL;
  useEffect(() => {
    // Fetch permissions for the role from your backend API
    fetchPermissionData(ids);
    getRole(id);
  }, [ids, id]);

  const fetchPermissionData = async (ids) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/roles/getPermission?section=${ids}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();

      setPermission(json);
    } catch (error) {
      console.error(error);
    }
  };
  // get role
  const getRole = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/roles/getRole/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setDefaultPermission(json.permissions);
      setFormData({ ...json });
    } catch (error) {
      console.error(error);
    }
  };
  // edit checkbox
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    // If the checkbox is checked, add the value to the state; otherwise, remove it
    if (checked) {
      setDefaultPermission((prevPermissions) => [...prevPermissions, value]);
    } else {
      setDefaultPermission((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== value)
      );
    }

    updateParentState(value, checked);
  };

  return (
    <>
      {permission.map((item, key) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            value={item._id}
            id="flexCheckDefault"
            checked={defaultPermission.includes(item._id) ? "checked" : ""}
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label" for="flexCheckDefault">
             {item.name}
          </label>{" "}
        </>
      ))}
    </>
  );
}

export default EditPermission;
