import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { useSocket } from "../Sockets/SocketProvider";
import SimplePeer from 'simple-peer';

const Chat_ser = ({ user, roomId }) => {
  const [peer, setPeer] = useState(null);
  const [otherUser, setOtherUser] = useState(null);
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const socket = useSocket();
  useEffect(() => {
     // Replace with your server URL
    socket.emit('join-room', roomId, user.id);

    socket.on('user-connected', (userId) => {
      setOtherUser(userId);
      const peer = createPeer(userId, socket.id);
      setPeer(peer);
    });

    socket.on('incoming-call', ({ signal, from }) => {
      setOtherUser(from);
      const peer = addPeer(signal, from);
      setPeer(peer);
    });

    socket.on('call-accepted', (signal) => {
      peer.signal(signal);
    });

    return () => {
      socket.disconnect();
    };
  }, [roomId, user.id]);

  const createPeer = (userId, callerId) => {
    const peer = new SimplePeer({
      initiator: true,
      trickle: false,
    });

    peer.on('signal', (signal) => {
      socket.emit('call-user', { userToCall: userId, signalData: signal, from: callerId });
    });

    peer.on('stream', (stream) => {
      remoteVideoRef.current.srcObject = stream;
    });

    return peer;
  };

  const addPeer = (signal, callerId) => {
    const peer = new SimplePeer({
      initiator: false,
      trickle: false,
    });

    peer.on('signal', (data) => {
      socket.emit('answer-call', { signal: data, to: callerId });
    });

    peer.on('stream', (stream) => {
      remoteVideoRef.current.srcObject = stream;
    });

    peer.signal(signal);

    return peer;
  };

  const startCall = () => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        localVideoRef.current.srcObject = stream;
        peer.addStream(stream);
      })
      .catch((error) => console.error('Error accessing media devices:', error));
  };

  return (
    <div>
      <h2>Video Chat with {otherUser}</h2>
      <video ref={localVideoRef} autoPlay playsInline muted style={{ width: 200, height: 150 }} />
      <video ref={remoteVideoRef} autoPlay playsInline style={{ width: 400, height: 300 }} />
      <button onClick={startCall}>Start Call</button>
    </div>
  );
};

export default Chat_ser;
