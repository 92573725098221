import React, { useEffect, useRef ,useState } from "react";
import io from "socket.io-client";


function Live() {
  const [socket, setSocket] = useState(null);
    const [videoSource, setVideoSource] = useState(null);
    const canvasRef = useRef(null);

  useEffect(() => {
    // const socket = io("http://localhost:5000");
    // socket.on("connect", () => {
    //   console.log("Socket connected");
      
    // });

    

    // socket.on("disconnect", () => {
    //   console.log("Disconnected from WebSocket server");
    // });

    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  return (
    <div className="App">
       {videoSource ? (
        <video ref={canvasRef} autoPlay controls>
          <source src={videoSource} type="video/jpeg" />
        </video>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Live;
