import React, { useState } from 'react'
import Header from '../partials/Header'
import Sidebar from '../partials/Sidebar';
import styles from "./style.module.css";
const RootLayout = ({ children }) => {
      const [isActive, setIsActive] = useState(false);
      const toggleActive = () => {
        setIsActive(!isActive);
    };
    const divClassName = isActive ? 'sidebar active' : 'sidebar';
    return (
        <>
            <div className={styles.heading}>
                <section className={styles.sidebar_wrap}>
                    <div className="body-wrap">
                        <div className="container-fluid px-0">
                            <div className="row">
                                <div className={`col col-sidebar  ${divClassName} `}>
                                    <Sidebar />
                                </div>
                                <div className="col col-dashboard">
                                    <Header isActive={isActive} toggleActive={toggleActive} />
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default RootLayout