import React, { useEffect, useState } from "react";
import Header from "../../../partials/Header";
import Sidebar from "../../../partials/Sidebar";
import BackIcon from '../../../../images/icon-back.png'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import styles from "./style.module.css";
import { toast } from "react-toastify";
function PhoneInput(props) {
  return (
    <InputMask
      mask='+1999 999 9999'
      value={props.value}
      onChange={props.onChange} placeholder="+1123 123 1234" className="form-control">
    </InputMask>
  );
}
const AddAppointments = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [appendedHTML, setAppendedHTML] = useState("");
  const [getClients, setClients] = useState([]);
  const [getSite, setSite] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const base_url = process.env.REACT_APP_BASE_URL;
  const handlePhoneInput = (e) => { setPhone(e.target.value) };
  useEffect(() => {
    roleData();
    siteData();
  }, []);
  const [credentials, setCredentials] = useState({
    client: "",
    visitor: "",
    email: "",
    site: "",
    start_time: "",
    end_time: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(`${url}/api/appointments/createAppointment`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        client: credentials.client,
        site: isAuthenticated.site,
        visitor: credentials.visitor,
        email: credentials.email,
        phone: phone,
        date: credentials.date,
        time: credentials.time,
        base_url: base_url,
      }),
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        client: "",
        visitor: "",
        email: "",
        date: "",
        time: "",
      });
      setPhone('')
      toast.success("Appointment Created Successfully");
    } else {
      toast.error("failed to create appointment");
    }
  };
  const roleData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/appointments/getClients`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setClients(json);
    } catch (error) {
      console.error(error);
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/list`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setSite(json.site_data);
    } catch (error) {
      console.error(error);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };

  // Function to toggle the active state
  
  // Define the CSS classes based on the isActive state


  return (
    <div className="general-dashboard text-capitalize">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/building/appointment/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Add Appointment</h3>
                  <p>
                    Enter the information to add new appointment to the
                    system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Select Client</h4>
                      <p>Select the Client </p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="client" value={credentials.client} onChange={handleInput} required>
                        <option value="">Choose Client</option>
                        {getClients.map((per) => (
                          <option key={per.site} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Visitor</h4>
                      <p>Enter the visitor name</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        name="visitor"
                        placeholder="John Doe.."
                        required
                        value={credentials.visitor}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Email Address</h4>
                      <p>Enter email address of the visitor</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="example@exp.com"
                        required
                        value={credentials.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>phone</h4>
                      <p>Enter the contact number of visitor</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <PhoneInput
                        value={phone}
                        onChange={handlePhoneInput}>
                      </PhoneInput>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Date</h4>
                      <p>Enter appointment date</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="date"
                        name="date"
                        placeholder="+111-111..."
                        className="form-control"
                        value={credentials.date}
                        onChange={handleInput}
                      ></input>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Appointment Time</h4>
                      <p>Select appointment time</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        className="form-control form-select"
                        id="time"
                        name="time"
                        required
                        value={credentials.time}
                        onChange={handleInput}
                      >
                        <option value="">Choose Time</option>
                        <option value="8-9 am">8-9 AM</option>
                        <option value="9-10 am">9-10 AM</option>
                        <option value="10-11 am">10-11 AM</option>
                        <option value="11-12 am">11-12 AM</option>
                        <option value="12-1 pm">12-1 PM</option>
                        <option value="1-2 pm">1-2 PM</option>
                        <option value="2-3 pm">2-3 PM</option>
                        <option value="3-4 pm">3-4 PM</option>
                        <option value="4-5 pm">4-5 PM</option>
                        <option value="5-6 pm">5-6 PM</option>
                        <option value="6-7 pm">6-7 PM</option>
                        <option value="7-8 pm">7-8 PM</option>
                        <option value="8-9 pm">8-9 PM</option>
                        <option value="9-10 pm">9-10 PM</option>
                        <option value="10-11 pm">10-11 PM</option>
                        <option value="11-12 pm">11-12 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Forward Appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddAppointments;
