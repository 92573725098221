import React, { useState, useEffect, useRef } from "react";
import BackIcon from "../../../images/icon-back.png";
import RemoveImg from "../../../images/remove.png";
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { toast } from "react-toastify";
const EditImages = () => {
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/wayfinder/wayfinder/images/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setImages(json.images_list);
      } else {
        toast.error("No Image Found");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const deleteImage = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');
        const response = await fetch(`${url}/api/wayfinder/image/delete/${id}`, {
          mode: 'cors',
          method: 'DELETE',
          headers: headers,
        });
        if (response.ok) {
          fetchData(); // Refresh the records list after successful deletion
          Swal.fire(
            'Deleted!',
            'Image deleted successfully!',
            'success'
          );
        } else {
          Swal.fire(
            'Error',
            'An error occurred while deleting the record.',
            'error'
          );
        }
      }
    } catch (error) {
      console.log('Error deleting record:', error);
      Swal.fire(
        'Error',
        'An error occurred while deleting the record.',
        'error'
      );
    }
  }
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/wayfinder/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>set sequence</h3>
                  <p>Set the sequence for the uploaded images</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        <form action="">
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <h3>drag to adjust</h3>
                    <p>
                      Drag any image to readjust according to the
                      sequence
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center-mob mb-5">
                  {images.map((item, key) => (
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                      <div className="seq-wrap">
                        <span>{key + 1}</span>
                        <div className="upld-img-wrap">
                          <div className="remve-img">
                            <img src={RemoveImg} onClick={() => deleteImage(item._id)} alt="" />
                          </div>
                          <img src={`/uploads/files/wayfinder/${item.image}`} alt={item.image} />
                        </div>
                        <Link to={`/wayfinder/single/images/${item._id}`}>
                          <div
                            className="btn btn-prim"
                          >
                            Edit image
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="col-lg-12 text-end">
              <div className="form-btn-wrap mb-5 pb-5">
                <a
                  href=""
                  className="btn btn-prim btn-transparent w-auto"
                >
                  add more images
                </a>
                <Link to="/" className="btn btn-prim w-auto ms-5">
                  create wayfinder
                </Link>
              </div>
            </div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditImages;
