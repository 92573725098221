import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../images/icon-back.png'
import styles from './style.module.css'
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const EditTypes = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getQrType(id);
  }, [id]);
  const [credentials, setCredentials] = useState({ name: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/type/updateQrType/${id}`,
      {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      toast.success('Qrcode Type Updated Successfully');
    } else {
      toast.error(json.error);
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  const getQrType = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/type/getQrType/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setCredentials({ ...json });
    } catch (error) {
      console.error(error);
    }
  };

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/qr/type/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Update Qrcode Type</h3>
                  <p>
                    Enter the credentials to add new qrcode type to the
                    system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-12">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Name</h4>
                      <p>Enter the precise name of qrcode type</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        name="name"
                        placeholder="Enter QR code name.."
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditTypes;
