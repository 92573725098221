import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackIcon from '../../../images/icon-back.png'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import HashLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
import { formattedDate } from '../../../utils/functions';

const TenantAppView = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [appData, setAppData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [siteData, setsiteData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const isAuthenticated = useSelector((state) => state.user);
  const base_url = process.env.REACT_APP_BASE_URL;
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoader(true);
      const response = await fetch(`${url}/api/appointments/detail/page/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoader(false);
      const json = await response.json();
      if (json.success) {
        setAppData(json.app);
        setClientData(json.client);
        setsiteData(json.site);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateAppStatus = async (status) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(`${url}/api/appointments/status/change/${id}`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          status: status,
          base_url: base_url,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      console.log(json)
      if (json.success) {
        setAppData(json.app);
        toast.success('appointment updated Successfully');
      } else {
        toast.error('Failed to update appointment');
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="user-wrap user-back-wrap">
              <Link to="/tenant/appointment/list">
                <div className="back-icon">
                  <img src={BackIcon} alt="" />
                </div>
              </Link>
              <div>
                <h3>Appintment Detail</h3>
                <p>
                  View scheduled appointment details
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-end"></div>
        </div>
        {/* row */}
        {isLoader ? (
          <>
            <div className='text-center'>
              <HashLoader
                color={`#0b0d41`}
                loading={isLoader}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </>
        ) : (
          <>
            <form action="">
              <div className="form-box card-box appnt-set-box mt-5">
                <div className="row-top-appnt">
                  <div className="row">
                    <div className="col-xl-6">
                      <h3>Appointment with {clientData && clientData.name}</h3>
                      <p>
                        {siteData?.name}, {siteData?.address}
                      </p>
                    </div>
                    <div className="col-xl-6">
                      <div className="conditions-appnt">
                        <div className="d-flex align-items-center justify-content-end">
                          {appData?.status === "confirm" &&
                            <span className="appnt-alert success">
                              confirmed
                            </span>
                          }
                          {appData?.status === "checked-in" && (
                            <span className={`appnt-alert success`} style={{ marginLeft: "0px" }}>
                              Checked-In
                            </span>
                          )}
                          {appData?.status === "expire" &&
                            <span className="appnt-alert danger">
                              expired
                            </span>
                          }
                          {appData?.status === "cancel" &&
                            <span className="appnt-alert danger">
                              cancel
                            </span>
                          }
                          {appData?.status === "pending" &&
                            <span className="appnt-alert warning">
                              pending
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="details-wrap-appnt">
                  <div className="row justify-content-center">
                    <div className="col-xl-9">
                      <div className="inner-det-wrap">
                        <div className="row">
                          <div className="col-12">
                            <h3>visitor details</h3>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col col-name ps-3">
                                <h5>visitor</h5>
                                <h4>{appData?.name}</h4>
                              </div>
                              <div className="col col-email">
                                <h5>email</h5>
                                <h4>{appData?.email}</h4>
                              </div>
                              <div className="col col-contact">
                                <h5>contact</h5>
                                <h4>{appData?.phone}</h4>
                              </div>
                              <div className="col col-date pe-0">
                                <h5>date & time</h5>
                                <h4>{formattedDate(appData?.date)} at {appData?.time}</h4>
                              </div>
                            </div>
                          </div>
                          {appData?.status === "pending" && appData?.tenant_id === isAuthenticated._id && (
                            <div className="col-12 text-end">
                              <div className="btns-wrap appnt">
                                <button type='button' className="btn btn-prim btn-transparent w-auto me-3" onClick={() => updateAppStatus('cancel')} disabled={isLoading}>
                                  Deny
                                </button>
                                <button type='button' className="btn btn-prim w-auto" onClick={() => updateAppStatus('confirm')} disabled={isLoading}>
                                  comfirm
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 ">
                      <div className="scan-img-appnt text-end" >
                        <img src={`/${appData?.qr_image}`} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}
export default TenantAppView