import React from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import Sidebar from "./partials/Sidebar";

const About = () => {
  return (

    <div>
      <Header />
      <Sidebar />
      <h1>Dashboard</h1>
      <Footer />
    </div>
  );
};

export default About;
