import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import defaultAvatar from "../../../images/avatar.png";
import styles from "./style.module.css";
import BackIcon from '../../../images/icon-back.png'
import HashLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
const AppointmentDetail = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [appData, setAppData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    tenant: "",
  });
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/type/get/tenant/list/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setAppData(json.tenant_list);

      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);
    const response = await fetch(`${url}/api/type/find/tenantLocation`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        tenant: credentials.tenant

      }),


    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      navigate(`/wayfinder/location/details/${json.wayFinder._id}`);

      setCredentials({
        tenant: ''
      });
    } else {
      setIsLoading(false);
      toast.error(json.error);
    }
  };
  const handleInput = (e) => {

    setCredentials({ ...credentials, [e.target.name]: e.target.value });

  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-12">
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row mt-5">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">

                            <div className="user-back-det">
                              <h3>Find Tenant Locations</h3>
                              <p>
                                List of all the sites tenant and thier locations
                              </p>

                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">

                          <div className="col-lg-8">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Select Tenant</h4>
                                  <p>Select tenant to find location</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <select
                                    name="tenant"
                                    required
                                    value={credentials.tenant}
                                    onChange={handleInput}
                                  >
                                    <option value="">Choose Tenant </option>
                                    {appData && appData.map((per) => (
                                      <option data-id={per._id} value={per._id}>
                                        {per.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">

                              <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                                <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{isLoading ? "Finding..." : "Find Location"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default AppointmentDetail