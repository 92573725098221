import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import TenantIcon from "../../../images/icon-tenant.png";
import EmailIcon from "../../../images/icon-email.png";
import PhoneIcon from "../../../images/icon-ph.png";
import ScanTimeIcon from "../../../images/icons-scan-time.svg";
import CalendarIcon from "../../../images/icons-calendar.svg";
import QrIconTable from "../../../images/icons-day.svg";
import PendingIcon from "../../../images/icons-pending.svg";
import BackIcon from "../../../images/icon-back.png";
import ScanIcon from "../../../images/scan-bar.png";
import EditIcon from "../../../images/edit.png";
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../Redux/Authentication';
import styles from "./style.module.css";
import { toast } from "react-toastify";
const Profile = () => {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const imageUrl = isAuthenticated.image;
  const [profileImg, setProfileImg] = useState(isAuthenticated?.image || '/path/to/placeholder-image.png');
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [counter, setCounter] = useState([]);
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        setError(null);
        // Create a FileReader
        const reader = new FileReader();
        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        reader.onload = (e) => {
          const dataURL = e.target.result; // This is the data URL of the selected image
          setProfileImg(dataURL); // Set the data URL as the profileImg
        };
        await formSubmit(file);
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        //setFile(null);
        setError("Invalid file type. Please select an image file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };
  const formSubmit = async (file) => {
    try {
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      formData.append("id", isAuthenticated._id);
      const response = await fetch(
        `${url}/api/auth/profile/image/change`,
        {
          method: "POST",
          body: formData,
        }
      );
      const json = await response.json();
      if (json.success) {
        dispatch(loginSuccess(json.user));
        toast.success('Image uploaded Successfully');
      } else {
        toast.error('failed to upload image');
      }
    } catch (error) {
      console.log(error)
    }
  };
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/auth/profile/counter`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      console.log(json);
      setCounter(json.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/dashboard">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>profile</h3>
                  <p>Check your profile info or edit it</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <div className="profile-main-wrap">
          <div className="cont-row">
            <div className="row">
              <div className="col-lg-3 col-cust-3">
                {profileImg ? (
                  <div className="img-profile-wrap">
                    <img src={profileImg} />
                    <div className="edit-btn">
                      <input type="file" onChange={handleFileSelect} />
                      <img src={EditIcon} alt="as" />
                    </div>
                  </div>
                ) : (
                  <div className="img-profile-wrap">
                    <div className="edit-btn">
                      <input type="file" onChange={handleFileSelect} />
                      <img src={EditIcon} alt="" />
                    </div>
                  </div>
                )}
                <div style={{ fontSize: '12px', color: 'red', fontStyle: 'italic' }}>
                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <span>{uploadProgress}% uploaded</span>
                  )}
                </div>
                {error && <div style={{ fontSize: '12px', color: 'red', fontStyle: 'italic' }}>{error}</div>}
              </div>
              <div className="col-lg-9 col-cust-9">
                <div className="cont-profile-wrap">
                  <h4>{isAuthenticated && isAuthenticated.name}</h4>
                  <div className="cont-detail d-flex">
                    <a href="#">
                      <img src={TenantIcon} alt="" /> {isAuthenticated && isAuthenticated.role}
                    </a>
                    <a href="mailto:alexleveque@email.com">
                      <img src={EmailIcon} alt="" />{" "}
                      {isAuthenticated && isAuthenticated.email}
                    </a>
                    <a href="tel:202-555-0168">
                      <img src={PhoneIcon} alt="" /> {isAuthenticated && isAuthenticated.phone}
                    </a>
                  </div>
                  <p>
                    {isAuthenticated?.bio}
                  </p>
                </div>
              </div>
            </div>
            {/* row */}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                {/* <div className="col-lg-6 col-md-6">
                  <div className="profile-details">
                    <div className="icons-wrap success-box">
                      <img src={ScanTimeIcon} alt="" />
                    </div>
                    <div className="profile-content">
                      <span>12</span>
                      <div className="cont-inner">
                        <i>
                          times
                          <br /> scanned
                        </i>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-6">
                  <div className="profile-details">
                    <div className="icons-wrap danger-box">
                      <img src={CalendarIcon} alt="" />
                    </div>
                    <div className="profile-content">
                      <span>{counter.length !== 0 ? counter.totalCompleteApp : 0}</span>
                      <div className="cont-inner">
                        <i>
                          appointments <br />
                          completed
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-6">
                  <div className="profile-details">
                    <div className="icons-wrap prim-box">
                      <img src={QrIconTable} alt="" />
                    </div>
                    <div className="profile-content">
                      <span>35</span>
                      <div className="cont-inner">
                        <i>
                          days
                          <br /> member
                        </i>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-6">
                  <div className="profile-details">
                    <div className="icons-wrap warning-box">
                      <img src={PendingIcon} alt="" />
                    </div>
                    <div className="profile-content">
                      <span>{counter.length !== 0 ? counter.totalPendingApp : 0}</span>
                      <div className="cont-inner">
                        <i>
                          appointments
                          <br /> pending
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              </div>
              {/* row */}
            </div>
            <div className="col-lg-6">
              <div className="scan-profile-wrap text-center">
                <h3>my QR code</h3>
                {isAuthenticated.qr_image && isAuthenticated.qr_image !== '' ? (
                  <img src={`/${isAuthenticated.qr_image}`} alt="" />
                ) : (
                  <img src={ScanIcon} alt="" />
                )}
                <Link
                  to="/password/change"
                  className="btn btn-prim btn-transparent w-100"
                >
                  Change Passwrord
                </Link>
                <Link
                  to="/profile/edit"
                  className="btn btn-prim w-100"
                >
                  Edit Profile
                </Link>
              </div>
            </div>
            {/* row */}
          </div>
          {/* row */}
        </div>
      </div>
    </div>
  );
}
export default Profile