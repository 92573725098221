import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
const Scan = () => {
  const { id } = useParams();
  const [siteData, setSiteData] = useState(null);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const navigate = useNavigate();
  const [appointmentIds, setAppointmentIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [scannerInitialized, setScannerInitialized] = useState(false);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [status, setStatus] = useState('');
  let scanner;
  useEffect(() => {
    let interval;
    const handleKeyDown = (evt) => {
      if (interval) clearInterval(interval);
      if (evt.code === 'Enter') {
        if (barcode) handleBarcode(barcode);
        setBarcode('');
        return;
      }
      if (evt.key !== 'Shift') setBarcode((prevBarcode) => prevBarcode + evt.key);
      interval = setInterval(() => setBarcode(''), 20);
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [barcode]);
  useEffect(() => {
    getSite();
    if (!scannerInitialized) {
      scanner = new Html5QrcodeScanner('reader', {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
      });
      scanner.render(success, error);
      setScannerInitialized(true);
    }
    function success(result) {
      scanner.clear();
      setIsLoading(true);
      appointmentAuthentication(result);
    }
    function error(err) {
      console.warn(err)
    }
  }, [id, scannerInitialized]);
  useEffect(() => {
    if (authenticationFailed) {
      // Reinitialize the scanner if authentication fails
      setScannerInitialized(false);
      setAuthenticationFailed(false);
    }
  }, [authenticationFailed]);
  const getSite = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json);
      if (json.success) {
        setSiteData(json.site)
        if (json.apps) {
          setAppointmentIds(json.apps);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const appointmentAuthentication = (result) => {
  //   const currentId = result.toString();
  //   setIsLoading(false);
  //   const foundAppointment = appointmentIds.some(appointment => appointment._id === currentId); 
  //   if (foundAppointment) {
  //     console.log("app found");
  //     navigate(`/appointment/detail/page/${currentId}`);
  //   } else {
  //     console.log('appointment not found');
  //     notification.error({
  //       message: 'Not Found',
  //       description: 'Appointment Not Found',
  //       duration: 3
  //     });
  //     setAuthenticationFailed(true); 
  //   }
  // };
  const handleBarcode = async (scannedBarcode) => {
    // Handle the scanned barcode data here
    try {
      const siteId = id;
      setIsLoading(true);
      const response = await fetch(`${url}/api/appointments/info/${scannedBarcode}/${siteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error(`Failed to fetch site with id ${scannedBarcode}`);
      }
      const json = await response.json();
      if (json.success) {
        navigate(`/appointment/detail/page/${scannedBarcode}`);
      } else {
        toast.error('Appointment Not Found');
        setAuthenticationFailed(true);
      }
    } catch (error) {
      console.error(error);
    }
    // You can perform any further processing or API requests here
  };
  const appointmentAuthentication = async (result) => {
    try {
      const appId = result.toString();
      const siteId = id;
      setIsLoading(true);
      const response = await fetch(`${url}/api/appointments/info/${appId}/${siteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error(`Failed to fetch site with id ${id}`);
      }
      const json = await response.json();
      console.log(json);
      if (json.success) {
        if (json?.status === "pending") {
          const queryParam = 'status=1';
          navigate(`/appointment/detail/page/${appId}?${queryParam}`);
        } else {
          navigate(`/appointment/detail/page/${appId}`);
        }
      } else {
        console.log('appointment not found');
        toast.error('Appointment Not Found');
        setAuthenticationFailed(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const readerStyle = {
    width: '500px',
    position: 'relative',
    padding: '0px',
    float: 'right'
  };
  const cs = {
    padding: '100px',
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-12">
                <div className="general-dashboard">
                  <div className="container-fluid" style={cs}>
                    {/* row */}
                    <div className="scan-box-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="scanner">
                                    <h5>QR code for</h5>
                                    {siteData && siteData.name !== undefined && (
                                      <h3>{siteData.name}</h3>
                                    )}
                                    {siteData && siteData.name !== undefined && (
                                      <QRCode value={`${base_url}/external/new/appointment/${siteData._id}`} size={220} />
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6 text-end">
                                  <div className="scanning-section">
                                    <div id="qr-reader" />
                                    <div id="qr-reader-results" />
                                    {isLoading
                                      ? <div>
                                        <HashLoader
                                          color={`#0b0d41`}
                                          loading={isLoading}
                                          size={40}
                                          aria-label="Loading Spinner"
                                          data-testid="loader"
                                        />
                                      </div>
                                      : <div id='reader' style={readerStyle}></div>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="scan-cont">
                                <div className="row">
                                  <div className="col-lg-8 order-2 order-lg-1">
                                    <h5>Instructions</h5>
                                    <ol>
                                      <li>
                                        Scan provided Qr code and you received
                                        the self registration form.
                                      </li>
                                      <li>
                                        Fill the given form and select the
                                        Client whom you want to visit.
                                      </li>
                                      <li>
                                        Submit your form and your appointment
                                        request is sent to the specific client.
                                      </li>
                                      <li>
                                        After approval you will receive new text
                                        message and email with a QR code to
                                        present to the camera for entry.
                                      </li>
                                    </ol>
                                  </div>
                                  <div className="col-lg-4 order-1 order-lg-2 text-end">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
export default Scan