import axios from "axios";
const baseUrl = process.env.REACT_APP_APP_BACK_URL + '/api'
const defaultOptions = {

    baseURL: baseUrl,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true"
    }
}
const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers["auth-token"] = `${token}`;
    }
    return config
})


axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    }, (error) => {
        return Promise.reject(error)
    })

export default axiosInstance;