import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import BackIcon from '../../../images/icon-back.png'
import Select from 'react-select';
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../config/axios";
import fetchTypesHandler from "../../../api/qr/fetchTypesHandler";
import fetchTenantsHandler from "../../../api/tenants/fetchTenantsHandler";
import fetchQrCodeHandler from "../../../api/qr/fetchQrCodeHandler";
import { toast } from "react-toastify";
const Edit = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const front_url = process.env.REACT_APP_BASE_URL;
  const params = useParams();
  const [qrTypes, setQrTypes] = useState([]);
  const [getTenant, setTenants] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [wifi, setwifi] = useState(false);
  const [wayfinder, setwayfinder] = useState(false);
  const [def, setdefault] = useState(true);
  const [qr_types, set_qr_types] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    name: "",
    qr_type: "",
    text: "",
    tenant_id: "",
    ssid: "",
    password: "",
    encryption: ""
  });
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getTypes();
      await getTenants();
      await fetchQrCodeHandler(params.id).then((res) => {
        const selectedQrType = qrTypes.find((qrType) => qrType._id === res.data.qr_type_id);
        console.log(res.data, 'Response')
        switch (selectedQrType?.name) {
          case 'wifi':
            setwifi(true);
            setwayfinder(false);
            setdefault(false);
            break;
          case 'wayfinder':
            setwifi(false);
            setwayfinder(true);
            setdefault(false);
            setSelectedTenants(res?.data?.tenant_id ?? [])
            console.log(getTenantOptions)
            break;
          default:
            setwifi(false);
            setwayfinder(false);
            setdefault(true);
            break;
        }
        setCredentials({
          name: res?.data?.name,
          qr_type: res?.data?.qr_type_id ?? '',
          text: res?.data?.text ?? '',
          tenant_id: res?.data?.tenant_id ?? '',
          ssid: res?.data?.s_id ?? '',
          password: res?.data?.s_password ?? '',
          encryption: res?.data?.encryption ?? ''
        })
        setIsLoading(false);
      }).catch((err) => {
        toast.error(err.message)
        console.log(err)
      })
    })()
  }, []);
  useEffect(() => {
    const selectedQrType = qrTypes.find((qrType) => qrType._id === credentials.qr_type);
    console.log(selectedQrType, 'Selected QR Type')
    selectedQrType && handleQrTypes(selectedQrType?.name)
  }, [credentials.qr_type])
  const handleQrTypes = (type) => {
    switch (type) {
      case 'wifi':
        setwifi(true);
        setwayfinder(false);
        setdefault(false);
        break;
      case 'wayfinder':
        console.log('wayfinder case')
        setwifi(false);
        setwayfinder(true);
        setdefault(false);
        break;
      default:
        setwifi(false);
        setwayfinder(false);
        setdefault(true);
        break;
    }
  }
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    const formData = JSON.stringify({
      name: credentials.name,
      qr_types_name: qr_types,
      site_id: isAuthenticated.site,
      qr_type: credentials.qr_type,
      text: credentials.text,
      tenant_id: selectedTenants,
      ssid: credentials.ssid,
      url: front_url,
      password: credentials.password,
      encryption: credentials.encryption,
    })
    console.log(formData)
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      // await axiosInstance.post('/type/createSiteQR', JSON.stringify({
      //   name: credentials.name,
      //   qr_types_name: qr_types,
      //   site_id: isAuthenticated.site,
      //   qr_type: credentials.qr_type,
      //   text: credentials.text,
      //   tenant_id: selectedTenants,
      //   ssid: credentials.ssid,
      //   url: front_url,
      //   password: credentials.password,
      //   encryption: credentials.encryption,
      // }))
      const response = await fetch(`${url}/api/type/qr/edit/${params.id}`, {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: formData,
      });
      const json = await response.json();
      console.log(json);
      if (json.success) {
        toast.success("Site QR Updated Successfully");
        navigate(-1)
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  };
  const getTypes = async () => {
    try {
      const response = await fetchTypesHandler();
      setQrTypes(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleTenantsSelection = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedTenants(selectedValues);
    setCredentials({ ...credentials, 'tenant_id': selectedValues });
  };
  const getTenants = async () => {
    try {
      const response = await fetchTenantsHandler();
      setTenants(response?.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getTenantOptions = [
    ...getTenant.map((per) => ({
      key: per._id,
      value: per._id,
      label: per.name,
    })),
  ];
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/site/qr/codes/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Create Site Qr Code</h3>
                  <p>
                    List of all the sites qrcode in the system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Name</h4>
                      <p>Enter Name of QR Code</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        placeholder="Enter Name of QR Code"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              {def &&
                <>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>QR Text</h4>
                          <p>Enter Text for QR Code</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <textarea
                            className="form-control"
                            id="text"
                            name="text"
                            placeholder="Enter Text for QR "
                            value={credentials.text}
                            onChange={handleInput}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {wayfinder &&
                <>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>Select Tenants</h4>
                          <p>Select tenants for use of site qrcode</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <Select
                            isMulti
                            name="tenant_id"
                            options={getTenantOptions}
                            className="basic-multi-select text-capitalize"
                            classNamePrefix="select"
                            value={getTenantOptions.filter((option) => credentials.tenant_id.includes(option.value))}
                            onChange={handleTenantsSelection}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {wifi &&
                <>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>SSID</h4>
                          <p>Enter SSID for Wifi Network</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            name="ssid"
                            placeholder="Enter SSID"
                            className="form-control"
                            value={credentials.ssid}
                            onChange={handleInput}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>Password</h4>
                          <p>Enter password for Wifi Network</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            name="password"
                            placeholder="Enter Wifi Password"
                            className="form-control"
                            value={credentials.password}
                            onChange={handleInput}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>Encryption: </h4>
                          <p>Select Network Encryption</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <select
                            name="encryption"
                            value={credentials.encryption}
                            onChange={handleInput}
                          >
                            <option value="">Select Encryption</option>
                            <option value="WPA">WPA/WPA2/WPA3</option>
                            <option value="WEP">WEP</option>
                            <option value="no_password">None</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Edit;
