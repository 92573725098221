import React, { useState, useEffect } from 'react'
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Html5QrcodeScanner } from 'html5-qrcode';
import styles from "./style.module.css";
import { useSelector } from 'react-redux';
import HashLoader from "react-spinners/ClipLoader";
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
const QrcodeScan = () => {
  let navigate = useNavigate();
  const [scanResult, setScanResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const id = isAuthenticated.site;
  const [isScanner, setIsScanner] = useState(false)
  const [qrcode, isQrcode] = useState('');
  const [siteName, isSiteName] = useState('');
  const [appointmentIds, setAppointmentIds] = useState([]);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [scannerInitialized, setScannerInitialized] = useState(false);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [siteData, setSiteData] = useState(null);
  const [barcode, setBarcode] = useState('');
  let scanner;
  useEffect(() => {
    let interval;
    const handleKeyDown = (evt) => {
      if (interval) clearInterval(interval);
      if (evt.code === 'Enter') {
        if (barcode) handleBarcode(barcode);
        setBarcode('');
        return;
      }
      if (evt.key !== 'Shift') setBarcode((prevBarcode) => prevBarcode + evt.key);
      interval = setInterval(() => setBarcode(''), 20);
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [barcode]);
  useEffect(() => {
    getSite(id);
    if (!scannerInitialized) {
      scanner = new Html5QrcodeScanner('reader', {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
      });
      scanner.render(success, error);
      setScannerInitialized(true);
    }
    function success(result) {
      scanner.clear();
      setIsLoading(true);
      appointmentAuthentication(result);
    }
    function error(err) {
      console.warn(err)
    }
  }, [id, scannerInitialized]);
  useEffect(() => {
    if (authenticationFailed) {
      // Reinitialize the scanner if authentication fails
      setScannerInitialized(false);
      setAuthenticationFailed(false);
    }
  }, [authenticationFailed]);
  // const appointmentAuthentication =  (result) => {
  //   const currentId = result.toString();
  //   setIsLoading(false);
  //   const foundAppointment = appointmentIds.some(appointment => appointment._id === currentId);
  //   if (foundAppointment) {
  //     console.log("app found");
  //     navigate(`/appointment/detail/page/${currentId}`);
  //   } else {
  //     console.log('appointment not found');
  //     setAuthenticationFailed(true);
  //   }
  // };
  const handleBarcode = async (scannedBarcode) => {
    // Handle the scanned barcode data here
    try {
      const siteId = id;
      setIsLoading(true);
      const response = await fetch(`${url}/api/appointments/info/${scannedBarcode}/${siteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error(`Failed to fetch site with id ${scannedBarcode}`);
      }
      const json = await response.json();
      if (json.success) {
        navigate(`/appointment/detail/page/${scannedBarcode}`);
      } else {
        toast.error('Appointment Not Found');
        setAuthenticationFailed(true);
      }
    } catch (error) {
      console.error(error);
    }
    // You can perform any further processing or API requests here
  };
  const appointmentAuthentication = async (result) => {
    try {
      const appId = result.toString();
      const siteId = id;
      setIsLoading(true);
      const response = await fetch(`${url}/api/appointments/info/${appId}/${siteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error(`Failed to fetch site with id ${id}`);
      }
      const json = await response.json();
      if (json.success) {
        if (json?.status === "pending") {
          const queryParam = 'status=1';
          navigate(`/appointment/detail/page/${appId}?${queryParam}`);
        } else {
          navigate(`/appointment/detail/page/${appId}`);
        }
      } else {
        toast.error('Appointment Not Found');
        setAuthenticationFailed(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSite = async (id) => {
    try {
      const response = await fetch(`${url}/api/sites/getSite/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch site with id ${id}`);
      }
      const json = await response.json();
      console.log(json);
      if (json.success) {
        if (json.site) {
          isQrcode(json.site.qr_code);
          isSiteName(json.site.name);
          setSiteData(json.site);
        }
        if (json.apps) {
          setAppointmentIds(json.apps);
        }
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const readerStyle = {
    width: '500px',
    position: 'relative',
    padding: '0px',
  };
  const qrShade = {
    position: 'absolute',
    borderWidth: '62px 124px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.48)',
    boxSizing: 'border-box',
    inset: '0px'
  };

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap text-capitalize">
                <h3>scan QR (for appointments) {barcode}</h3>
                <p>Scan the QR code for appointment scheduling</p>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <div className="scan-box-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="scanner">
                        <h5>QR code for</h5>
                        <h3>{siteName && siteName}</h3>
                        <QRCode value={`${base_url}/external/new/appointment/${id}`} size={220} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="scanning-section">
                        {isLoading ?
                          <div className='text-center'>
                            <HashLoader
                              color={`#0b0d41`}
                              loading={isLoading}
                              size={40}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                          : <div id='reader' style={readerStyle}></div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="scan-cont">
                    <div className="row">
                      <div className="col-lg-8 order-2 order-lg-1">
                        <h5>Instructions</h5>
                        <ol>
                          <li>
                            Scan provided Qr code and you received
                            the self registration form.
                          </li>
                          <li>
                            Fill the given form and select the
                            Client whom you want to visit.
                          </li>
                          <li>
                            Submit your form and your appointment
                            request is sent to the specific client.
                          </li>
                          <li>
                            After approval you will receive new text
                            message and email with a QR code to
                            present to the camera for entry.
                          </li>
                        </ol>
                      </div>
                      <div className="col-lg-4 order-1 order-lg-2 text-end">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default QrcodeScan