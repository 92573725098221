import axiosInstance from "../../config/axios"
import { toast } from "react-toastify";

const fetchRolesHandler = async () => {
    try {
        const response = await axiosInstance.get('/roles/getRoles');
        return response;
    } catch (error) {
        toast.error(error.message);
        console.log(error)
    }
}
export default fetchRolesHandler;