import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import defaultAvatar from "../../../../images/avatar.png";
import styles from "./style.module.css";
import BackIcon from '../../../../images/icon-back.png'
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/ClipLoader";
import { formattedDate } from '../../../../utils/functions';

const AppointmentDetail = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const guard = queryParams.get("status");
  const [appData, setAppData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [siteData, setsiteData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);
  const [seconds, setSeconds] = useState(5);


  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsNotFound(false);
      setIsDisplay(false);

      const response = await fetch(`${url}/api/appointments/detail/page/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json);
      setIsLoading(false);

      if (json.success) {
        setIsDisplay(true);
        setAppData(json.app);
        setClientData(json.client);
        setsiteData(json.site);


      } else {
        setIsNotFound(true)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (guard && guard === "1") {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => (prevSeconds === 0 ? 0 : prevSeconds - 1));
      }, 1000);
      // Clear the interval when the counter reaches zero
      if (seconds === 0) {
        clearInterval(interval);
        setRedirect(true); // Set the redirect flag to true
      }
      return () => clearInterval(interval);
    } else {
      setRedirect(false);
    }

  }, [seconds]);

  useEffect(() => {
    if (redirect) {
      navigate(`/external/scan/${siteData._id}`);
    }
  }, [redirect]);
  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-12">
                <div className="general-dashboard">
                  {isLoading &&
                    <div className='container-fluid text-center mt-5'>
                      <HashLoader
                        color={`#0b0d41`}
                        loading={isLoading}
                        size={40}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  }
                  {isDisplay &&
                    <div className="container-fluid">
                      <div className='col-lg-6 mx-auto'>
                        <form action="">
                          <div className="form-box card-box appnt-set-box mt-5">
                            <div className="row-top-appnt">
                              <div className="row">
                                <div className="col-xl-6">
                                  <h3 className='text-capitalize'>Appointment with {clientData?.name}</h3>
                                  <p>
                                    {siteData?.name}, {siteData?.address}
                                  </p>
                                </div>
                                <div className="col-xl-6">
                                  <div className="conditions-appnt">
                                    <div className="d-flex align-items-center justify-content-end">
                                      {appData?.status === "confirm" &&
                                        <span className="appnt-alert success">
                                          Confirmed
                                        </span>
                                      }
                                      {appData?.status === "pending" &&
                                        <span className="appnt-alert warning">
                                          Pending
                                        </span>
                                      }
                                      {appData?.status === "checked-in" && (
                                        <span className={`appnt-alert success`} style={{ marginLeft: "0px" }}>
                                          Checked-In
                                        </span>
                                      )}
                                      {appData?.status === "expire" &&
                                        <span className="appnt-alert danger">
                                          Expired
                                        </span>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="details-wrap-appnt">
                              <div className="row justify-content-center">
                                <div className="col-xl-9">
                                  <div className="inner-det-wrap">
                                    <div className="row">
                                      <div className="col-12">
                                        <h3>Visitor Details</h3>
                                      </div>
                                      <div className="col-12">
                                        <div className="row">
                                          <div className="col">
                                            <div className="">
                                              <h5>Visitor</h5>
                                              <h4>{appData?.name}</h4>
                                            </div>
                                            <div className="">
                                              <h5>Email</h5>
                                              <h4>{appData?.email}</h4>
                                            </div>
                                          </div>
                                          <div className="col">
                                            <div className="">
                                              <h5>Contact</h5>
                                              <h4>{appData?.phone}</h4>
                                            </div>
                                            <div className="">
                                              <h5>Date & Time</h5>
                                              <h4>{formattedDate(appData?.date)} at {appData?.time}</h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {guard && guard === "1" && (
                                        <div className='col-12'>
                                          <div className='text-center'>
                                            <h1 className="counter-sec">
                                              Redirect after
                                              <span className="counter-value">{seconds < 10 ? '0' + seconds : seconds}</span> seconds.
                                            </h1>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 ">
                                  <div className="scan-img-appnt">
                                    <img src={`/${appData?.qr_image}`} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </form>
                      </div>
                    </div>
                  }
                  {isNotFound &&
                    <div className='container-fluid mt-5'>
                      <div className='form-box card-box appnt-set-box mt-5 text-center'>
                        <h4>Oops</h4>
                        <p>No appointment found </p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

  )
}

export default AppointmentDetail