import React, { useState, useEffect } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import ChatSidebar from "./Sidebar";
import RichTextEditor from "./RichTextEditor";
import { useSelector } from "react-redux";
import './message.css';
import { Link } from "react-router-dom";
import io from "socket.io-client";
import { toast } from "react-toastify";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
const Compose = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [content, setContent] = useState('');
  const [credentials, setCredentials] = useState({ user: "", subject: "" });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchData();

  }, []);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/chat/user/list`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setData(json.users);
    } catch (error) {
      console.error(error);
    }
  };
  const handleContentChange = (newContent) => {
    setContent(newContent);
  };
  const formSubmit = async (e) => {
    e.preventDefault();

    if (content === '') {
      toast.error("please write any message first");
      return;
    }
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);
    const receiverId = credentials.user;
    const response = await fetch(
      `${url}/api/chat/message/send`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          receiver_id: credentials.user,
          subject: credentials.subject,
          sender_id: isAuthenticated._id,
          message: content,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      socket.emit("sendMessageNotification", { receiverId });
      setCredentials({
        user: '',
        subject: '',
      });
      setContent('');

      toast.success('Message Sent Successfully');

    } else {
      setIsLoading(false);
      toast.error(json.error);
    }
  };
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div style={{ marginTop: "100px" }}>
            </div>
            <div className="page-header">
              <h1 className="page-title">Chat</h1>
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Chat
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <ChatSidebar />
              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Compose new message</h3>
                    {isAuthenticated.role}
                  </div>
                  <form onSubmit={formSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <div className="row align-items-center">
                          <label className="col-xl-2 form-label">To</label>
                          <div className="col-xl-10">
                            <select
                              className="form-control form-select"
                              name="user"
                              value={credentials.user}
                              onChange={handleInput}
                              required
                            >
                              <option value="" selected>
                                Select User
                              </option>
                              {data.map((item, key) => (
                                <option value={item._id} key={item._id}>
                                  {item.name} {'(' + item.role + ')'}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row align-items-center">
                          <label className="col-xl-2 form-label">Subject</label>
                          <div className="col-xl-10">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              value={credentials.subject}
                              onChange={handleInput}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row ">
                          <label className="col-xl-2 form-label">Message</label>

                          {/* <textarea
                              className="form-control"
                              name="message"
                              id="summernote"
                            ></textarea> */}
                          <RichTextEditor onContentChange={handleContentChange} />

                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-sm-flex">
                      <div className="mt-2 mb-2">
                        <a
                          href="javascript:void(0)"
                          className="btn btn-icon btn-white btn-svg"
                          id="btnUp"
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Attach"
                        >
                          <span className="ri-attachment-2"></span>
                        </a>
                      </div>

                      <div className="btn-list ms-auto my-auto">
                        <button
                          type="button"
                          className="btn btn-danger btn-space mb-0"
                        >
                          Cancel
                        </button>

                        <button type="submit" className="btn btn-primary btn-space mb-0" id="btnSubmit" disabled={isLoading}>

                          {isLoading ? 'Sending...' : 'Send message'}
                        </button>
                      </div>
                    </div>
                    <div
                      className="row"
                      id="uploadFilesDiv"
                      style={{ paddingLeft: '20px' }}
                    ></div>
                    <div
                      id="messageMsg"
                      style={{ paddingLeft: '20px', marginTop: '5px', paddingBottom: '10px' }}
                    ></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Compose;
