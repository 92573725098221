import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import BackIcon from "../../../images/icon-back.png";
import RemoveImg from "../../../images/remove.png";
import UploadIcon from "../../../images/icon-upload.png";
import styles from "./style.module.css";
import { toast } from "react-toastify";
const AddInformation = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [tenants, setTenant] = useState([]);
  const [images, setImages] = useState([]);
  const [credentials, setCredentials] = useState({
    tenant_id: "",
    start: "",
    end: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    fetchTenant();
  }, []);
  const fetchTenant = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/wayfinder/tenants`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setTenant(json.tenants);
      } else {
        toast.error("No Tenant Found");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    // let headers = new Headers();
    // headers.append("Content-Type", "application/json");
    // headers.append("Access-Control-Allow-Origin", "*");
    // headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    // Create a new FormData object to send both form data and images
    const formData = new FormData();
    formData.append("tenant_id", credentials.tenant_id);
    formData.append("start", credentials.start);
    formData.append("end", credentials.end);
    // // Append images to the FormData object
    images.forEach((image, index) => {
      formData.append(`file`, image);
    });
    const response = await fetch(`${url}/api/wayfinder/add/location`, {
      method: "POST",
      body: formData,
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        tenant_id: "",
        start: "",
        end: "",
      });
      toast.success(json.message);
      navigate(json.url);
    } else {
      toast.error(json.message);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const handleFileChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages([...images, ...selectedImages]);
  };
  const removeImage = (indexToRemove) => {
    const updatedImages = images.filter((_, index) => index !== indexToRemove);
    setImages(updatedImages);
  };

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/wayfinder/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Add Wayfinder</h3>
                  <p>Create New Wayfinder Directions For Tenant</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap text-capitalize">
                      <h4>Select tanent</h4>
                      <p>Whom this wayfinder will lead to</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="tenant_id"
                        required
                        onChange={handleInput}
                      >
                        <option value selected>
                          Choose Tenant
                        </option>
                        {tenants.map((tenant) => (
                          <option
                            className="text-capitalize"
                            key={tenant._id}
                            value={tenant._id}
                          >
                            {tenant.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap text-capitalize">
                      <h4>Start Point</h4>
                      <p>Enter the starting point of wayfinder</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="start"
                        name="start"
                        placeholder="Enter Starting Point"
                        required
                        onChange={handleInput}
                      />
                      <div className="option-selected"></div>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap text-capitalize">
                      <h4>End Point</h4>
                      <p>Enter the starting point of wayfinder</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="end"
                        name="end"
                        placeholder="Enter Ending Point"
                        required
                        onChange={handleInput}
                      />
                      <div className="option-selected"></div>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap text-capitalize">
                      <h4>Upload Pictures</h4>
                      <p>Images of path towards the tenant</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <div className="file-wrap">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleFileChange}
                          required
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="file_name">
                            Firts_Image.png
                          </span>{" "}
                          <img src={UploadIcon} alt="Upload Icon" />
                        </div>
                      </div>
                    </div>
                    {/* <div className="btn-add-wrap">
                    <div className="btn btn-gray">
                      Open Camera
                    </div>
                  </div> */}
                  </div>
                </div>
                {/* input row */}
                <div className="row mb-5">
                  {images.map((file, index) => (
                    <div
                      key={index}
                      className="col-xl-3 col-lg-4 col-md-6"
                    >
                      <div className="upld-img-wrap">
                        <div className="remve-img">
                          <img
                            src={RemoveImg}
                            onClick={() => removeImage(index)}
                            alt=""
                          />
                        </div>
                        <img
                          src={URL.createObjectURL(file)}
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a
                    href=""
                    className="btn btn-prim btn-transparent w-auto"
                  >
                    Reset Form
                  </a>
                  <button
                    type="submit"
                    className="btn btn-prim w-auto ms-5 text-capitalize"
                  >
                    proceed to editing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddInformation;
