import React, { useEffect, useState } from "react";
import Header from "../partials/Header";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
const Permission = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/roles/getPermissions?page=${currentPage}&limit=${itemsPerPage}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      setData(json.permission_data);
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/roles/permission/delete/${id}`,
        {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        }
      );
      fetchData(); // Refresh the records list after successful deletion
    } catch (error) {
      console.log("Error deleting record:", error);
    }
  };
  const handleEdit = (item) => {
    setSelectedItem(item);
    setFormData({ ...item });
    setEditMode(true);
  };
  const closeForm = () => {
    setEditMode(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    // const response = await fetch("http://54.174.4.89:5000/api/auth/login", {
    //   mode: "cors",
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify({
    //     email: credentials.email,
    //     password: credentials.password,
    //   }),
    // });
    const response = await fetch(
      `${url}/api/roles/updatePermission/${selectedItem._id}`,
      {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          name: formData.name,
          section: formData.section,
        }),
      }
    );
    const json = await response.json();
    setFormData("");
    setEditMode(false);
    fetchData();
    if (json.success) {
      alert("success");
    } else {
      alert("Error");
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
          <div style={{ marginTop: "100px" }}>
           
           </div>
            <div className="page-header">
              <div>
                <h1 className="page-title">Permissions</h1>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="">Permission list</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Permission
                  </li>
                </ol>
              </div>
              <Link role="button" className="btn btn-primary" to="/add/permission">
                {" "}
                <span className="fe fe-plus fs-14"></span>
                Add Permission
              </Link>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12">
                <div className="card">
                  {!editMode && (
                    <div className="card-body pt-4">
                      <table className="table table-bordered text-nowrap mb-0">
                        <thead className="border-top bg-light">
                          <tr>
                            <th className="bg-transparent border-bottom-0 fw-bold">
                              Permission Name
                            </th>
                            <th className="bg-transparent border-bottom-0 fw-bold">
                              Permission Section
                            </th>
                            <th className="bg-transparent border-bottom-0 fw-bold">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {data.length > 0 ? (
                          data.map((item, key) => (
                            <tr className="border-bottom">
                              <td>{item.name}</td>
                              <td>
                                <h6 className="mb-0 m-0 fs-14 fw-semibold">
                                  {item.section}
                                </h6>
                              </td>
                              <td>
                                <div className="g-2">
                                  <Link
                                    className="btn text-primary btn-sm btnEdit"
                                    onClick={() => handleEdit(item)}
                                  >
                                    <span className="fe fe-edit fs-14"></span>
                                  </Link>
                                  <Link
                                    className="btn text-danger btn-sm "
                                    onClick={() => handleDelete(item._id)}
                                  >
                                    <span className="fe fe-trash-2 fs-14"></span>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                          ) : (
                            <tr>
                              <td colSpan="3" className="text-center text-danger">No data found.</td>
                            </tr>
                        )}
                        </tbody>
                      </table>
                      {data.length > 0 && (
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          pageCount={totalPages}
                          forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                          onPageChange={handlePageChange}
                          containerClassName={'pagination-container'}
                          pageClassName={'pagination-page'}
                          previousClassName={'pagination-previous'}
                          nextClassName={'pagination-next'}
                          disabledClassName={'pagination-disabled'}
                          activeClassName={'pagination-active'}
                        />
                      )}
                    </div>
                  )}
                  {editMode && selectedItem && (
                    <div className="row">
                      <div className="col-12 col-sm-12">
                        <div className="card">
                          <div className="card-body pt-4">
                            <form action="" onSubmit={formSubmit}>
                              <div className="form-group">
                                <label className="form-label text-start fw-bold">
                                  Permission name:{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  required
                                  placeholder="Role name"
                                  value={formData.name}
                                  onChange={handleChange}
                                ></input>
                                <label className="form-label text-start fw-bold">
                                  Permission Section:
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-select form-select-lg"
                                  aria-label=".form-select-lg example"
                                  name="section"
                                  value={formData.section}
                                  onChange={handleChange}
                                >
                                  <option value="">Select section</option>
                                  <option value="user_section">
                                    User Section
                                  </option>
                                  <option value="QR Codes Type">
                                    QR Codes Type
                                  </option>
                                  <option value="Chat">Chat</option>
                                  <option value="Sites">Sites</option>
                                  <option value="Profile">Profile</option>
                                  <option value="Door">Door</option>
                                  <option value="Site QR Code">
                                    Site QR Code
                                  </option>
                                  <option value="WayFinder">WayFinder</option>
                                  <option value="Screens">Screens</option>
                                  <option value="Apponitment">
                                    Appointments
                                  </option>
                                  <option value="Content">Content</option>
                                  <option value="Employee">Employee</option>
                                  <option value="Camera">Camera</option>
                                  <option value="Scan">Scan</option>
                                  <option value="building Admins">
                                    building Admins
                                  </option>
                                  <option value="Vistor">Vistor</option>
                                </select>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  id="btnSubmit"
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-danger"
                                  onClick={closeForm}
                                >
                                  Close
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Permission;
