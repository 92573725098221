import React, { useState, useEffect } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import ChatSidebar from "./Sidebar";
import DateDisplay from "../date/DateDisplay";
import { useSelector } from "react-redux";
import "./message.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router-dom";

const SentItem = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/video/chat/sent/item/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json.chats);
      setData(json.chats);
      setUser(json.users);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div style={{ marginTop: "100px" }}></div>
            <div className="page-header">
              <h1 className="page-title">Chat</h1>
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>{" "}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Chat
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <ChatSidebar />
              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Mail Sent</h3>
                </div>
             
                <div className="card-body">
                    <div className="email-media" id="row{{$chat->id}}">
                        <div className="mt-0 d-sm-flex">
                           
                            <img className="me-2 rounded-circle avatar avatar-lg" src="/assets/images/users/6.jpg" alt="avatar" />
                           

                            <div className="media-body pt-0">
                                <div className="float-md-end d-flex fs-15">
                                    <small className="me-3 mt-3 text-muted"><DateDisplay date={data.createdAt} /></small>
                                </div>
                                <div className="media-title text-dark font-weight-semibold mt-1">{isAuthenticated.name}<span className="text-muted font-weight-semibold">( {isAuthenticated.email} )</span></div>
                                <small className="mb-0">to {user.name} ( {user.email}) </small>

                            </div>

                        </div>
                        <div className="eamil-body mt-5">
                        <div dangerouslySetInnerHTML={{ __html: data.message }} />
                            
                        </div>

                       
                        
                        
                    </div>
                </div>
              
            </div>
        </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SentItem;
