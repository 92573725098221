import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import VideoOrImages from "./VideoOrImages";
import BackIcon from "../../../images/icon-back.png";
import Select from 'react-select';
import styles from "./style.module.css";
import { toast } from "react-toastify";
const AddScreen = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [videosOrImages, setVideosOrImages] = useState([]);
  const [siteQrcodes, setSiteQrcodes] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedQrcodes, setSelectedQrcodes] = useState([]);
  const [credentials, setCredentials] = useState({ name: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    fetchFile();
    fetchQrcodes();
  }, []);
  const fetchFile = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/screen/files`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setVideosOrImages(json.content);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchQrcodes = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/screen/qrcodes`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSiteQrcodes(json.qrcodes);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleVideoChange = (event) => {
    const { value, checked } = event.target;
    setSelectedVideos((prevSelectedVideos) =>
      checked
        ? [...prevSelectedVideos, value]
        : prevSelectedVideos.filter((video) => video !== value)
    );
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      if (selectedVideos.length === 0) {
        toast.error('Please select any image or video first');
        return;
      }
      if (selectedQrcodes.length === 0) {
        toast.error('Please select any qrcode first');
        return;
      }
      setIsLoading(true);
      const response = await fetch(`${url}/api/screen/create`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            name: credentials.name,
            files: selectedVideos,
            qrcodes: selectedQrcodes
          }),
        }
      );
      setIsLoading(false);
      const json = await response.json();
      console.log(json)
      if (json.success) {
        setCredentials({
          name: '',
        });
        setSelectedVideos([]);
        setSelectedQrcodes([]);
        toast.success('Screen added Successfully');
      } else {
        toast.error('failed to create screen');
      }
    } catch (error) {
      console.log(error)
    }
  };
  const handleQrSelection = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedQrcodes(selectedValues);
  };
  let getQrOptions = []; // Initialize an empty array
  if (siteQrcodes.length > 0) {
    getQrOptions = siteQrcodes.map((per) => ({
      value: per._id,
      label: per.name,
    }));
  }
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };

  
  return (
    <div className="general-dashboard text-capitalize">
    <div className="container-fluid">
      <div className="dashboard-top-row add-new-top-row">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="user-wrap user-back-wrap">
              <Link to="/screen/list">
                <div className="back-icon">
                  <img src={BackIcon} alt="" />
                </div>
              </Link>
              <div>
                <h3>add screen</h3>
                <p>Enter new screen by filling required fields</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-end"></div>
        </div>
      </div>
      {/* row */}
      <form onSubmit={formSubmit}>
        <div className="form-box card-box">
          <div className="row">
            <div className="col-lg-6">
              {/* input row */}
              <div className="row">
                <div className="col-xl-5 col-lg-5">
                  <div className="label-wrap">
                    <h4>screen name</h4>
                    <p>Enter the name of the screen</p>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="inpt-wrap">
                    <input
                      type="text"
                      className="form-control text-capitalize"
                      placeholder="main hallway screen"
                      onChange={handleInput}
                      name="name"
                    />
                  </div>
                </div>
              </div>
              {/* input row */}
            </div>
            <div className="col-lg-6">
              {/* input row */}
              <div className="row">
                <div className="col-xl-5 col-lg-5">
                  <div className="label-wrap">
                    <h4>select QR</h4>
                    <p>Select QR codes you want to show</p>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <Select
                    isMulti
                    name="tenants"
                    options={getQrOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleQrSelection}
                  />
                </div>
              </div>
              {/* input row */}
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3 col-cust-screens-3">
                  <div className="label-wrap">
                    <h4>select media</h4>
                    <p>Images/videos you want to display</p>
                  </div>
                </div>
                <div className="col-lg-9 col-cust-screens-9">
                  <div className="row">
                    {videosOrImages.length > 0 ? (
                      videosOrImages.map((item) => (
                        <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                          <div className="seq-wrap">
                            <div className="upld-img-wrap">
                              <div className="remve-img checkd-img">
                                <input
                                  className="form-check-input position-static"
                                  id={`video-${item.id}`}
                                  name="videos[]"
                                  type="checkbox"
                                  value={item.image}
                                  checked={selectedVideos.includes(item.image)}
                                  onChange={handleVideoChange}
                                />
                              </div>
                              {/* <img src={ProfileImg} alt="" /> */}
                              <VideoOrImages videoName={item.image} />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No Media Found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-end">
              <div className="form-btn-wrap mb-5 pb-5">
                <button type="submit" className="btn btn-prim w-auto ms-5 text-capitalize" disabled={isLoading}>
                  <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> create screen
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  )
}
export default AddScreen