import React, { useEffect, useState, useRef } from 'react';
import VideoPlayer from './VideoPlayer';
import { useParams } from 'react-router-dom';
import BackIcon from "../../../images/back-white.png";
import BarBottom from "../../../images/bar-bottom.png";
import VideoBtn from "../../../images/video-btn.png";
import VideoBg from "../../../images/video-bg.png";
import VideoDemo from "../../../images/demo.mp4";
import styles from "./style.module.css";
import { Link } from "react-router-dom";

const ScreenView = () => {
  const { id } = useParams();
  const [screenFiles, setScreenFiles] = useState([]);
  const [screenQrcodes, setScreenQrcodes] = useState([]);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);

  const handlePlayClick = () => {
    const video = videoRef.current;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(`${url}/api/screen/view/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);

      const json = await response.json();
      console.log(json)
      if (json.success) {
        setScreenFiles(json.files);
        setScreenQrcodes(json.qrTypes);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);



  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-12">
                <div className="general-dashboard vid-gernal">
                  <div className="container-fluid px-0">
                    <div className="dashboard-top-row add-new-top-row video-top-bar">
                      <div className="row">
                        <div className="col-lg-6 my-auto">

                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="video-box">
                      {/* <img src={VideoBtn} alt="" className="play-btn" /> */}
                      {/* <img src={VideoBg} alt="" className="video-bg" /> */}
                      {/* <video>
                      <source src={VideoDemo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video> */}

 
                      
                    {isLoading ? (
                      <p className='text-danger'>Loading... please wait</p>
                    ) : screenFiles.length > 0 ? (
                      <>
                      <VideoPlayer videoList={screenFiles} />
                    </>

                    ) : (
                      <p className='text-danger'>No Data Found</p>

                    )}
                    </div>
                    <div className="vide-bar-bottom">
                      <div className="row-bar">
                        {screenQrcodes.length > 0 ? (
                          screenQrcodes.map((qr, index) => (
                            <div className="bar-code">
                              <div className="bar-code-box">
                                <div className="img">
                                  <img src={`/${qr.image}`} alt="" style={{width:"80px"}}/>
                                </div>
                                <div className="bar-code-text">
                                  <h3 className="mb-0">{qr.name}</h3>
                                </div>
                              </div>
                            </div>
                          ))
                        ):(
                          <p className='text-danger'>No Qr attached</p>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ScreenView;
