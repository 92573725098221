import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import BackIcon from '../../../images/icon-back.png'
import UploadIcon from '../../../images/icon-upload.png'
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import styles from "./style.module.css";
import { toast } from "react-toastify";
function MyDropzone({ setFile, setShowDropzone }) {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      const fileTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime', 'video/mpeg', 'video/3gpp', 'video/avi', 'video/ogg', 'video/webm'];
      if (!fileTypes.includes(file.type)) {
        toast.error("Please upload only image or video files.");
        return;
      }
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
      };
      reader.readAsArrayBuffer(file);
      // Save the uploaded file in state and hide the dropzone
      setFile(file);
      setShowDropzone(false);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*,video/*",
    multiple: false,
  });
  return (
    <div className="dropzoneDiv">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop Video/Image here, or click to select</p>
      </div>
    </div>
  );
}
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [appendedHTML, setAppendedHTML] = useState("");
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [showDropzone, setShowDropzone] = useState(true);
  const [credentials, setCredentials] = useState({
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please upload a file.");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("description", credentials.description);
    formData.append("file", file);
    formData.append("site", isAuthenticated.site);
    try {
      const response = await fetch(`${url}/api/screen/post/video`, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        setCredentials({ description: "" });
        setFile(null);
        setShowDropzone(true);
        toast.success("Video Posted Successfully");
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      toast.error("An error occurred while posting the video.");
    }
    setIsLoading(false);
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/gif", "video/mp4", "video/webm", "video/ogg"];
      if (allowedTypes.includes(file.type)) {
        setFile(file);
        setError(null);
        // Create a FileReader
        const reader = new FileReader();
        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        setFile(null);
        setError("Invalid file type. Please select an image or video file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };

  
  return (
    <div className="general-dashboard text-capitalize">
    <div className="container-fluid">
      <div className="dashboard-top-row add-new-top-row">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="user-wrap user-back-wrap">
              <Link to="/media/contents">
                <div className="back-icon">
                  <img src={BackIcon} alt="" />
                </div>
              </Link>
              <div>
                <h3>add media</h3>
                <p>Add new image or video</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-end"></div>
        </div>
      </div>
      {/* row */}
      <form onSubmit={formSubmit}>
        <div className="form-box card-box">
          <div className="row">
            <div className="col-lg-6">
              {/* input row */}
              <div className="row">
                <div className="col-xl-5 col-lg-5">
                  <div className="label-wrap">
                    <h4>upload media</h4>
                    <p>Upload in photo or video format</p>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="inpt-wrap">
                    <div className="file-wrap">
                      <input type="file" onChange={handleFileSelect} />
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="file_name">
                          {file ? file.name : 'No file selected'}
                        </span>{" "}
                        <img src={UploadIcon} alt="Upload Icon" />
                      </div>
                    </div>
                    <div style={{ fontSize: '12px', color: 'red', fontStyle: 'italic' }}>
                      {uploadProgress > 0 && uploadProgress < 100 && (
                        <span>{uploadProgress}% uploaded</span>
                      )}
                    </div>
                    {error && <div style={{ fontSize: '12px', color: 'red', fontStyle: 'italic' }}>{error}</div>}
                  </div>
                </div>
              </div>
              {/* input row */}
            </div>
            <div className="col-lg-6">
              {/* input row */}
              <div className="row">
                <div className="col-xl-5 col-lg-5">
                  <div className="label-wrap">
                    <h4>media title</h4>
                    <p>Add the title of media content</p>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="inpt-wrap ">
                    <input
                      type="text"
                      className="text-capitalize"
                      placeholder="main hallway door footage"
                      name="description"
                      required
                      value={credentials.description}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              {/* input row */}
            </div>
            <div className="col-lg-12 text-end mt-5">
              <div className="form-btn-wrap mb-5 pb-5">
                <button
                  type="submit"
                  className="btn btn-prim ms-5 w-auto"
                  disabled={isLoading}
                >
                  {isLoading ? "Saving..." : "add media"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  );
};
export default Add;
