import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddRole = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  useEffect(() => {

  }, []);
  const [credentials, setCredentials] = useState({ name: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    // const response = await fetch("http://54.174.4.89:5000/api/auth/login", {
    //   mode: "cors",
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify({
    //     email: credentials.email,
    //     password: credentials.password,
    //   }),
    // });
    setIsLoading(true);
    const response = await fetch(`${url}/api/roles/createRoles`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: credentials.name,
        permission: []
      }),
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        name: '',
      });
      toast.success('Role Created Successfully');
    } else {
      toast.error(json.error);
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header">
              <div>
                <h1 className="page-title">User Role</h1>

                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Home</a>
                  </li>

                  <li className="breadcrumb-item">
                    <a href="">User list</a>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    User role
                  </li>
                </ol>
              </div>

              <button
                type="button"
                className="btn btn-primary"
                data-bs-effect="effect-slide-in-right"
                data-bs-toggle="modal"
                href="#addRole"
              >
                {" "}
                <span className="fe fe-plus fs-14"></span> Add role
              </button>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12">
                <div className="card">
                  <div className="card-body pt-4">
                    <form action="" onSubmit={formSubmit}>
                      <div className="form-group">
                        <label className="form-label text-start fw-bold">
                          Role name: <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          required
                          placeholder="Role name"
                          value={credentials.name}
                          onChange={handleInput}
                        ></input>
                      </div>
                      <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" id="btnSubmit" disabled={isLoading}>
                          <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Save
                        </button>


                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddRole;
