import React from "react";
import QrIconTable from "../../../images/icon-qr-tb.png";
import VideoIcon from "../../../images/icon-vid.png";

const MediaValidator = ({ videoName }) => {
  const cleanedVideoName = videoName.replace(/[^\w.]/g, "").toUpperCase();

  const isValidVideo =
    cleanedVideoName.includes(".MP4") ||
    cleanedVideoName.includes(".MPV") ||
    cleanedVideoName.includes(".WMV") ||
    cleanedVideoName.includes(".FLV") ||
    cleanedVideoName.includes(".AVI") ||
    cleanedVideoName.includes(".AVCHD") ||
    cleanedVideoName.includes(".WEBM") ||
    cleanedVideoName.includes(".MKV");

  const fileExtension = cleanedVideoName.split('.').pop().toUpperCase();
  const fileType = isValidVideo ? 'Video' : 'Image';

  return (
    <>

        {isValidVideo ? (
          <div className="usr_det_tb">
            <div className="">
                <video controls muted style={{width:"400px"}}>
                    <source  src={`/uploads/screen/${videoName}`} type="video/mp4"  />
                </video>
            </div>
          </div>
        ) : (

          <div className="usr_det_tb">
            <div className="media_bg media_img_bg">
              <img
                className="not_prof_img"
                src={`/uploads/screen/${videoName}`}
                alt=""
              />
            </div>
          </div>
        )}

    </>
  );
};

export default MediaValidator;
