import React, { useEffect, useCallback, useState } from "react";
import Logo from '../../images/logo.svg'

const Header = () => {

  const divStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

  return (
    <div>
      <header>
        <div className="row">
          <div className="col-lg-6">
            <div className="prof-sett text-center" style={divStyle}>
              <img src={Logo} />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
