import React, { useEffect, useState } from "react";
import Header from "../../../partials/Header";
import Sidebar from "../../../partials/Sidebar";
import Footer from "../../../partials/Footer";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from "sweetalert2";
import SearchIcon from "../../../../images/search.png";
import styles from "./style.module.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
const DoorList = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [getSite, setSite] = useState([]);
  const [selectedSite, setSelectedSite] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;
  const [searchValue, setSearchValue] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ site: "all", relay_no: "" });
  const handleInput = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = { ...prevCredentials, [e.target.name]: e.target.value };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    siteData();
  }, []);
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage]);
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/integrator/door/list?page=${currentPage}&limit=${itemsPerPage}&relay_no=${fetchCredentials.relay_no}&site=${fetchCredentials.site}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        if (json.doors.length > 0) {
          setIsDisplay(true);
          setData(json.doors);
        } else {
          setIsNotFound(true);
        }
        setTotalPages(json.totalPages);
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/integrator/sites`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSite(json.sites);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const handleResetBtn = () => {
    const updatedCredentials = {
      relay_no: '',
      site: 'all',
    };
    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  const handleDeleteClick = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/integrator/door/delete/${id}`, {
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        fetchData(credentials);
        await Swal.fire("Deleted!", "The Door has been deleted.", "success");
      } else {
        await Swal.fire("Error!", "Failed to delete the door.", "error");
      }
    }
  };

  
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>Doors</h3>
                <p>List of all the doors</p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box" onClick={toggleFilterSection}>
                    <i className="fas fa-filter" />
                  </div>
                </div>
                <Link
                  to="/door/add"
                  className="btn btn-prim w-auto"
                >
                  Add Door
                </Link>
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <>
              <motion.div
                className={`row mt-5 filter-section`}
                initial={{ opacity: 0 }} // Initial state (hidden)
                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                transition={{ duration: 1.2, delay: 0.1 }}
              >
                <div className="col-12">
                  <div className="filter-right-sec filter-wrap-chat">
                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Site</label>
                      <select name="site" id="" value={credentials.site} onChange={handleInput}>
                        <option value="all">Choose site</option>
                        {getSite.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="search-wrap">
                      <input type="search" placeholder="search by relay no" name="relay_no" value={credentials.relay_no} onChange={handleInput} />
                      <div className="search-box">
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <Link
                      to="" onClick={handleResetBtn}
                      className="btn btn-prim w-auto"
                    >
                      RESET
                    </Link>
                  </div>
                </div>
              </motion.div>
            </>
          )}
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>Site Relay Url</th>
                          <th>Site Name</th>
                          <th>Relay</th>
                          <th>Added</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td colSpan={6} className="text-center" style={{ background: "transparent" }}>
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay &&
                          data.map((item, key) => (
                            <tr key={key}>
                              <td>{item.site_relay_url}</td>
                              <td>
                                {getSite && item.site_id ? (
                                  (() => {
                                    const foundSite = getSite.find(site => site._id === item.site_id);
                                    return foundSite ? foundSite.name : item.site_id;
                                  })()
                                ) : (
                                  "Loading..." // or another placeholder for when data is not available
                                )}
                              </td>
                              <td>{item.relay_no}</td>
                              <td>{item.createdAt}</td>
                              <td className="text-end">
                                {/* <Link to="javascript:;" state="2" lockerid="8" relay="123123" className="btn btn-success btn-sm relayState">Open Door</Link> */}
                                <div className="d-flex">
                                  <Link to={`/door/edit/${item._id}`} className="btn btn-edit tb-btn">Edit</Link>
                                  <button
                                    type="button"
                                    className="btn btn-delete tb-btn"
                                    onClick={() => handleDeleteClick(item._id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                        {isNotFound && (
                          <tr>
                            <td colSpan={5} className="text-center">
                              <p className="text-center text-danger">No data found.</p>
                            </td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="table-num">
                    {(isDisplay && data.length > 0) && (
                      <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={' dig-num'}
                        pageClassName={'dig-num'}
                        previousClassName={'num-btns'}
                        nextClassName={'num-btns'}
                        disabledClassName={'pagination-disabled'}
                        activeClassName={'pagination-active'}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DoorList